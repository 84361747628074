import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Checkbox } from 'antd';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { isValidArray } from '@/utils/utils2';
import { useReactive, useUpdateEffect } from 'ahooks';
import { INDEX_OPTIONS } from '../../Components/sutil';
import _ from 'lodash';
import * as echarts from 'echarts';
// 板块周期图表
export default function PlateRangeCharts(props) {
  const [option, setoption] = useState({
    ...INDEX_OPTIONS.avg,
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      },
      padding: 5,
      formatter: renderFormatter
    },
  });
  const [checkboxItem, setCheckboxItem] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [update, setUpdate] = useState(0);
  const plState = useReactive({ plData: {}, plTime: [] });

  function renderFormatter(params) {
    // 显示对应日期的股票数量
    let finalString = '';
    params.map(n => {
      const ffidx = _.findIndex(plState.plTime, o => o === n.name); //用time找到index
      let extraStringValue = '';
      if (_.includes(n.seriesName, '平均')) { // 平均曲线后面加，并删掉'平均'用来当做key去找到数据
        const plateArr = _.get(plState.plData, `${_.replace(n.seriesName, '平均', '')}`, []);
        extraStringValue = ffidx !== -1 ? `(${_.get(plateArr, `[${ffidx}].stockNum`, 0)}个)` : '';
      }
      finalString = finalString
        + n.marker + ' ' + n.seriesName
        + `<span style='font-weight:600;margin-left:12px'>${n.value}%</span>    
                ${extraStringValue}` // 额外数额显示
        + '<br/>'
    });
    return finalString;
  }

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('plate_range_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const plateDatas = _.get(props, 'datas', {});
    const plateNameArr = _.get(plateDatas, 'plateNames', []);
    // 赋值checkbox和plState
    setCheckboxItem(plateNameArr);
    plState.plData = _.get(plateDatas, `plates`, {}); // tooltip使用
    //console.log(plateDatas);
    let newOption = _.cloneDeep(option);
    let times = _.get(plateDatas, 'dates', []).map(d => d.date);
    plState.plTime = times;
    let sdata = [];
    // 根据checkbox创建series
    if (isValidArray(checkboxValues)) {
      checkboxValues.map(c => {
        const nameIdx = _.findIndex(plateNameArr, o => o.value === c);
        const pl_name = _.get(plateNameArr, `[${nameIdx}].label`, '');
        //const pl_color = _.get(plateNameArr, `[${nameIdx}].color`, ''); // 如需要静态颜色使用该值填充baseLine
        // 用名字找到对应数据
        const getsdata = _.get(plateDatas, `plates.${pl_name}`, []);
        // 创建serise数据；对值进行*100的计算
        sdata.push(createBaseLine(null, {
          name: pl_name + '平均',
          data: getsdata.map(n => 'costAvgRate' in n ? _.round(_.get(n, 'costAvgRate', 0) * 100, 5) : '-')
        }));
        sdata.push(createBaseLine(null, {
          name: pl_name + '指数', isDash: true,
          data: getsdata.map(n => 'plateIndexRate' in n ? _.round(_.get(n, 'plateIndexRate', 0) * 100, 5) : '-')
        }));
      });
    }
    //console.log(sdata);
    newOption.xAxis.data = times;
    newOption.series = sdata;
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [props.updateCount, update]);
  //【bug-fix】 重新获取数据清空checkvalue,否则会再次选中checkbox不显示;
  useUpdateEffect(() => {
    setCheckboxValues([]);
  }, [props.updateCount]);

  const onCheckBoxChange = (checkedValues) => {
    setCheckboxValues(checkedValues);
    setUpdate(_.round(update + 0.1, 1));
  };

  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginTop: 12, marginBottom: 35, marginLeft: 8, marginRight: 8, paddingLeft: 8, paddingRight: 8 };
  return (
    <>
      <Row gutter={[8, 8]} style={controlBarStyle}>
        <Col span={24}>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={checkboxValues}
            onChange={onCheckBoxChange}
          >
            <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
              {checkboxItem.map((itm, i) => {
                return <Col span={3} key={i}>
                  <Checkbox value={itm.value}>{itm.label}</Checkbox>
                </Col>
              })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="plate_range_charts"
            style={{ width: '100%', height: 410 }}
          />
        </div>
      </Spin>
    </>
  )
}