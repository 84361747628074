import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Checkbox } from 'antd';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { labelValues } from '@/utils/utils2';
import { useUpdateEffect } from 'ahooks';
import { INDEX_OPTIONS } from '../../Components/sutil';
import _ from 'lodash';
import * as echarts from 'echarts';
// 颜色根据止盈止损、指数、超额分三个不同色系，并创建三组；【tips】实现同一个checkbox颜色不变，切换时不会更换颜色
const COLOR_GROUP = {
  1: ['#4b77ae', '#005889', '#002f5a', '#ff0000', '#ff8e8e', '#ff6300', '#c64057', '#b1004d', '#ffcd00', '#ffa700', '#e4b100'],
  2: ['#e19100', '#f8c600', '#efcb7a', '#00a388', '#635a07', '#95bd34', '#009483', '#cf6f7c', '#dd0048', '#701128', '#710000'],
  3: ['#a2876c', '#7e989f', '#515f76', '#e4b100', '#ba9953', '#ffcf3c', '#ffa016', '#ef7390', '#4b77ae', '#005b7b', '#34426a']
};
const CHECKBOX_ITEMS = [
  ['一日10%止盈止损', 'oneDayTen'], ['两日10%止盈止损', 'twoDayTen'], ['三日10%止盈止损', 'threeDayTen'],
  ['指数涨跌幅', 'indexRate'], ['指数日涨跌幅', 'indexDailyRate'], ['指数两日涨跌幅', 'twoDayIndexDailyRate'],
  ['指数三日涨跌幅', 'threeDayIndexDailyRate'], ['指数四日涨跌幅', 'fourDayIndexDailyRate'],
  ['两日平均超额', 'twoDayAvgExcess'], ['三日平均超额', 'threeDayAvgExcess'], ['四日平均超额', 'fourDayAvgExcess']
].map(k => labelValues([k[0], k[1]]));
// 动态分析图表
export default function TwoCharts(props) {
  const [option, setoption] = useState(INDEX_OPTIONS.avg);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [fullSerise, setFullSerise] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('push_two_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const pushDatas = _.get(props, 'datas', {});
    let newOption = _.cloneDeep(option);
    let times = _.get(pushDatas, 'time', []);
    let sdata = [];

    // 创建所有checkbox中对应的所有serise数据；
    if (_.size(pushDatas) > 1) {
      // 对象中key代表对应的条件, !==time都是条件数据
      let color_count = 1; let color_round = 1;
      _.keys(pushDatas).map(keyss => {
        if (keyss !== 'time') {
          CHECKBOX_ITEMS.map((c, ci) => {
            let cindex = color_round > 3 ? Math.round(Math.random() * 10) : ci;
            // 条件>3时，静态颜色不足，所以第四个条件颜色用10以内随机数选择，避免单组重复
            let sitem = createBaseLine(COLOR_GROUP[color_count][cindex], {
              name: keyss + ' : ' + c.label,
              keys: keyss + c.value,
              vkeys: c.value,
              /* 根据key值遍历条件中的数据；
              【tips】首次遍历数据多，如checkbox变化再进行遍历数据，需拆解条件字符串，然后去找到条件遍历数据；
              */
              data: _.get(pushDatas, `${keyss}`, []).map(n => _.round(_.get(n, c.value), 5))
            });
            sdata.push(sitem);
          });
          // color_count 1-3循环;color_round累加
          if (color_count >= 3) {
            color_count = 1;
          } else {
            color_count++;
          }
          color_round++;
        }
      });
    } else { // <1 情况checkboxValue
      setCheckboxValues([]);
    }
    //console.log(sdata);
    setFullSerise(sdata);
    newOption.xAxis.data = times;
    newOption.series = _.filter(sdata, o => _.includes(checkboxValues, o.vkeys));
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [props.updateCount]);
  // checkbox 点击更新
  useUpdateEffect(() => {
    let myChart = props.myChart;
    myChart = echarts.init(document.getElementById('push_two_charts'));
    const pushDatas = _.get(props, 'datas', {});
    let newOption = _.cloneDeep(option);
    // 点击更新，直接通过filter更新
    newOption.xAxis.data = _.get(pushDatas, 'time', []);
    newOption.series = _.filter(fullSerise, o => _.includes(checkboxValues, o.vkeys));

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update]);

  const onCheckBoxChange = (checkedValues) => {
    setCheckboxValues(checkedValues);
    setUpdate(_.round(update + 0.1, 1));
  };

  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginTop: 12, marginBottom: 35, marginLeft: 8, marginRight: 8, paddingLeft: 8, paddingRight: 8 };
  return (
    <>
      <Row gutter={[8, 8]} style={controlBarStyle}>
        <Col span={24}>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={checkboxValues}
            onChange={onCheckBoxChange}
          >
            <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
              {CHECKBOX_ITEMS.map((itm, i) => {
                return <Col span={3} key={i}>
                  <Checkbox value={itm.value}>{itm.label}</Checkbox>
                </Col>
              })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="push_two_charts"
            style={{ width: '99%', height: 410 }}
          />
        </div>
      </Spin>
    </>
  )
}