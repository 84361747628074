import _ from 'lodash';

const commonIndexOptions = [
  { label: '沪深300', value: '000300' },
  { label: '中证500', value: '000905' },
  { label: '中证1000', value: '000852' },
  { label: '其他', value: 'else' },
];

const COMMON_INDEX_2 = [
  { name: '沪深300', value: '000300' },
  { name: '中证500', value: '000905' },
  { name: '中证1000', value: '000852' },
  { name: '创业科创', value: 'kc' },
  { name: '其他', value: 'else' },
  { name: '全部', value: 'all' },
];
let commonIndexValueType = {};
COMMON_INDEX_2.map(c => {
  commonIndexValueType[c.value] = c.name;
});

// 创建单一item的对象，返回 {label,value,children}
function createLabelValue(array) {
  let baseObj = { label: array[0], value: array[1] };
  if (array[2]) {
    baseObj.children = array[2];
  }
  return baseObj;
}
// 指数分类总和object
let codes = {
  base: {
    '上证50': "000016",
    '沪深300': "000300",
    '中证500': "000905",
    '中证1000': "000852",
    '中证2000': "932000",
    '东财小市值': '800006',
    '米筐小市值': '866001',
    '米筐微盘股': '866006',
    '国证2000': "399303",
    '上证指数': "000001",
    '深证成指': '399001',
    '创业板指': '399006',
    '科创50': '000688',
  },
  plate: {
    "环保": "888701",
    "电力设备": "888702",
    "商贸零售": "888703",
    "社会服务": "888704",
    "综合": "888705",
    "汽车": "888706",
    "石油石化": "888707",
    "煤炭": "888708",
    "钢铁": "888709",
    "房地产": "888710",
    "纺织服饰": "888711",
    "通信": "888712",
    "交通运输": "888713",
    "轻工制造": "888714",
    "基础化工": "888715",
    "食品饮料": "888716",
    "非银金融": "888717",
    "农林牧渔": "888718",
    "有色金属": "888719",
    "建筑材料": "888720",
    "美容护理": "888721",
    "建筑装饰": "888722",
    "医药生物": "888723",
    "公用事业": "888724",
    "家用电器": "888725",
    "电子": "888726",
    "国防军工": "888727",
    "机械设备": "888728",
    "传媒": "888729",
    "计算机": "888730",
    "银行": "888731",
    "ETF": "888732",
  }
};
let index_code_keys = { base: '基础', plate: '板块' }; // 分类key查找中文名称
let codes_value = {}; // 反向，值生成key，value是中文名称，快速通过值找到名称，所有值名称
let code_value_2 = {};
let base_options = []; // 基础指数选择options
let base_plate_options = []; // 基础指数选择options,加板块
let cascaderOptions = [ // cascader 创建第一层分类
  createLabelValue(['基础', 'base']),
  createLabelValue(['全部', 'all']),
  createLabelValue(['分类1', '01']),
  createLabelValue(['分类2', '02']),
  createLabelValue(['分类3', '03']),
];
/*
  tips: 以下分类内容初始版本基础select选择即可，并未统一规划，之后拓展若干标准及分支，故代码一直在累加标准，比较难重构；

  分类指数数据; 策略指数按照name进行分类,根据不同分类创建可选择的label,value对象；
  【base】：指数参数的基础配置，code 字段设置初始值（每创建一个子分类，code会+1），key值自定义，fullname为中文全称
  (正常新增只需要增加或修改该数组即可)
  rulesKey:基础指标8888后面的key值；sub3Key需要执行‘拆分3’分类初始key值
*/
const SORT_INDEX = [ // code为该分类的初始值；下一个分类code = 上一个code + 6[子分类(5个) + 1(初始值本身)]
  { name: '标准', code: 999901, key: 'alphaX', fullName: 'Alpha指数-标准', rulesKey: '01', sub3Key: 20 },
  { name: '去限', code: 999907, key: 'test4', fullName: 'Alpha指数-去限', rulesKey: '02' },
  { name: '或量标准', code: 999913, key: 'test', fullName: 'Alpha指数-或量标准', rulesKey: '03', sub3Key: 24 },
  { name: '或量宽标', code: 999919, key: 'test2', fullName: 'Alpha指数-或量宽标', rulesKey: '04', sub3Key: 28 },
  { name: '6s滚动全量', code: 999925, key: 'test200', fullName: 'Alpha指数-6s滚动全量', rulesKey: '05', sub3Key: 16 },
  { name: '价限', code: 999931, key: 'test204', fullName: 'Alpha指数-价限', rulesKey: '06', sub3Key: 32 },
  { name: '升标', code: 999937, key: 'test206', fullName: 'Alpha指数-升标', rulesKey: '07' },
  { name: '标准增强', code: 999943, key: 'test207', fullName: 'Alpha标准规则增强', rulesKey: '08' },
  { name: '宽限', code: 999949, key: 'test208', fullName: 'Alpha宽限', rulesKey: '09' },
  { name: '首推', code: 999955, key: 'alphaTop', fullName: 'Alpha首推', rulesKey: '10' },
  { name: '卖出', code: 999961, key: 'alphaSell', fullName: 'Alpha卖出', rulesKey: '11' },
  { name: '大单成交额', code: 999967, key: 'hugeSell', fullName: 'Alpha标准大单成交额', rulesKey: '12' },
  { name: '去重', code: 999973, key: 'uniq', fullName: 'Alpha标准去重', rulesKey: '13' },
  { name: '量限测试', code: 999979, key: 'limitTest', fullName: 'Alpha标准量限测试', rulesKey: '14' },
  { name: '或量宽限', code: 999985, key: 'hlstander', fullName: 'Alpha或量标准宽限', rulesKey: '15' },
  { name: '或量标准双限', code: 999991, key: 'shuangxian', fullName: 'Alpha或量标准双限', rulesKey: '36' },
];
// 【子分类】；默认子分类，
const SORT_SUB_CATEGORY = ['沪深300', '中证500', '中证1000', '创业科创', '其他'];
// 【标准+】：增加子分类
const SORT_SUB_CATEGORY_2 = ['25', '50', '65', '70', '100', '200', '400'];
// 【拆分3】：增部分分类的子分类
const SORT_SUB_3 = ['沪深300', '中证500', '中证1000', '其他']; // 需要增加拆分的分类
const SORT_SUB_INDEX = ['或量标准', '标准', '6s滚动全量', '价限', '或量宽标']; // 需要拆分的sort_index的name
const SORT_SUB_CATEGORY_3 = ['25', '50', '65', '70', '100']; // 拆分3需要增加的分类后缀
// ___________________________________________________（以下无特殊需求 无需修改）___________________________________________________________
// 创建更多分类指数，例: 创建标准，标准=999901，标准-沪深300=999902.. 
function createExtraIndex(name, startCode) {
  let obj = {};
  obj[name] = String(startCode);
  SORT_SUB_CATEGORY.map((n, i) => {
    obj[n + '-' + name] = String(startCode + (i + 1));
  });
  return obj;
}
// 创建完整indexcode object文件
SORT_INDEX.map(item => {
  index_code_keys[item.key] = item.fullName;
  codes[item.key] = createExtraIndex(item.name, item.code);
});
/**  
 * console.log('codesObj', codes)
  [结果]  alphaSell {中证500-卖出:999963,中证1000-卖出:999964}
 */
// 遍历，增加增加第二级和第三级子项，并遍历时赋值其他需要的对象参数
const BASE_KEY = { 'base': '基础', 'plate': '板块' };
cascaderOptions.map(item => {
  // 基础没有二级菜单； 基础用于组件大部分Select单选组件; 
  if (item.value === 'base') {
    Object.keys(BASE_KEY).map((basekey, bi) => { // 【新】增加板块，base_options增加分组，
      let baseItem = { label: index_code_keys[basekey], options: [] };
      Object.keys(codes[basekey]).map((bk, i) => {
        const get_code_val = _.get(codes, `${basekey}.${bk}`);
        codes_value[get_code_val] = bk;
        item.children = _.concat(item.children || [], createLabelValue([bk, get_code_val]));
        baseItem.options[i] = createLabelValue([bk, get_code_val]); // 创建基础otpions
      })
      base_plate_options[bi] = baseItem;
      if (basekey === 'base') {
        base_options = baseItem.options;
      }
    })
  } else {
    const is_all_category = item.value === 'all' ? true : false; // 是否是“全部”分类下的
    // 其他指数信息创建二级和三级数据; 用于AlphaIndexCharts指数曲线
    Object.keys(codes).map(keyname => {
      if (!(keyname in BASE_KEY)) {
        const sindex = _.findIndex(SORT_INDEX, o => o.key === keyname); // 根据keyname找到SORT_INDEX里面的位置
        const getSortName = _.get(SORT_INDEX, `[${sindex}].name`, '');
        const findStartCode = String(SORT_INDEX[sindex].code); // 拿到标准code值
        let newChildren = []; let childrenSplit = [];
        Object.keys(codes[keyname]).map(k => {
          // k=沪深300-标准... 名称
          const getval = _.get(codes, `${keyname}.${k}`, '');
          // 除了全部分类，需要对key和name进行判断，分类1、2、3将名字和值后面分别增加01、02、03，进行区分分类
          const cval = !is_all_category ? getval + item.value : getval;
          const cname = !is_all_category ? k + item.value : k;
          codes_value[cval] = cname;
          newChildren = _.concat(newChildren, createLabelValue(
            [k, cval]
          ));
          // 【拆分3】部分增加 99990225/50...
          const split_name_3 = _.replace(k, '-' + getSortName, ''); // "沪深300-标准" 删除 "-标准"
          // 全部分类，包含SORT_SUB_3的板块，并且SORT的name也匹配分类
          if (is_all_category && _.includes(SORT_SUB_INDEX, getSortName) && _.includes(SORT_SUB_3, split_name_3)) {
            SORT_SUB_CATEGORY_3.map(s => {
              const cname_3 = k + '-' + s;
              const cval_3 = getval + s; // 沪深300-标准 999902 + 50... 
              codes_value[cval_3] = cname_3;
              childrenSplit = _.concat(childrenSplit, createLabelValue([cname_3, cval_3]));
            })
          }
        });
        // 标准+ 拼接字符串：99990150..999901100...  （在Alpha指数里面使用
        if (is_all_category) { // 只需要增加全部，分类里面不需要增加
          const childrenPlus = SORT_SUB_CATEGORY_2.map(s => {
            const plus_name = getSortName + '-' + s;
            const plus_value = findStartCode + s;
            codes_value[plus_value] = plus_name;
            return createLabelValue([plus_name, plus_value]);
          }); // 每个进行拼接
          newChildren = _.concat(newChildren, childrenPlus, childrenSplit);
        }
        item.children = _.concat(item.children || [], createLabelValue([index_code_keys[keyname], keyname, newChildren]));
      }
    });
  }
});

// console.log('cascaderOptions', cascaderOptions);

/*
  【rules】 每日记录新增的指数规则 - 基础指标 
  extraChart 非当日指数数据是可添加的基础指标 ，右侧两个cascader数据组件所需的数据结构
  SORT_INDEX 内的每组指标都需要创建 全部-分类1-分类2-分类3  888801+01/02 
  第二种拼接方法: 888801+01+ 今仓(01) + 涨停(A) = 8888010101A  
  规则：如果选择 888801(必填)01(选填) 后两位选填，但是只要选择后两位必填 01A
*/
const isValidString = (string) => string && _.isString(string) && string !== '' ? true : false;
const createCategoryName = (array) => {
  let finalObj = {};
  array.map(n => {
    if (isValidString(n.value)) {
      _.set(finalObj, n.value, n.label);
    }
  });
  return finalObj;
}
const category_31 = [
  createLabelValue(['全部', '']),
  createLabelValue(['分类1', '01']), createLabelValue(['分类2', '02']), createLabelValue(['分类3', '03']),
  ...SORT_SUB_CATEGORY_2.map(s => createLabelValue([s, s]))
];
const category_32 = [
  createLabelValue(['今仓', '01']), createLabelValue(['昨仓', '02']), createLabelValue(['前仓', '03']),
  createLabelValue(['前1', '04']), createLabelValue(['前2', '05']), createLabelValue(['前3', '06'])
];
const category_33 = [createLabelValue(['涨停', 'A']), createLabelValue(['非涨停', 'B']), createLabelValue(['平均', 'C'])];

// 分组的value值，对应的中文名称，用于拼接折线图的name，最后拼接显示
let category_3_name = {
  '1': createCategoryName(category_31),
  '2': createCategoryName(category_32),
  '3': createCategoryName(category_33),
}
let category_5_name = {}; // 指数指标反向查找的object
// SORT_INDEX每个指数递增，标准=888801 去限=888802.. baseKey是校验用，8888+rulesKey = 基础指标数值
const rules_pre_key = '8888';
let cascaderOptions_3 = [];
let cascaderOptions_4 = [];
let cascaderOptions_5 = []; //  指数指标
SORT_INDEX.map(n => {
  const newRulesIndex = parseInt(rules_pre_key + n.rulesKey); // 静态数据拼接成为指数value
  const category_31_name = '基础指标-';
  /**
   *    指数指标分类数据：
        code("888816")  ("Alpha指数-6s滚动全量-基础指标-沪深300")
        code("888817")  ("Alpha指数-6s滚动全量-基础指标-中证500")
        code("888818")  ("Alpha指数-6s滚动全量-基础指标-中证1000")
        code("888819")  ("Alpha指数-6s滚动全量-基础指标--其他")
   */
  if ('sub3Key' in n) {
    // 创建3级的options_5,
    cascaderOptions_5.push(createLabelValue([
      n.name,
      n.rulesKey, // 该值不重复就行，使用时会替换掉
      SORT_SUB_3.map((si, i) => { //用拆分3分类去创建二级
        const si_key = String(n.sub3Key + i); // 初始sub3Key每次+i就是对应值
        _.set(category_5_name, rules_pre_key + si_key, n.name + '-' + si);
        return createLabelValue([
          si,
          si_key,
          SORT_SUB_CATEGORY_3.map(c => { // 增加拆分3的拼接后缀
            _.set(category_5_name, rules_pre_key + si_key + c, n.name + '-' + si + '-' + c)
            return createLabelValue([c, c]);
          })
        ]);
      })
    ]));
  }
  // 基础指标options_3的创建
  cascaderOptions_3.push(createLabelValue([n.name, newRulesIndex, category_31]));
  codes_value[String(newRulesIndex)] = category_31_name + n.name; // 保存基础指标
});
category_32.map(n => {
  _.set(n, 'children', category_33);
  cascaderOptions_4.push(n);
});

// console.log('cascaderOptions_3', cascaderOptions_3);
// console.log('cascaderOptions_4', cascaderOptions_4);
// console.log('cascaderOptions_5', cascaderOptions_5);

/*
  230905新增基础对比指标
*/
const SORT_INDEX_2 = [
  { name: '标准', key: 'standard', fullName: 'Alpha指数-标准', rulesKey: '01' },
  { name: '或量标准', key: 'hlbz', fullName: 'Alpha指数-或量标准', rulesKey: '03' },
  { name: '或量宽标', key: 'hlkb', fullName: 'Alpha指数-或量宽标', rulesKey: '04' },
];
const PUSH_COUNT_2 = ['25', '50', '100'].map(s => createLabelValue([s, s]));
const CATEGORY_2_1 = [createLabelValue(['昨仓', '02']), createLabelValue(['前仓', '03'])]
const CATEGORY_2_2 = [createLabelValue(['平均', 'C']), createLabelValue(['绝对涨跌幅', 'D']), createLabelValue(['相对涨跌幅', 'E'])];
const DE_LEVEL_2 = ['1', '2', '3', '4'].map(s => createLabelValue([s + '%', s]));
// 合并3里面的中文字符，实现category_3_name里面的字段通用
_.set(category_3_name, '3', _.assign(category_3_name[3], createCategoryName(CATEGORY_2_2)));
_.set(category_3_name, '4', createCategoryName(DE_LEVEL_2));

let cas2_1 = []; let cas2_2 = [];
SORT_INDEX_2.map(sort => {
  cas2_1.push(createLabelValue([
    sort.name,
    rules_pre_key + sort.rulesKey,
    PUSH_COUNT_2
  ]));
  _.set(code_value_2, rules_pre_key + sort.rulesKey, sort.name);
});
CATEGORY_2_1.map(cat => {
  cas2_2.push(createLabelValue([
    cat.label,
    cat.value,
    CATEGORY_2_2.map(c2 => {
      if (_.includes(['D', 'E'], c2.value)) {
        _.set(c2, 'children', DE_LEVEL_2);
      }
      return c2;
    })
  ]))
})

/**
 * index板块
 */
const plate_options = [
  createLabelValue(['上证指数', '000001.SH']),
  createLabelValue(['上证50', '000016.SH']),
  createLabelValue(['科创50', '000688.SH']),
  createLabelValue(['中证1000', '000852.SH']),
  createLabelValue(['中证500', '000905.SH']),
  createLabelValue(['深证成指', '399001.SZ']),
  createLabelValue(['创业板指', '399006.SZ']),
  createLabelValue(['沪深300', '399300.SZ']),
  createLabelValue(['国证2000', '399303.SZ'])
];
const plate_options_alpha = SORT_INDEX.map(n => createLabelValue([n.fullName, n.code]));

export {
  cascaderOptions,
  codes_value, code_value_2,
  base_options, base_plate_options,
  createLabelValue,
  commonIndexOptions,
  COMMON_INDEX_2,
  commonIndexValueType,
  rules_pre_key,
  cascaderOptions_3, cascaderOptions_4, category_3_name,
  cascaderOptions_5, category_5_name,
  cas2_1, cas2_2,
  plate_options, plate_options_alpha,
};
