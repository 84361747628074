import React, { useState } from 'react';
import { Row, Col, Card, Select, Table, Space, Typography } from 'antd'; //Switch
import { showSellPositionRate } from '@/api/stock';
import { commonIndexOptions } from '@/utils/indexCodes';
import { autoCol, validateResponse, RateText } from '@/utils/utils';
import { DAY_COLOR, simpleColumns, isValidArray, isValidObj, createDatasTimeKey } from '@/utils/utils2';
import { useMount, useReactive, useUnmount } from 'ahooks';
import { NewRangePicker } from '@/view/common/widgets';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY_FORMAT = "YYYY-MM-DD";
const TODAY = moment().format(TODAY_FORMAT);

const SignalTable = ({ }) => {
  const pageState = useReactive({
    date: TODAY,
    type: commonIndexOptions[3].value,
    s_type: 6,
    spin: false,
    validStatus: false
  });
  const [signalDatas, setSignalDatas] = useState({});
  let isUnmont = false;

  useMount(() => {
    _showSellPositionRate();
  });

  useUnmount(() => {
    isUnmont = true;
  });
  // T0板块推送信号统计
  async function _showSellPositionRate() {
    pageState.spin = true;
    let params = {
      'tradeDate': pageState.date,
      'indexCode': pageState.type,
      'type': pageState.s_type
    }
    const res = await showSellPositionRate(params);
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data', {});
      if (isValidObj(getData)) {
        // 创建日期timeKey
        const timeArray = createDatasTimeKey(
          _.keys(getData).map(t => { return { date: t } }),
          'date',
        );
        // 找到获取日期
        const todayTimeKey = _.get(timeArray, `[${_.findIndex(timeArray, o => o.date === params.tradeDate)}].timeKey`, 0);
        const todaySignal = _.get(getData, params.tradeDate, []);
        let today = []; let todayValid = [];
        let yester = []; let yesTimeKey = 0; let last = [];
        if (isValidArray(timeArray)) {
          // 动态处理昨仓前仓数据；如再往前可根据该遍历增加更多之前仓位数据的条件
          timeArray.map(t => {
            const cur = _.get(t, 'timeKey');
            // 昨仓一定比获取日期小 ; 赋值昨天的timeKey=yesTimeKey
            if (cur < todayTimeKey && yesTimeKey === 0) {
              yester = _.get(getData, t.date, []);
              yesTimeKey = cur
            }
            // timeKey小于昨天，并且yesTimeKey昨天timekey已经赋值
            if (cur < yesTimeKey && yesTimeKey !== 0) {
              last = _.get(getData, t.date, []);
            }
          });
        }
        if (isValidArray(todaySignal)) {
          const dayClose = params.tradeDate + ' 15:01:00';
          todaySignal.map((sitem, i) => {
            // 创建id及从收盘开始计算的排序timeKey
            const idObj = {
              'uuid': _.get(sitem, 'symbol', '') + i,
              'time_key': moment(_.get(sitem, 'date', '') + ' ' + _.get(sitem, 'time', '')).diff(moment(dayClose), params.type === 6 ? 's' : 'millisecond'),
              'y_costRate': 0, // 排序用，默认创建为 0
              'l_costRate': 0,
            }
            today.push(_.assign(idObj, sitem));
            let yfidx = _.findIndex(yester, o => o.symbol === sitem.symbol);
            let lfidx = _.findIndex(last, o => o.symbol === sitem.symbol);
            let newItem = {};
            if (yfidx !== -1) { // 昨仓有数据，赋值所以昨仓数据，并字段增加 y_
              _.keys(yester[yfidx]).map(ykey => _.set(newItem, 'y_' + ykey, yester[yfidx][ykey]))
            }
            if (lfidx !== -1) {  // 前仓有数据，赋值所以昨仓数据，并字段增加 y_
              _.keys(last[lfidx]).map(lkey => _.set(newItem, 'l_' + lkey, last[lfidx][lkey]))
            }
            if (yfidx !== -1 || lfidx !== -1) { // 昨仓前仓有数据统计算作有效数据
              todayValid.push(_.assign(idObj, sitem, newItem));
            }
          });
        }
        setSignalDatas({ today, todayValid });
        // console.log({ today, todayValid })
      }
    }
    pageState.spin = false;
  }

  const columns = [
    simpleColumns(['代码', 'symbol']),
    simpleColumns(['名称', 'name']),
    {
      ...simpleColumns(['时间', 'time']),
      sorter: (a, b) => a.time_key - b.time_key,
      sortDirections: ['descend', 'ascend'],
    },
    simpleColumns(['今价', 'price']),
  ];
  const columns_pre = [
    {
      ...simpleColumns(['昨价', 'yester1']),
      render: (text, record) => {
        return 'y_price' in record ? <Text style={{ color: DAY_COLOR['yesterday'] }}>{_.get(record, 'y_price', 0)}</Text> : ''
      }
    },
    {
      ...simpleColumns(['昨益', 'yester2']),
      sorter: (a, b) => a.y_costRate - b.y_costRate,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const isValidRate = ('y_costRate' in record) && _.get(record, 'y_costRate', 0) ? true : false;
        return isValidRate ? <RateText rate={_.get(record, 'y_costRate', 0)} carry={4} /> : ''
      }
    },
    {
      ...simpleColumns(['前价', 'last1']),
      render: (text, record) => {
        return 'l_price' in record ? <Text style={{ color: DAY_COLOR['preday'] }}>{_.get(record, 'l_price', 0)}</Text> : ''
      }
    },
    {
      ...simpleColumns(['前益', 'last2']),
      sorter: (a, b) => a.l_costRate - b.l_costRate,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const isValidRate = ('l_costRate' in record) && _.get(record, 'l_costRate', 0) ? true : false;
        return isValidRate ? <RateText rate={_.get(record, 'l_costRate', 0)} carry={4} /> : ''
      }
    },
  ];

  return <Card bodyStyle={{ padding: 8 }} style={{ height: 900 }}>
    <Row style={{ marginBottom: 12 }} align='middle'>
      <Col {...autoCol([12])}>
        <NewRangePicker
          isSingle
          value={pageState.date}
          size='small'
          onSelect={(dateStrings) => {
            pageState.date = dateStrings;
            _showSellPositionRate();
          }}
        />
      </Col>
      <Col {...autoCol([12])} style={{ textAlign: 'right' }}>
        <Space>
          {/* <Switch
            checkedChildren="全部"
            unCheckedChildren="有效"
            size='small'
            checked={pageState.validStatus}
            onChange={(checked) => pageState.validStatus = checked}
          /> */}
          <Select style={{ width: 125 }} value={pageState.s_type} size='small' bordered={false}
            options={[{ label: '卖出', value: 6 }, { label: 'Alpha标准', value: 201 }, { label: 'Alpha或量标准', value: 202 }]}
            onChange={(v) => {
              pageState.s_type = v;
              _showSellPositionRate();
            }} />
          <Select style={{ width: 115 }} value={pageState.type} size='small'
            options={commonIndexOptions}
            onChange={(v) => {
              pageState.type = v;
              _showSellPositionRate();
            }} />
        </Space>
      </Col>
    </Row>

    <Table
      rowKey={'uuid'}
      dataSource={_.get(signalDatas, 'todayValid', [])}
      columns={_.concat(columns, columns_pre)}
      // dataSource={pageState.validStatus ? _.get(signalDatas, 'today', []) : _.get(signalDatas, 'todayValid', [])}
      // columns={pageState.validStatus ? columns : _.concat(columns, columns_pre)}
      size='small'
      loading={pageState.spin}
      scroll={{ y: 805 }}
      pagination={false}
    />
  </Card>
}

export default SignalTable;