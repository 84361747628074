import React, { useState } from 'react';
import { Row, Col, Select, Space, Button, Typography, Tag, message, Divider, Segmented } from 'antd';
import { CheckCircleOutlined, PlusOutlined, MinusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { showStrategyRunning } from '@/api/stock';
import { validateResponse } from '@/utils/utils';
import { isValidString, isValidArray, isValidNumber, typeValueOptions, labelValues, createDatasTimeKey } from '@/utils/utils2';
import { useMount, useUnmount, useReactive } from 'ahooks';
import { NewRangePicker } from '@/view/common/widgets';
import TwoCharts from './TwoCharts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY_FORMAT = "YYYY-MM";
const TODAY = moment().format(TODAY_FORMAT);
const RANGE_DATE = [moment().subtract(1, 'y').format(TODAY_FORMAT), TODAY];
const FILTER_TYPE_OPTIONS = _.filter(typeValueOptions, o => [201, 202, 203].indexOf(o.value) !== -1);
const TYPE_ELSE_OPTIONS = _.concat(FILTER_TYPE_OPTIONS, FILTER_TYPE_OPTIONS.map(n => labelValues([n.label + '-其他', parseInt(String(n.value) + '1')])));
const NUM_OPTIONS = [1, 3, 5, 10, 20].map(n => labelValues([String(n), n]));
const NUM_COUNT_OPTIONS = [50, 100].map(n => labelValues([String(n), n]));
const RULES_OPTIONS = [labelValues(['常用', 'A']), labelValues(['自定义', 'B'])];
const NUM_CN = { 1: '一', 3: '三', 5: '五', 7: '七', 10: '十', 20: '二十', 15: '十五', 20: '二十', '<': '小于', '>': '大于' };
const FULL_STRING_ARR = ['天分钟成交量基准大于', '天分钟成交量基准'];
/**  常用条件：
3>7且5<10
 3>7且5<15
 3>7且5<20
 5>10且10<15
 5>10且10<20
 10>15且20<20
 3>10且5<15
 3>10且5<20
 5>15月10<20
 */
const USUAL_OPTIONS = [
  [3, 7, 5, 10], [3, 7, 5, 15], [3, 7, 5, 20],
  [5, 10, 10, 15], [5, 10, 10, 20], [10, 15, 20, 20],
  [3, 10, 5, 15], [3, 10, 5, 20], [5, 15, 10, 20],
].map(n => labelValues([
  `${n[0]}>${n[1]}且${n[2]}<${n[3]}`,
  `${n[0]}>${n[1]}且${n[2]}<${n[3]}` // 前格式为拼装为自定义格式的字符串，现用该值即可获取
])); // 拼装完整常用条件
// 动态推送分析tab页面
const PushTwo = ({ routeType }) => {
  const twoState = useReactive({
    spin: false, signalType: '', num: 50, condition1: 3, condition2: null, conditionName: {},
    rulesType: 'A', usuVal: '', usuText: '', isAdd: false
  });
  const [conditionArr, setConditionArr] = useState([]); // 所有规则数组
  const [dates, setDates] = useState(RANGE_DATE);
  const [upcount, setUpcount] = useState(0);
  const [pushValue, setPushValue] = useState({}); // {条件1:[周期内的全部数据],条件2:[]...}
  const [getPms, setGetPms] = useState({}); // 上一次请求参数
  let isUnmont = false;

  useMount(() => {
    if (isValidNumber(routeType)) {
      twoState.signalType = routeType;
    }
  });

  useUnmount(() => {
    isUnmont = true;
  });
  // 获取区间策略
  async function _showStrategyRunning() {
    twoState.spin = true;
    let combString = _.join(conditionArr.map(n => n.full_string), '');
    let params = {
      start: dates[0],
      end: dates[1],
      type: twoState.signalType,
      num: twoState.num,
      combName: encodeURIComponent(combString) // get方法中文需要编码字符
    }
    // console.log(combString);
    let isNew = false; //基础条件修改则重置temp字段为空，否则为叠加态
    ['start', 'end', 'num'].map(k => { //【231007】 取消type，可叠加其他推送标准
      if (params[k] !== getPms[k]) {
        isNew = true;
        twoState.isAdd = true;
      }
    });
    if (!isValidString(combString)) {
      message.info('请添加规则！');
      twoState.spin = false;
      return
    }
    const res = await showStrategyRunning(params);
    if (validateResponse(res, isUnmont)) {
      let temp = isNew ? {} : _.cloneDeep(pushValue); // 相同条件下，每次获取条件进行叠加
      const getData = _.get(res, 'data', []);
      if (isValidArray(getData)) {
        let findex = _.findIndex(TYPE_ELSE_OPTIONS, o => o.value === params.type);
        // time字段占用一个size，所以递增无需+1; 增加分类名称
        let newItemName = `条件${_.size(temp) || 1}(${_.replace(TYPE_ELSE_OPTIONS[findex].label, 'Alpha', '')})`;
        // 排序，并创建时间字段
        let orderData = createDatasTimeKey(getData, 'date', 'd', 'asc');
        _.set(temp, newItemName, orderData);
        _.set(temp, 'time', orderData.map(n => _.get(n, 'dateDuration', '')));
        //console.log('temp', temp);
        setPushValue(temp);
        // 保存简写字符串
        if (isNew) {
          twoState.conditionName = {};
        }
        _.set(twoState.conditionName, newItemName, _.join(conditionArr.map(n => n.str), '; '));
      } else {
        message.info('暂无数据！')
      }
      setUpcount(_.round(upcount + 0.1, 1));
      setGetPms(params);
    }
    twoState.spin = false;
  }
  // 添加查询条件
  function addCondition() {
    let tempArr = _.cloneDeep(conditionArr);
    if (isValidNumber(twoState.condition1) && isValidNumber(twoState.condition2)) {
      let cur = `${twoState.condition1}>${twoState.condition2}`; // 固定条件字符串c1>c2
      let curObj = {
        'c1': twoState.condition1, 'c2': twoState.condition2,
        'str': cur, // 简写
        // 完整条件字符串，使用中文数字
        'full_string': `[${NUM_CN[twoState.condition1]}${FULL_STRING_ARR[0]}${NUM_CN[twoState.condition2]}${FULL_STRING_ARR[1]}]`,
      };
      if (_.size(conditionArr) >= 4) {
        message.info('可添加4条规则！');
        return;
      }
      let isSame = false; // 判断是否有相同条件
      conditionArr.map(c => {
        if (_.get(c, 'str', '') === cur) {
          isSame = true;
        }
      })
      if (isSame) {
        message.info('条件相同！');
        return;
      }
      // c1条件必须小于c2
      if (curObj.c1 < curObj.c2) {
        tempArr.push(curObj); // 增加curobj
        tempArr = _.orderBy(tempArr, ['c1', 'c2'], ['asc', 'asc']);
        setConditionArr(tempArr);
      } else {
        message.info('成交量1必须小于成交量2！');
        return;
      }
    } else {
      message.info('请选择正确的成交量！');
    }
  }
  // 删除条件; pushValue除time字段，都是条件数据，图表直接使用
  function delCondition(keyname) {
    let temp = {}; let tempName = {};
    _.keys(pushValue).map(k => {
      if (k !== keyname && k !== 'time') {
        _.set(temp, k, pushValue[k]);
        _.set(tempName, k, twoState.conditionName[k]);
      }
    });
    // 删除时只剩下time字段则情况，否则将time字段保留
    if (('time' in pushValue) && _.size(pushValue) > 1) {
      _.set(temp, 'time', pushValue.time);
    }
    setPushValue(temp);
    twoState.conditionName = tempName;
    setUpcount(_.round(upcount + 0.1, 1));
  }

  const num_select_style = { size: 'small', style: { width: 75 }, options: NUM_OPTIONS };
  const usual_select_style = { size: 'small', style: { width: 155 }, options: USUAL_OPTIONS, showSearch: true };
  const rules_string = _.join(conditionArr.map(n => n.full_string), '');
  return (
    <>
      <Row gutter={[16, 16]} align='middle'>
        <Col span={24}>
          <Space>
            <Select style={{ width: 155 }} value={twoState.signalType}
              options={TYPE_ELSE_OPTIONS}
              onChange={(val) => {
                twoState.signalType = val;
              }}
            />
            <Select style={{ width: 100 }} value={twoState.num}
              options={NUM_COUNT_OPTIONS}
              onChange={(val) => {
                twoState.num = val;
                twoState.isAdd = false;
              }}
            />
            <NewRangePicker
              dates={dates}
              restRange={{ picker: 'month' }}
              onSelect={(dateStrings) => {
                setDates(dateStrings); // 2023-03 
                twoState.isAdd = false;
              }}
            />
            <Button
              icon={<CheckCircleOutlined />}
              type='primary'
              onClick={() => _showStrategyRunning()}>
              {twoState.isAdd ? '添加' : '确定'}
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Space size='small'>
            <Text type='secondary'>规则：</Text>
            <Segmented size={'small'} options={RULES_OPTIONS} onChange={v => {
              twoState.rulesType = v;
              setConditionArr([]);
            }} />
            {twoState.rulesType === 'A' ? <Space size='small'>
              <Select {...usual_select_style} value={twoState.usuVal}
                onSearch={(v) => {
                  if (isValidString(v)) {
                    twoState.usuText = v;
                  }
                }}
                onChange={(val) => {
                  twoState.usuVal = val;
                  // 与自定义保持格式一致
                  let fidx = _.findIndex(USUAL_OPTIONS, o => o.value === val);
                  setConditionArr([{ 'full_string': USUAL_OPTIONS[fidx].value, 'str': USUAL_OPTIONS[fidx].label }]);
                }}
              />
              <Button size='small' type='link' disabled={!isValidString(twoState.usuText)} onClick={() => {
                setConditionArr([{ 'full_string': twoState.usuText, 'str': twoState.usuText }]);
              }}>添加</Button>
              <Button size='small' danger type='text' disabled={!isValidString(twoState.usuText)}
                onClick={() => {
                  setConditionArr([]);
                  twoState.usuText = '';
                }} >
                清除
              </Button>
              <Text type='secondary'>
                <InfoCircleOutlined style={{ marginRight: 2 }} />
                可选择常用，也可直接输入条件，【添加】条件并【确认】
              </Text>
            </Space> : <Space size='small'>
              <Select {...num_select_style} value={twoState.condition1}
                onChange={(val) => twoState.condition1 = val}
              />
              <Text>天分钟成交量基准</Text>
              <Text>{'大于'}</Text>
              <Select {...num_select_style} value={twoState.condition2}
                onChange={(val) => twoState.condition2 = val}
              />
              <Text>天分钟成交量基准</Text>
              <Button size='small' type='link' onClick={addCondition} icon={<PlusOutlined />}>添加</Button>
              <Button size='small' danger type='text' icon={<MinusOutlined />}
                onClick={() => setConditionArr([])} disabled={isValidString(rules_string) ? false : true}>
                清除
              </Button>
            </Space>}
          </Space>
        </Col>
        <Col span={24}>
          <Space size='small'>
            <Text type='secondary'>条件：</Text>
            <Text>{rules_string}</Text>
          </Space>
        </Col>
        <Col span={24}>
          <Space size='small'>
            <Text type='secondary'>已选：</Text>
            {_.keys(twoState.conditionName).map(keyname => <Tag key={keyname} closable onClose={() => {
              delCondition(keyname)
            }}>{`${keyname} : ${_.get(twoState.conditionName, keyname, '')}`}</Tag>)}
          </Space>
        </Col>
      </Row>

      <Divider />

      <TwoCharts
        updateCount={upcount}
        datas={pushValue}
        pushNames={twoState.conditionName}
        loading={twoState.spin}
      />
    </>
  )
}

export default PushTwo;