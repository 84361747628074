import React from 'react';
import { Table, Button } from 'antd';
import { simpleColumns } from '@/utils/utils2';
import { RateText } from '@/utils/utils';
import _ from 'lodash';

const PlatesAllTable = ({ datas = [], yHeight = 200, loading = false, onPlateClick }) => {
  const columns = [
    {
      ...simpleColumns(['板块', 'pname']),
      render: (text) => <Button type='text' size='small' onClick={() => onPlateClick(text)}>
        {text || ''}
      </Button>
    },
    {
      ...simpleColumns(['涨跌幅', 'plateRate']),
      sorter: (a, b) => a.plateRate - b.plateRate,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <RateText rate={text} carry={4} />
    },
    {
      ...simpleColumns(['建仓平均收益率', 'costAvgRate']),
      sorter: (a, b) => a.costAvgRate - b.costAvgRate,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <RateText rate={text} carry={4} />
    },
  ];

  return <Table
    rowKey={'id'}
    dataSource={datas}
    columns={columns}
    size='small'
    loading={loading}
    scroll={{ y: yHeight }}
    pagination={false}
  />
}

export default PlatesAllTable;