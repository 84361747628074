import React from 'react';
import VirtualTable from '@/view/common/Components/VirtualTable';
import { calColumnsWidth } from '@/utils/utils2';
import _ from 'lodash';

// 推送基础表格
const PushTable = ({ datas, newCol, spining }) => {
  return (
    <>
      <VirtualTable
        rowKey={'index'}
        dataSource={datas}
        columns={newCol}
        loading={spining}
        scroll={{ y: 680, x: calColumnsWidth(newCol) }}
        pagination={false}
      />
    </>
  )
};

// 推送45分收盘表格
const CloseTables = ({ datas, newCol, spining }) => {
  return (
    <VirtualTable
      rowKey={'index'}
      dataSource={datas}
      columns={newCol}
      loading={spining}
      scroll={{ y: 680, x: calColumnsWidth(newCol) }}
      pagination={false}
    />
  )
}


export {
  PushTable,
  CloseTables
}