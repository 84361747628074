import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Badge,
  List,
  Segmented,
  Table,
  Modal,
  Space
} from 'antd';
import { CaretUpOutlined, CaretDownOutlined, RightOutlined } from '@ant-design/icons';
import { showStockAnalysis } from '@/api/stock';
import { createBasicColums, autoCol, validateResponse, SyncButton } from '@/utils/utils';
import { commonIndexOptions } from '@/utils/indexCodes';
import { getStorage, isValidArray } from '@/utils/utils2';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMediaQuery } from 'react-responsive';
import { useMount, useUnmount } from 'ahooks';
import { NewRangePicker } from '@/view/common/widgets';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');

const col_list = [
  ['股票名称', 'name', 100],
  ['行业', 'industry', 80],
  ['板块', 'plate', 80],
  ['日期', 'date', 100],
  ['时间', 'time', 100],
  ['价格', 'price', 80],
  ['收益率', 'rate', 80, 'rate'],
];
// pageKey: 'assetsShow':首页, 'tzero': T0策略页面
const StockAnalyse = ({ pageKey = '' }) => {
  const mobxStore = useLocalObservable(() => store);
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idValue, setIdValue] = useState('else');
  const [sDate, setSDate] = useState([TODAY, TODAY])
  const [visible, setVisible] = useState({ show: false, table: [] });
  const [glbStock] = useState(JSON.parse(JSON.stringify(mobxStore.stockAnalysis)));
  let isUnmont = false;

  const columns = createBasicColums(col_list, [6, 'updown']);

  useMount(() => {
    const isGlobalStock = _.get(glbStock, pageKey, null);
    const tradingStatus = getStorage('2');
    if (!!isGlobalStock && tradingStatus === 'rest') {
      const glbIndexCode = _.get(glbStock, `${pageKey}.pms.indexCode`, '');
      let fIndex = _.findIndex(commonIndexOptions, o => o.value === glbIndexCode);
      if (fIndex !== -1) {
        setIdValue(commonIndexOptions[fIndex].value)
      }
      setStockData(_.get(glbStock, `${pageKey}.data`, []));
      setSDate([_.get(glbStock, `${pageKey}.pms.startDate`, ''), _.get(glbStock, `${pageKey}.pms.endDate`, '')]);
    } else {
      // handleUpdate();
    }

    handleUpdate();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  function handleUpdate(idv, date) {
    let params = {
      startDate: date ? date[0] : sDate[0],
      endDate: date ? date[1] : sDate[1],
      indexCode: idv ? idv : idValue,
      type: pageKey === 'tzero' ? 6 : 5 // 【原】涨跌可切换
    }
    _getStock(params);
  }

  const _getStock = async (pms) => {
    let temp = _.cloneDeep(glbStock);
    setLoading(true)
    const res = await showStockAnalysis(pms);
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data', []);
      let newData = [];
      Object.keys(getData).map((keyname, i) => {
        const nameArray = _.split(keyname, '_');
        newData.push({
          key: 'list' + i,
          name: nameArray[0],
          symbol: nameArray[1],
          detail: getData[keyname]
        })
      })
      setStockData(newData);
      temp[pageKey] = {
        data: newData,
        pms: {
          startDate: pms.startDate,
          endDate: pms.endDate,
          indexCode: pms.indexCode,
        }
      }
      mobxStore.changeStockAnalysis(temp)
      setLoading(false);
    }
  }

  const upStyle = { style: { color: '#ff4d4f' } };
  const downStyle = { style: { color: '#52c41a' } };
  const refreshHeaderStyles = isMobile ? { marginBottom: 8, height: 128 } : { marginBottom: 8 };
  const updownIcon = pageKey === 'assetsShow' ? { title: <Text {...upStyle}>{'涨幅'}</Text>, icon: <CaretUpOutlined {...upStyle} /> } : { title: <Text {...downStyle}>{'跌幅'}</Text>, icon: <CaretDownOutlined {...downStyle} /> };
  return <Card bodyStyle={{ padding: 8 }} style={{ height: pageKey === 'assetsShow' ? 770 : 900, overflowY: 'scroll' }}>
    <div className='refreshHeader' style={refreshHeaderStyles}>
      <Row align='middle'>
        <Col {...autoCol([12])}>
          <NewRangePicker
            dates={sDate}
            size='small'
            onSelect={(dateStrings) => {
              setSDate(dateStrings);
              handleUpdate(null, dateStrings);
            }}
          />
        </Col>
        <Col {...autoCol([12])} style={{ textAlign: 'right' }}>
          <Segmented
            options={commonIndexOptions}
            value={idValue}
            onChange={(v) => {
              setIdValue(v);
              handleUpdate(v);
            }}
            size='small'
          />
        </Col>
      </Row>
      <Row align='middle' justify='space-between' style={{ marginTop: 6 }}>
        <Col {...autoCol([6])}>
          <Space size='small'>
            {updownIcon.icon}
            {updownIcon.title}
          </Space>
        </Col>
        <Col {...autoCol([6])} style={{ textAlign: 'right' }}>
          <SyncButton onClick={() => handleUpdate()} />
        </Col>
      </Row>
    </div>

    {/* <div style={{ padding: 5, textAlign: 'center', marginTop: 10 }}>

      <Tabs
        activeKey={active}
        centered
        onChange={(activeKey) => {
          setActive(activeKey)
          handleUpdate(activeKey)
        }}
      >
        <TabPane tab={<><UpCircleOutlined {...upStyle} /><Text {...upStyle}>上涨</Text></>} key="1" />
        <TabPane tab={<><DownCircleOutlined {...downStyle} /><Text {...downStyle}>下跌</Text></>} key="2" />
      </Tabs>
    </div> */}

    <List
      itemLayout="horizontal"
      dataSource={stockData}
      loading={loading}
      size='small'
      renderItem={item => {
        return <List.Item style={{ padding: 6 }}>
          <List.Item.Meta
            title={`${_.get(item, 'name', '')}    ${_.get(item, 'symbol', '')}`}
          // description={`${item.market || ''} - ${item.industry || ''} - ${item.plate || ''}`}
          />
          <div style={{ cursor: 'pointer' }} onClick={() => {
            setVisible({
              show: true,
              table: isValidArray(_.get(item, 'detail', [])) ? _.get(item, 'detail').map((n, i) => _.assign(n, { key: i })) : []
            })
          }}>
            <Badge count={_.size(item.detail)} style={{ backgroundColor: '#bfbfbf' }} />
            {_.size(item.detail) > 0 && <Button type='text' icon={<RightOutlined style={{ color: '#bfbfbf' }} />} />}
          </div>
        </List.Item>
      }}
    />
    <Modal
      title='涨跌幅分析'
      width={760}
      visible={_.get(visible, 'show', false)}
      onCancel={() => setVisible({ show: false, table: [] })}
      footer={null}
    >
      <Table
        rowKey={'key'}
        dataSource={_.get(visible, 'table', [])}
        columns={columns}
        // scroll={{ x: 1000 }}
        size='small'
        pagination={{
          pageSize: 10
        }}
      />
    </Modal>
  </Card>
}

export default StockAnalyse;