import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox, Radio, Tag, Spin, Table, Space, Button, Typography } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { commonIndexValueType, createLabelValue } from '@/utils/indexCodes';
import { VALUE_TYPE, isValidArray } from '@/utils/utils2';
import { autoCol, createSingleColumns } from '@/utils/utils';
import { LIMIT_RANGE_OPTIONS } from '../Components/sutil';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import * as echarts from 'echarts';

const { Text } = Typography;
const OPTIONS = {
  'Rate': '命中率', 'Acc': '准确率', 'Recall': '召回率'
};
const KEYS_ARRAY = [ // calKey 是固定值，区分今昨前及盘中今昨前的 key值
  { name: '今', key: 'num', calKey: 'A' },
  { name: '今', key: 'num', isTrading: true, calKey: 'E' },
  { name: '昨', key: 'ynum', calKey: 'B' },
  { name: '昨', key: 'ynum', isTrading: true, calKey: 'F' },
  { name: '前', key: 'preYNum', calKey: 'C' },
  { name: '前', key: 'preYNum', isTrading: true, calKey: 'G' },
];
const STATISTIC_ARRAY = [
  { name: '命中率', key: 'rate' },
  { name: '命中率(盘中)', key: 'rateTraing' },
  { name: '召回率', key: 'recall' },
  { name: '召回率(盘中)', key: 'recallTrading' },
];
// 波幅计算固定object值
const CAL_KEY_OBJ = { 'F': '昨盘比', 'B': '昨比', 'G': '前盘比', 'C': '前比' };
const CAL_COLOR_OBJ = { 'F': '#ffbb00', 'B': '#ff7831', 'G': '#007fba', 'C': '#006f81' };
// 主键与组别常量
const CAL_MAIN1 = 'A'; const CAL_GROUP1 = ['B', 'C'];
const CAL_MAIN2 = 'E'; const CAL_GROUP2 = ['F', 'G'];
// 分类tag
const TAGS_ARRAY = KEYS_ARRAY.map(n => createLabelValue(['isTrading' in n ? n.name + '(盘中)' : n.name, n.calKey]));
// 默认涨停分组统计
const DEFAULT_SUM = [createLabelValue(['涨停票', 'limits']), createLabelValue(['全市场涨停票', 'limitTotal']), createLabelValue(['推送总数', 'bases'])];
// 两个数组数据相除得到新的数组
const arraysDiv = (arrPrev = [], arrToday = []) => {
  let final = [];
  if (isValidArray(arrPrev) && isValidArray(arrToday)) {
    arrPrev.map((n, i) => {
      final[i] = _.round(n / _.get(arrToday, `[${i}]`, 0), 3);
    })
  }
  return final;
}
/**
 *  特定时间区间内涨停票及比率数据的计算；并且可进行累加数据展示进行对比
 */
export default function LimitChartsRange(props) {
  const [segVal, setSegVal] = useState('Rate');
  const [calDatas, setCalDatas] = useState([]);
  const [currentSeries, setCurrentSeries] = useState([]); // 折线 处理完的series数据
  const [tagUpdate, setTagUpdate] = useState(0); // checkbox 更新
  const [tagUpdate3, setTagUpdate3] = useState(0);
  const [tagVals, setTagVals] = useState([]); // 已选的checkbox的value
  const [tagVals3, setTagVals3] = useState([]);
  const [tagCheckbox, setTagCheckbox] = useState([]); // checkbox 数据
  const [tagCheck3, setTagCheck3] = useState([]); // 区间checkbox
  const [option, setOption] = useState(LIMIT_RANGE_OPTIONS.rangeCategory); // 涨停分类折线图
  const [option2, setOption2] = useState(LIMIT_RANGE_OPTIONS.rangeSum); // 涨停累计柱状图
  const [option3, setOption3] = useState(LIMIT_RANGE_OPTIONS.rangeSumDay); // 涨停区间折线图

  const columns = _.concat(
    {
      ...createSingleColumns(['序号', 'num']),
      render: (text, record) => <Space size='small'>
        <Text strong>{text}</Text>
        <Button icon={<MinusCircleOutlined />} size='small' type='link'
          onClick={() => props.onDelRow(record.key)}
        />
        <Button style={{ fontSize: 13, marginLeft: -6 }} size='small' type='link' onClick={() => {
          // 选项中包含序号的，可以直接修改checkbox，实现该组全选
          onTagChange(
            _.chain(tagCheckbox).filter(o => _.includes(o.key, text)).map(n => n.key).value()
          )
        }}>全选</Button>
      </Space>
    },
    { ...createSingleColumns(['全称', 'rname', 125]), },
    STATISTIC_ARRAY.map(n => createSingleColumns([n.name, n.key]))
  );

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart; let myChart3 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('limit_range_sum')); // 图3 涨停分类折线图
    myChart2 = echarts.init(document.getElementById('limit_category')); // 图2 涨停累计柱状图
    myChart3 = echarts.init(document.getElementById('limit_sum_day'));  // 图1 涨停区间折线图
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    const getSearchNameObj = _.get(props, 'searchName', {});
    const defaultProduct = ['product', '涨停票', '全市场涨停票', '推送总数'];
    let newOption = _.cloneDeep(option); let newSeries = []; let times = []; let legend = [];
    let newOption2 = _.cloneDeep(option2); let productArr = [];
    let newOption3 = _.cloneDeep(option3); let series3 = []; let numberList = [];
    let tables = [];
    let groups = []; let checkArray = [];
    const getAlls = _.get(props, 'allInfo', {}); //组数据： {参数字段keyname：渲染完成的数据组object}
    // console.log('getAlls', getAlls)
    if (_.size(getAlls) > 0) {
      let nindex = 0; // 有多少个数据
      Object.keys(getAlls).map((keyname, kidx) => { // 每组创建都需要创建 KEYS_ARRAY 内的6个线条数据
        const getDatas = _.get(getAlls[keyname], 'dataArray', []); // 计算完成的数据
        const getCals = _.get(getAlls[keyname], 'cals', {}); // 统计数据
        times = _.get(getAlls[keyname], 'date', []); // 天数直接赋值
        const number = kidx + 1; // 序号
        // 每组数据的统计，对应字段直接赋值，用 Bar 图表展示 ; 增加单日统计数据，区间折线图
        let limits = 0; let limitTotal = 0; let bases = 0;
        let day_obj = { 'limits_arr': [], 'limitTotal_arr': [], 'bases_arr': [] };
        getDatas.map((n, i) => {
          limits += _.get(n, 'numLimit', 0);
          limitTotal += _.get(n, 'numLimitTotal', 0);
          bases += _.get(n, 'numBase', 0);
          day_obj['limits_arr'][i] = _.get(n, 'numLimit', 0);
          day_obj['limitTotal_arr'][i] = _.get(n, 'numLimitTotal', 0);
          day_obj['bases_arr'][i] = _.get(n, 'numBase', 0);
        });
        // 单日统计
        DEFAULT_SUM.map(s => {
          let baseItem = createBaseLine(null, { 'name': number + s.label, 'keyname': number + s.value });
          _.set(baseItem, 'data', day_obj[s.value + '_arr']);
          series3.push(baseItem);
        })
        numberList.push(createLabelValue(['序号' + number, String(number)]));
        productArr[kidx] = [number, limits, limitTotal, bases];
        // 表格数据 ；每组数据的统计
        let renderName = '';
        const splitKeyname = _.split(keyname, '_'); // 拆分组keyname，拿到3个参数值
        renderName = _.join(  // 渲染名称 ；通过分别的 {值：名称} 数据的结果 最后拼接
          [
            VALUE_TYPE[splitKeyname[0]], // 推送名称，用值找到名称
            getSearchNameObj[splitKeyname[1]], // 分类，用值找到名称
            commonIndexValueType[splitKeyname[2]],  // indexCode，用值找到名称
            splitKeyname[3],  // num 
          ], '_')
        let tableItems = { 'rname': renderName, 'key': keyname, 'num': number };
        STATISTIC_ARRAY.map(s => {
          const getVal = _.get(getCals, s.key, 0);
          tableItems[s.key] = _.round(getVal, 2);
        });
        tables.push(tableItems);
        groups.push({ 'key': keyname, 'rname': renderName });
        // line 图表数据渲染
        if (isValidArray(getDatas)) {
          KEYS_ARRAY.map(k => {
            //拼接key和名称，区分盘中字段；  例: num+Acc+Trading ，该字段是上层处理数据时创建的字段
            const gkey = 'isTrading' in k ? k.key + segVal + 'Trading' : k.key + segVal;
            const gname = 'isTrading' in k ? k.name + '(盘中)' : k.name;
            if (!newSeries[nindex]) { // 对应数据为空先创建series空对象
              newSeries[nindex] = {
                type: 'line',
                symbol: 'circle',
                data: [],
                name: '',
              }
            }
            // segVal = 分组数据，每组6个数据 ；每次渲染一组数据
            const fullName = number + OPTIONS[segVal] + gname;
            newSeries[nindex].name = fullName;
            legend[nindex] = fullName;
            checkArray[nindex] = { 'key': fullName, 'calKey': k.calKey }; // checkbox 增加calKey区分分类
            // 创建ckey字段计算使用 组number_当前calKey值 ；计算 波幅是使用
            newSeries[nindex].ckey = number + '_' + k.calKey;

            getDatas.map((item, i) => {
              // 字段拼接，i= data数据的长度，nindex代表series的个数 
              newSeries[nindex].data[i] = item[gkey];
            });
            // KEY_ARRAY遍历次数代表serires的个数，处理完进行自加 ； nindex = KEYS_ARRAY数据长度 = 6
            nindex++;
          })
        }
      })
    };
    setTagCheckbox(checkArray); // checkbox 数据
    setCurrentSeries(newSeries); // 赋值全局serires，点击check可在更新中直接使用
    newOption.xAxis.data = times;
    newOption3.xAxis.data = times;
    setTagCheck3(_.concat(numberList, DEFAULT_SUM));
    // newOption.legend.data = legend;
    newOption.series = []; // 默认置空数据
    newOption2.dataset.source = _.concat([defaultProduct], productArr);
    newOption3.series = series3;
    setCalDatas(tables);
    // console.log('newOption', newOption)
    setOption(newOption);
    setOption2(newOption2);
    setOption3(newOption3);
    myChart.setOption(newOption, true);
    myChart2.setOption(newOption2, true);
    myChart3.setOption(newOption3, true);
    myChart.hideLoading();
    myChart.resize();
    myChart2.resize();
    myChart3.resize();
  }, [props.updateCount, segVal]);
  // checkbox控制；更新series数据
  useUpdateEffect(() => {
    let myChart = props.myChart;
    myChart = echarts.init(document.getElementById('limit_range_sum'));

    let newOption = _.cloneDeep(option);
    // 选择后更新series，根据name进行筛选
    let extraRate = [];
    const filterData = _.filter(currentSeries, o => _.includes(tagVals, o.name));
    // 用筛选数据进行波幅计算； 用昨日或者前日除以今日数据得到结果
    filterData.map(s => {
      const splitKey = _.split(s.ckey, '_'); // 拆分自定义字段 ckey
      const groupNum = splitKey[0]; const cal_key = splitKey[1]; // 组名；和 该数据的计算calKey值
      let mainIndex = -1;
      // 寻找主键 A 或者 E，是波幅的除数； = 今日或今日盘中数据
      if (_.includes(CAL_GROUP1, cal_key)) { // 两个组别分布寻找，找到后通过拼接主键名称找到主键index
        mainIndex = _.findIndex(filterData, o => o.ckey === `${groupNum}_${CAL_MAIN1}`);
      } else if (_.includes(CAL_GROUP2, cal_key)) {
        mainIndex = _.findIndex(filterData, o => o.ckey === `${groupNum}_${CAL_MAIN2}`);
      }
      if (mainIndex !== -1) { // 没有主键(今的数据)无需计算
        let newSeriesItem = {
          name: groupNum + CAL_KEY_OBJ[cal_key],
          symbol: 'circle',
          yAxisIndex: 1,
          lineStyle: {
            color: CAL_COLOR_OBJ[cal_key],
            type: 'dashed',
          },
          itemStyle: {
            color: CAL_COLOR_OBJ[cal_key],
            opacity: 0.9
          },
          type: 'line',
          data: arraysDiv(s.data, filterData[mainIndex].data) // = 昨/前 的数据 除以(/) 今的数据
        }
        // 符合条件进行运算： 创建新的series数据
        extraRate.push(newSeriesItem);
      }
    })
    newOption.series = _.concat(filterData, extraRate);
    // console.log(newOption)
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [tagUpdate]);
  // 区间分类更新，通过series name字段进行筛选
  useUpdateEffect(() => {
    let myChart = props.myChart;
    myChart = echarts.init(document.getElementById('limit_sum_day'));
    let newOption3 = _.cloneDeep(option3);
    newOption3.series = _.filter(_.get(newOption3, 'series', []), o => _.includes(o.keyname, tagVals3));
    myChart.setOption(newOption3, true);
    myChart.resize();
  }, [tagUpdate3]);

  // checkbox.group 修改选择项
  const onTagChange = (checkedValues) => {
    setTagVals(checkedValues);
    setTagUpdate(_.round(tagUpdate + 0.1, 1));
  };

  const onTagChange3 = (v) => {
    setTagVals3(v);
    setTagUpdate3(_.round(tagUpdate3 + 0.1, 1));
  };

  const radioButton = Object.keys(OPTIONS).map(keyname => <Radio.Button key={keyname} value={keyname}>{OPTIONS[keyname]}</Radio.Button>);
  const chart12_style = { width: '98%', height: 250 };
  return (
    <>
      <div style={{ minHeight: 190 }}>
        <Table
          rowKey={'key'}
          dataSource={calDatas}
          columns={columns}
          size='small'
          loading={_.get(props, 'loading', false)}
          bordered={true}
          scroll={{ y: 150 }}
          pagination={false}
        />
      </div>

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ marginTop: 12, textAlign: 'center' }}>
          {isValidArray(tagCheck3) && tagCheck3.map((t, i) => <Tag key={'tag33' + i} color='blue' style={{ marginRight: 2 }}>
            <a onClick={() => onTagChange3(t.value)}>{t.label}</a>
          </Tag>)}
        </div>
        <div style={{ display: 'flex' }}>
          <div
            id="limit_sum_day" //  图1
            style={chart12_style}
          />
        </div>
        <div style={{ display: 'flex', marginTop: 12 }}>
          <div
            id="limit_category" //  图2
            style={chart12_style}
          />
        </div>
      </Spin>

      <Row style={{ marginBottom: 20, marginTop: 2 }} align='middle'>
        <Col {...autoCol([8])} style={{ textAlign: 'center' }}>
          <Radio.Group
            buttonStyle="solid"
            value={segVal}
            size='small'
            onChange={(e) => {
              setSegVal(e.target.value);
              setTagVals([]); // 切换类别置空checkbox选择
            }}
          >
            {radioButton}
          </Radio.Group>
        </Col>
        <Col {...autoCol([12])}>
          {TAGS_ARRAY.map((t, i) => <Tag key={'tag' + i} style={{ marginRight: 2 }}>
            <a onClick={() => {
              // 分组显示,按照calKey去找到所有分组数据，并赋值
              onTagChange(
                _.chain(tagCheckbox).filter(o => o.calKey === t.value).map(n => n.key).value()
              )
            }}>{t.label}</a>
          </Tag>)}
        </Col>
      </Row>

      <div className='limitControlBar'>
        <Checkbox.Group
          style={{ width: '100%' }}
          value={tagVals}
          onChange={onTagChange}
        >
          <Row gutter={[8, 8]}>
            {tagCheckbox.map((item, i) => {
              const getKey = _.get(item, 'key', '')
              return <Col span={6} key={i}>
                <Checkbox value={getKey}>
                  <Text style={{ fontSize: 13 }}>{getKey}</Text>
                </Checkbox>
              </Col>
            })}
          </Row>
        </Checkbox.Group>
      </div>

      <div style={{ display: 'flex' }}>
        <div
          id="limit_range_sum" //  图3
          style={{
            width: '98%',
            height: 300
          }}
        />
      </div>
    </>
  )
}