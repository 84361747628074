import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Typography } from 'antd';
import { autoCol, addNumberComma } from '@/utils/utils';
import { simpleColumns } from '@/utils/utils2';
import { NewRangePicker } from '@/view/common/widgets';
import _ from 'lodash';

const PAGE_SIZE = 50;
/**
 * 单日最大成交量表格
 */
const HistoryTable = ({ datas, updateCount, todayFormat, loading, onTimeChange }) => {
  const [page, setPage] = useState(1);
  const [volDate, setVolDate] = useState(todayFormat);
  const columns = [
    {
      ...simpleColumns(['INDEX', 'index', 100]),
      render: (text, record, index) => (page - 1) * PAGE_SIZE + index + 1,
    },
    simpleColumns(['股票代码', 'symbol', 110]),
    {
      ...simpleColumns(['成交量(手)', 'historyMaxVolume']),
      align: 'left',
      sorter: (a, b) => a.historyMaxVolume - b.historyMaxVolume,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <Typography.Text strong>{addNumberComma(text)}</Typography.Text>
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [updateCount])

  return <>
    <Row style={{ marginBottom: 12, marginTop: 6 }}>
      <Col {...autoCol([6])}>
        <NewRangePicker
          isSingle
          value={volDate}
          size='small'
          onSelect={(dateStrings) => {
            setVolDate(dateStrings);
            onTimeChange(dateStrings);
          }}
        />
      </Col>
    </Row>

    <Table
      rowKey={'id'}
      loading={loading}
      dataSource={datas}
      columns={columns}
      size='small'
      scroll={{ x: 620, y: 650 }}
      pagination={{
        pageSize: PAGE_SIZE,
        simple: true,
        onChange: e => { setPage(e) }
      }}
    />

  </>
}

export default HistoryTable;