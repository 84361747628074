import React from 'react';
import { Typography, List, Space, Divider, Badge, Tag } from 'antd';
import { RateText } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;
const REPORT_TIME_KEYS = ['r_time', 'rs_time'];
// 推送列表
const ExtraList = ({
  pageType = '',
  listData = [],
  sortkey = '',
  loading = false,
  colorObj = {},
  current = '',

  onStockClick,
}) => {
  const is_report_content = _.includes(REPORT_TIME_KEYS, sortkey);
  const is_timeindex_page = pageType === 'timeindex' ? true : false;
  return <List
    itemLayout="horizontal"
    dataSource={listData}
    loading={loading}
    header={null}
    renderItem={item => {
      const renderTime = _.get(item, 'format_time', '');
      const is_report = _.get(item, 'isReport', false);
      const is_research = _.get(item, 'isResearch', false);
      const is_equal = current === String(_.get(item, 'symbol')) ? true : false;
      return <List.Item>
        <List.Item.Meta
          title={<div style={{ cursor: is_timeindex_page ? 'pointer' : 'default' }}
            onClick={() => {
              if (is_timeindex_page) {
                onStockClick(_.get(item, 'symbol'), _.get(item, 'name'));
              }
            }}>
            <Space>
              <Text style={{ color: is_timeindex_page && is_equal ? '#096dd9' : '#232323' }}>
                {`${_.get(item, 'idx', '')} ${_.get(item, 'name', '')}    ${_.get(item, 'symbol', '')}`}
              </Text>
              {_.get(item, 'new') ? <Badge status="error" /> : null}
            </Space>
          </div>}
          description={<Space size='small'>
            <Text type='secondary'>{`${item.market || ''} - ${item.industry || ''} - ${item.plate || ''}`}</Text>
            {is_report && <Tag color={colorObj['report']}>
              {_.get(item, 'report.reportType')}
            </Tag>}
            {is_research && <Tag color={colorObj['research']}>
              {_.get(item, 'researchReport.ratingName')}
            </Tag>}
          </Space>}
        />
        {is_report_content ? <Space size='small'>
          {is_report && sortkey === 'r_time' && <Text type='secondary'>报告：{_.get(item, 'r_time', '')}</Text>}
          {is_research && sortkey === 'rs_time' && <Text type='secondary'>研报：{_.get(item, 'rs_time', '')}</Text>}
        </Space> : <div>
          <RateText rate={_.get(item, 'rate', 0)} />
          <Divider type='vertical' />
          <Text>{_.get(item, 'price', '')}</Text>
          <Divider type='vertical' />
          <RateText rate={_.get(item, 'costRate', 0)} />
          <Divider type='vertical' />
          <Text type='secondary'>{renderTime}</Text>
        </div>}
      </List.Item>
    }}
  />
}

export default ExtraList;