import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Progress, Select, Space, Switch, Button, Divider, Checkbox, Segmented } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getStockChgStatistics } from '@/api/stock';
import { autoCol, validateResponse } from '@/utils/utils';
import { isValidArray, isValidObj, isValidString, labelValues } from '@/utils/utils2';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { base_options } from '@/utils/indexCodes';
import { NewRangePicker } from '@/view/common/widgets';
import {
  UPDOWN_OPTION, UPDOWN_COLOR, INTERVAL_KEYS, SPECIAL_ITEMS, UPDOWN_TEXT,
  arraySum,
} from '../Components/sutil';
import { useReactive, useUnmount } from 'ahooks';
import moment from 'moment';
import _ from 'lodash';
import * as echarts from 'echarts';

const { Text } = Typography;
const RANGE_OPTIONS = [labelValues(['周度', 'weeks']), labelValues(['月度', 'months']), labelValues(['季度', 'season'])];
const ORDER_KEYS = _.orderBy(_.concat(INTERVAL_KEYS, SPECIAL_ITEMS), ['order'], ['asc']);
const findIndexName = (symbol) => {
  let fidx = _.findIndex(base_options, o => o.value === symbol);
  return fidx !== -1 ? base_options[fidx].label : '';
};
let timer = null;
/**
 * 涨跌概况图表; 区间查看涨跌曲线；柱状图显示单日数据（区间外的数据重新获取接口）
 */
export default function UpDownCharts(props) {
  const pmsState = useReactive({
    range: _.get(props, 'params.s_range', []), rtype: 'weeks',
    date: _.get(props, 'params.s_date', ''), rcheck: '',
    rateObj: {}, isSingleGet: false, // =true 的时候代表单日数据为获取数据，=false则可以从区间数据中找到单日数据
    idx: _.get(props, 'idxParams.symbol', ''), idxName: findIndexName(_.get(props, 'idxParams.symbol')),
    update: 0, isSelectAll: false, isOpen: false,
  });
  const [option, setoption] = useState(UPDOWN_OPTION.range);
  const [option2, setoption2] = useState({
    ...UPDOWN_OPTION.bar,
    tooltip: {
      trigger: 'item',
      formatter: renderFormatter
    },
  });
  const [upcount, setUpcount] = useState(0);
  const [upcount2, setUpcount2] = useState(0);
  const [validSingleDate, setValidSingleDate] = useState([]);
  const [singelData, setSingleData] = useState({});
  const [seriseData, setSeriseData] = useState([]);
  const [checkItems, setCheckItems] = useState([]);
  const [checks, setChecks] = useState([]);

  function renderFormatter(params) {
    const getType = _.get(params, 'componentType', '');
    let finalString = '';
    if (getType === 'markPoint') {
      finalString = params.marker + `<span style='font-weight:600;margin-left:12px'>${params.value}</span>`
    } else {
      finalString = params.marker + ' ' + params.name
        + `<span style='font-weight:600;margin-left:12px'>${params.value}</span>`
        + '<br/>'
    }

    return finalString;
  };

  useUnmount(() => {
    timer && clearTimeout(timer);
  });
  // 获取单日涨停数据
  async function _getStockChgStatistics() {
    const res = await getStockChgStatistics({
      tradeDate: pmsState.date + ' 00:00:00',
    });
    if (validateResponse(res)) {
      const getData = _.get(res, 'data', {});
      setSingleData({
        ...getData,
        formatDate: moment(_.get(getData, 'tradeDate', '')).format('YYYY-MM-DD')
      });
      pmsState.isSingleGet = true;
      setUpcount(upcount + 1);
    }
  }
  // range区间数据更新
  useEffect(() => {
    let myChart = props.myChart;
    let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('updown_charts_line'));
    myChart2 = echarts.init(document.getElementById('updown_charts_bar'));

    pmsState.isSingleGet = false; // 区间effect更新将直接重置获取单日数据的状态

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    const getDatas = _.get(props, 'datas', []);
    const getIndex = _.get(props, 'indexs', {});
    let rangeObj = {}; let times = []; let sData = []; let legend = [];

    if (isValidArray(getDatas)) {
      // 创建每个组数据，并根据index赋值之后的数据
      getDatas.map((n, i) => {
        times.push(_.get(n, 'formatDate'));
        INTERVAL_KEYS.map(k => {
          const getKey = pmsState.isOpen ? k.key + 'Open' : k.key;
          if (getKey in rangeObj) {
            rangeObj[getKey][i] = _.get(n, getKey);
          } else {
            _.set(rangeObj, getKey, [_.get(n, getKey)]);
          }
        })
      });
      // 每组数据创建series
      ORDER_KEYS.map(k => {
        let seriesItem = createBaseLine(
          _.get(UPDOWN_COLOR, k.colorPath),
          { 'name': k.name, 'keys': k.key, 'order': k.order, 'yAxisIndex': 0 }
        );
        if ('sumKey' in k) { //特殊字段，统计汇总数据
          let sumArr = [];
          k.sumKey.map(keys => sumArr = arraySum(sumArr, _.get(rangeObj, pmsState.isOpen ? keys + 'Open' : keys, [])));
          seriesItem.data = sumArr;
        } else {
          seriesItem.data = _.get(rangeObj, pmsState.isOpen ? k.key + 'Open' : k.key);
        }
        sData.push(seriesItem);
        legend.push(_.assign(k, labelValues([k.name, k.key])));
      });

      if (isValidObj(getIndex)) {
        const diffTimes = handleDiffTimes(times, _.get(getIndex, 'timeList', []));
        let newValues = [];
        if (_.get(diffTimes, 'isDiff')) { // 如果有差异，用valueIndex数组，到pointList里面提取符合值
          newValues = _.get(diffTimes, 'valueIndex').map(v => _.get(getIndex, `pointList[${v}]`, '-'))
        } else {
          newValues = _.get(getIndex, 'pointList', []);
        }
        let indexItem = createBaseLine('#B5495B', { 'name': pmsState.idxName, 'keys': 'index', 'yAxisIndex': 1 });
        _.set(indexItem, 'lineStyle.type', 'dashed');
        _.set(indexItem, 'data', newValues);
        sData[_.size(ORDER_KEYS)] = indexItem; // 默认最后位是指数数据
        //y轴浮动
        const yFloat = calMaxMin(newValues);
        newOption.yAxis[1].max = yFloat.newMax;
        newOption.yAxis[1].min = yFloat.newMin;
      }
      newOption.series = sData;
      newOption.xAxis.data = times;
      setValidSingleDate(times);
      pmsState.date = _.last(times); // 默认用最新的日作为单日数据
      setCheckItems(legend);
      // 单日
      const xaxis2 = _.filter(legend, o => !_.includes(['正', '负'], o.name));// 单日取消特殊字段
      newOption2.yAxis.data = xaxis2.map(a => a.name);
      newOption2.series[0].data = handleSingleData();
    }
    setSeriseData(sData);
    setoption(newOption);
    setoption2(newOption2);
    let initSeries = [_.last(sData)];
    if (isValidArray(checks)) {
      initSeries = _.concat(
        [_.last(sData)],
        _.filter(sData, o => _.includes(checks, o.keys)),
      );
      timer = setTimeout(() => {
        updateRangeChart();
      }, 500);
    };
    newOption.series = pmsState.isSelectAll ? sData : initSeries;
    myChart.setOption(newOption, true);
    myChart.resize();
    myChart2.setOption(newOption2, true);
    myChart2.resize();
  }, [props.updateCount, pmsState.update]);
  // 选择切换,计算周期bar图
  useEffect(() => {
    let myChart = props.myChart; let myChart3 = props.myChart;
    myChart = echarts.init(document.getElementById('updown_charts_line'));
    myChart3 = echarts.init(document.getElementById('updown_range'));
    const getTradeDate = _.get(props, 'tDates', []);
    let newOption = _.cloneDeep(option);
    let newOption3 = _.cloneDeep(UPDOWN_OPTION.rangeBar);
    // console.log('seriseData', seriseData)
    const checkSerise = _.filter(seriseData, o => _.includes(checks, o.keys));
    newOption.series = _.concat(
      _.filter(seriseData, o => o.keys === 'index'),
      checkSerise
    );
    // 周期区间计算，同mainchart周期统计图表相同计算逻辑
    const getx = _.get(option, 'xAxis.data', []);
    if (isValidArray(getx) && isValidString(pmsState.rcheck)) {
      let findex = _.findIndex(ORDER_KEYS, o => o.key === pmsState.rcheck);
      let sindex = _.findIndex(seriseData, o => o.keys === pmsState.rcheck);
      let months = {}; let weeks = {}; let season = {};
      getx.map((date, ix) => {
        const curVal = _.get(seriseData, `[${sindex}].data[${ix}]`, 0);
        const spl_month = _.split(date, '-')
        const mval = spl_month[0] + '-' + spl_month[1];
        if (mval in months) {
          months[mval].push(curVal);
        } else {
          _.set(months, mval, [curVal]);
        }
        const find_date = _.findIndex(getTradeDate, o => o.date === date);
        // 周统计；week字段为全局时间处理，提前划分周信息
        const years = moment(date).year();
        const shortYear = String(years).substring(2);
        const wval = _.get(getTradeDate, `[${find_date}].week`, 0);
        // 每年第1周增加年份显示
        const wvalString = `${shortYear}-${wval}周(${_.get(getTradeDate, `[${find_date}].start`, 0)})`
        if (wvalString in weeks) {
          weeks[wvalString].push(curVal);
        } else {
          _.set(weeks, wvalString, [curVal]);
        }
        // 季度统计
        const sval = _.get(getTradeDate, `[${find_date}].season`, 0);
        const svalString = `${shortYear}-${sval}季(${_.get(getTradeDate, `[${find_date}].seaStart`, 0)})`;
        if (svalString in season) {
          season[svalString].push(curVal);
        } else {
          _.set(season, svalString, [curVal]);
        }
      });
      const finalRange = { weeks, months, season };
      const curRange = finalRange[pmsState.rtype];
      let rdata = []; let rtime = [];
      // 周期求平均
      _.keys(curRange).map(rname => {
        const val = _.round(_.sum(curRange[rname]) / _.size(curRange[rname]), 2);
        rdata.push({
          'value': val,
          'itemStyle': { color: _.get(UPDOWN_COLOR, ORDER_KEYS[findex].colorPath) }
        });
        rtime.push(rname);
      });
      newOption3.series[0].data = rdata;
      newOption3.xAxis.data = rtime;
      newOption3.series[0].label.show = _.size(rdata) > 25 ? false : true;
    };
    myChart.setOption(newOption, true);
    myChart.resize();
    myChart3.setOption(newOption3, true);
    myChart3.resize();
  }, [upcount2]);
  // 单日数据更新
  useEffect(() => {
    let myChart2 = props.myChart;
    myChart2 = echarts.init(document.getElementById('updown_charts_bar'));
    let newOption2 = _.cloneDeep(option2);

    newOption2.series[0].data = handleSingleData();

    setoption2(newOption2);
    myChart2.setOption(newOption2, true);
    myChart2.resize();
  }, [upcount]);
  // 单日涨跌幅计算
  function handleSingleData() {
    const getDatas = pmsState.isSingleGet ? [singelData] : _.get(props, 'datas', []); // 统一数据格式
    let single = [];
    let findex = pmsState.isSingleGet ? 0 : _.findIndex(getDatas, o => o.formatDate === pmsState.date); // isSingleGet=true，默认第0位，保持findex的逻辑通用
    if (findex !== -1) {
      let rateSum = 0; let rateUpSum = 0; let rateDownSum = 0;
      let rateFlat = _.get(getDatas[findex], 'flatPlate');
      ORDER_KEYS.map(k => {
        // 统计涨跌个数
        const itemVal = _.get(getDatas[findex], pmsState.isOpen ? k.key + 'Open' : k.key, 0);
        let singleItem = { 'name': k.name, 'value': itemVal, 'itemStyle': { color: _.get(UPDOWN_COLOR, k.colorPath) } };
        if (k.order === 2 || k.order === 10) { // 特殊字段计算
          singleItem.value = _.get(getDatas[findex], k.sumKey[0], 0) + _.get(getDatas[findex], k.sumKey[1], 0);
        } else { // 普通计算累计涨跌幅比例数据
          if (_.includes(k.colorPath, 'up') || _.includes(k.colorPath, 'redLevel')) {
            rateUpSum = rateUpSum + itemVal;
          } else if (_.includes(k.colorPath, 'down') || _.includes(k.colorPath, 'greenLevel')) {
            rateDownSum = rateDownSum + itemVal;
          }
          rateSum = rateSum + itemVal;
        }
        // 创建bar数据
        if (!_.includes([12, 13], k.order)) {
          single.push(singleItem);
        }
      });
      // 计算单日涨跌的比例数据
      let cal = {
        'up': rateUpSum, 'down': rateDownSum, 'flat': rateFlat,
        'up_rate': _.round((rateUpSum / rateSum) * 100),
        'down_rate': _.round((rateDownSum / rateSum) * 100),
        'flat_rate': _.round((rateFlat / rateSum) * 100),
      }
      pmsState.rateObj = cal;
    }
    return single;
  }
  // 对比时间是否有差异
  function handleDiffTimes(time1, time2) {
    let indexArray = []; let finalTime = []; let isDiff = false;
    if (isValidArray(time1) && isValidArray(time2)) {
      time1.map((t, i) => {
        finalTime[i] = t;
        if (t === time2[i]) { // 两个时间相等，记录对应i的值
          indexArray[i] = i;
        } else { // 否则查找time2里面包含该时间，包含记录对应indexOf值，否这置为 -
          let t2index = time2.indexOf(t);
          if (t2index !== -1) {
            indexArray[i] = t2index;
          } else {
            finalTime[i] = '-'
          }
          isDiff = true;
        }
      })
    }
    return {
      'valueIndex': indexArray, // time1数据为基准，time2对应的数据index
      'timeList': finalTime, // 统一输出一个时间
      'isDiff': isDiff
    }
  }
  // 指数y轴设置最大最小值 + 1%的浮动空间
  function calMaxMin(points) {
    let max = _.max(points); let min = _.min(points);
    let avg = _.round((max + min) / 2, 4);
    let onePercent = _.round(avg / 100, 4);
    return { 'newMax': _.round(max + onePercent), 'newMin': _.round(min - onePercent) };
  };
  // checkbox.group 修改选择项
  const onSubValuesChange = (checkedValues) => {
    pmsState.rcheck = isValidArray(checkedValues) ? _.last(checkedValues) : '';
    setChecks(checkedValues);
    updateRangeChart();
  };

  const updateRangeChart = () => setUpcount2(_.round(upcount2 + 0.1, 1));
  // 切换右侧单日
  function onSwitchSingleDate(type) {
    const curIndex = validSingleDate.indexOf(pmsState.date);
    let isValid = type === 'picker' ? true : false;
    if (curIndex > 0 && type === 'last') {
      pmsState.date = validSingleDate[curIndex - 1];
      isValid = true;
    };
    if (curIndex < _.size(validSingleDate) - 1 && type === 'next') {
      pmsState.date = validSingleDate[curIndex + 1];
      isValid = true;
    };
    if (isValid) {
      setUpcount(upcount + 1);
      pmsState.isSingleGet = false;
      props.onRangeChange('s_date', pmsState.date);
    };
  }

  const select_props = { value: pmsState.idx, style: { width: 125 }, size: 'small', options: base_options };
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 8, marginTop: 8, paddingLeft: 8, paddingRight: 8 };
  const btn_props = { 'size': 'small', 'type': 'text' }
  return (
    <Row gutter={[8, 8]}>
      <Col {...autoCol([16])} className='limitDivder'>
        <Space>
          <NewRangePicker
            dates={pmsState.range}
            size='small'
            onSelect={(dateStrings) => {
              pmsState.range = dateStrings;
              props.onRangeChange('s_range', dateStrings);
            }}
          />
          <Select {...select_props} onChange={(v) => {
            pmsState.idx = v;
            pmsState.idxName = findIndexName(v);
            props.onIndexChange(v);
          }}
          />
          <Button size='small' type={pmsState.isOpen ? 'primary' : 'default'}
            onClick={() => {
              pmsState.isOpen = !pmsState.isOpen;
              pmsState.update++;
            }}
          >
            开盘
          </Button>
          <Divider type='vertical' />
          <Switch
            checkedChildren="全选"
            unCheckedChildren="全选"
            checked={pmsState.isSelectAll}
            onChange={(checked) => {
              pmsState.isSelectAll = checked;
              pmsState.update++;
            }}
          />
        </Space>

        <div style={controlBarStyle}>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={checks}
            onChange={onSubValuesChange}
          >
            <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
              {isValidArray(checkItems) && checkItems.map((itm, i) => {
                return <Col span={3} key={i}>
                  <Checkbox value={_.get(itm, 'key', '')}>
                    <Text style={{ color: _.get(UPDOWN_COLOR, itm.colorPath) }}>{_.get(itm, 'name')}</Text>
                  </Checkbox>
                </Col>
              })}
            </Row>
          </Checkbox.Group>
        </div>

        <div style={{ display: 'flex', marginTop: 24 }}>
          <div
            id="updown_charts_line"
            style={{ width: '100%', height: 430 }}
          />
        </div>

        <Row style={{ marginBottom: 8 }}>
          <Col span={12}>
            <Segmented
              size='small'
              options={RANGE_OPTIONS}
              value={pmsState.rtype}
              onChange={v => {
                pmsState.rtype = v;
                updateRangeChart();
              }}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Select value={pmsState.rcheck}
              style={{ width: 95 }} size='small'
              options={checkItems} bordered={false}
              onChange={(v) => {
                pmsState.rcheck = v;
                updateRangeChart();
              }}
            />
          </Col>
        </Row>

        <div style={{ display: 'flex' }}>
          <div
            id="updown_range"
            style={{ width: '100%', height: 285 }}
          />
        </div>

      </Col>
      <Col {...autoCol([8])}>
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button
              {...btn_props}
              icon={<LeftOutlined />}
              onClick={() => onSwitchSingleDate('last')}
            />
            <NewRangePicker
              isSingle
              value={pmsState.date}
              size='small'
              singleValidDate={validSingleDate}
              onSelect={(dateStrings) => {
                pmsState.date = dateStrings;
                if (validSingleDate.indexOf(dateStrings) === -1) {
                  _getStockChgStatistics();
                } else {
                  onSwitchSingleDate('picker');
                }
              }}
            />
            <Button
              {...btn_props}
              icon={<RightOutlined />}
              onClick={() => onSwitchSingleDate('next')}
            />
          </Space>
        </div>

        <div style={{ display: 'flex', marginTop: 24 }}>
          <div
            id="updown_charts_bar"
            style={{ width: '100%', height: 650 }}
          />
        </div>

        <div style={{ paddingLeft: 36, paddingRight: 36 }}>
          <Progress
            percent={_.get(pmsState.rateObj, 'flat_rate', 100) + _.get(pmsState.rateObj, 'up_rate', 0)}
            showInfo={false}
            strokeColor={UPDOWN_COLOR['flat']}
            success={{ percent: _.get(pmsState.rateObj, 'up_rate', 0), strokeColor: UPDOWN_COLOR['up'] }}
            trailColor={UPDOWN_COLOR['down']}
          />
          <Row>
            {UPDOWN_TEXT.map(n => <Col key={n.key} span={8} style={{ textAlign: n.align }}>
              <Text style={{ color: UPDOWN_COLOR[n.key] }}>{n.name + ': '}</Text>
              <Text strong style={{ color: UPDOWN_COLOR[n.key] }}>{_.get(pmsState.rateObj, n.key, '')}</Text>
            </Col>)}
          </Row>
        </div>
      </Col>
    </Row>
  )
}