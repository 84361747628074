import React, { useState } from 'react';
import {
  Row, Col, Typography, Button, Select, Space, TimePicker, Divider, Tooltip,
  Popover, Slider, Input, Dropdown, Menu, InputNumber, Segmented, Spin
} from 'antd';
import {
  ArrowDownOutlined, ArrowUpOutlined, ControlOutlined, SearchOutlined, ExceptionOutlined, TableOutlined,
  LineChartOutlined, SlidersFilled
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { COMMON_INDEX_2 } from '@/utils/indexCodes';
import { autoCol, RateText, SyncButton } from '@/utils/utils';
import { typeValueOptions } from '@/utils/utils2';
import { useReactive, useUpdateEffect } from 'ahooks';
import { NewRangePicker } from '@/view/common/widgets';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;
const numberOrNull = (n) => _.isNumber(n) ? String(n) : null;
const SecText = ({ strong = false, txt = '' }) => {
  let text_props = {};
  if (strong) {
    _.set(text_props, 'strong', true);
  } else {
    _.set(text_props, 'type', 'secondary');
  }
  return <Text {...text_props}>{txt}</Text>
};
const btn_link_props = { type: 'link', size: 'small' };
const btn_text_props = { type: 'text', shape: 'circle' };
const btn_primary_props = { type: 'primary', size: 'small' };
/**
 *  推送板块拆分出来的顶部控制栏组件；
 *  条件筛选及控制功能，统一在该文件下，控制状态（例如时间\滑动条组件..)进行本地状态管理，获取或全局状态有上次状态统一管理 
 */
const ExtraBar = ({
  defaultPms = {}, // 初始默认params参数【常量】
  todayFormat = '', // 今日时间值【常量】
  weekFormat = [],  // 周区间时间值【常量】
  fKeys = [],  // 筛选字段 【常量】
  reportArray = [], // 报告类型 【常量】
  typeOptions = [], // 类型切换option 【常量】
  exParams = {},  // 当前的params参数
  rateObj = {},
  resetCount = 0,
  segCount = 0, // 板块切换，更新segemnt的状态
  sortKeyname = '',
  searchBool = false, // 是否搜索
  isAuto = false,
  loading = false,
  isTrading = false,

  onPmsChange,
  onSync,
  onConfirm,
  onSortChange,
  onAutoChange,
  goPushPage,
  goPlatePage,
  goTimeIndexPage
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [signalType, setSignalType] = useState(_.get(defaultPms, 'type'));
  const chartState = useReactive({
    idRate: { value: _.get(exParams, 'indexCode', '') },
    r_color: null,
    r_key: 'cancel',
    type: typeOptions[0], barDate: todayFormat
  });
  const sliderState = useReactive({ rate: [], rateCli: [], rateTime: [], num: 0 });
  const [searchTxt, setSearchTxt] = useState('');
  const [seaDate, setSeaDate] = useState([todayFormat, todayFormat]);
  const [chartDate, setChartDate] = useState(weekFormat);
  const [exDate, setExDate] = useState(todayFormat);

  useUpdateEffect(() => {
    sliderState.rate = [];
    sliderState.rateCli = [];
    sliderState.rateTime = [];
  }, [resetCount]);
  // 板块table点击切换，更新状态
  useUpdateEffect(() => {
    chartState.type = typeOptions[2];
  }, [segCount]);

  // 推送范围切换
  const idRateOptions = COMMON_INDEX_2.map(n => <Option key={n.value} value={n.value}>{n.name}</Option>)
  // 条件范围搜索 Popover 模块
  const hasFilter = _.includes(fKeys.map(k => !!_.get(exParams, k)), true); // 筛选字段有有效值则代表当前事筛选数据
  const commonSliderProps = { range: { draggableTrack: true }, max: 20, min: 0 };
  //const rangeSliderTimes = !sliderState.rateTime[0] || !sliderState.rateTime[1] ? [null, null] : [moment(sliderState.rateTime[0]), moment(sliderState.rateTime[1])];
  // console.log('sliderState.rateTime', sliderState.rateTime)
  const content = (
    <div style={{ width: 330 }}>
      <Space direction='vertical' style={{ width: 325 }}>
        <SecText txt={'收益率范围'} strong />

        <Row align='middle'>
          <Col span={4}><SecText txt={'主板'} /></Col>
          <Col span={17}>
            <Slider {...commonSliderProps} value={sliderState.rate} onChange={(v) => sliderState.rate = v} />
          </Col>
          <Col span={3} style={{ textAlign: 'center' }}>
            <Text>{_.get(sliderState.rate, '[0]', '') + '-' + _.get(sliderState.rate, '[1]', '')}</Text>
          </Col>
        </Row>

        <Row align='middle'>
          <Col span={4}><SecText txt={'创业板'} /></Col>
          <Col span={17}>
            <Slider {...commonSliderProps} value={sliderState.rateCli} onChange={(v) => sliderState.rateCli = v} />
          </Col>
          <Col span={3} style={{ textAlign: 'center' }}>
            <Text>{_.get(sliderState.rateCli, '[0]', '') + '-' + _.get(sliderState.rateCli, '[1]', '')}</Text>
          </Col>
        </Row>

        <Row align='middle'>
          <Col span={4}><SecText txt={'时间'} /></Col>
          <Col span={20}>
            <TimePicker.RangePicker size='small' secondStep={1}
              // value={rangeSliderTimes}
              onChange={(date, dateStrings, info) => {
                sliderState.rateTime = dateStrings;
              }} />
          </Col>
        </Row>

        <Row align='middle'>
          <Col span={4}><SecText txt={'推送数'} /></Col>
          <Col span={20}>
            <InputNumber size='small' step={1} min={0} value={sliderState.num}
              onChange={(v) => sliderState.num = v}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 8 }}>
          <Col span={12}>
            <Button {...btn_link_props} disabled={!hasFilter} onClick={() => {
              onPmsChange('range', { type: 'reset' });
              sliderState.num = 0;
            }}>
              重置
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button {...btn_primary_props} onClick={() => {
              onPmsChange(
                'range', // 获取时的参数名称，type=confirm代表筛选确认字段
                {
                  type: 'confirm',
                  open: numberOrNull(_.get(sliderState, 'rate[0]')),
                  close: numberOrNull(_.get(sliderState, 'rate[1]')),
                  chiNextOpen: numberOrNull(_.get(sliderState, 'rateCli[0]')),
                  chiNextClose: numberOrNull(_.get(sliderState, 'rateCli[1]')),
                  bTime: _.get(sliderState, 'rateTime[0]', null),
                  eTime: _.get(sliderState, 'rateTime[1]', null),
                  countNum: _.round(_.get(sliderState, 'num', 0), 1)
                },
              )
            }}>
              确定
            </Button>
          </Col>
        </Row>
      </Space>
    </div >
  );
  // 指定股票信息搜索模块
  const hasSearch = searchTxt !== '' ? true : false;
  const content2 = (
    <div style={{ width: 290 }}>
      <Space direction='vertical' style={{ width: 289 }}>
        <SecText txt={'搜索指定股票:'} strong />
        <Input style={{ width: 288 }} placeholder='股票或股票代码' value={searchTxt} onChange={(e) => setSearchTxt(e.target.value)} />
        <NewRangePicker
          dates={seaDate}
          onSelect={(dateStrings) => {
            setSeaDate(dateStrings)
          }}
        />
        <Row style={{ marginTop: 8 }}>
          <Col span={12}>
            <Button {...btn_link_props} disabled={!searchBool} onClick={() => {
              setSearchTxt('');
              onSync();
            }}>
              重置
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button {...btn_primary_props} disabled={!hasSearch}
              onClick={() => onConfirm({ txt: searchTxt, date: seaDate })}
            >
              确定
            </Button>
          </Col>
        </Row>
      </Space>
    </div>
  )
  // 排序按钮
  const SortBtn = ({ name, keyname, color, skey }) => {
    return <div
      className={'upDownBlock'}
      style={{ backgroundColor: sortKeyname === keyname ? color : '#ededed' }}
      onClick={() => onSortChange(keyname, skey)}
    >
      <Text style={{ color: sortKeyname === keyname ? 'white' : "black" }}>
        {name}
      </Text>
    </div>
  }
  // 报告排序
  const menu = (
    <Menu
      items={reportArray.map(n => {
        return {
          label: <a style={{ color: _.get(n, 'color', 'black') }} onClick={() => {
            onSortChange(n.value); // 用培训的value作为sortKey，赋值给上层全局state
            chartState.r_color = n.color;
            chartState.r_key = n.value;
          }}>
            {n.label}
          </a>,
          key: n.value
        }
      })}
    />
  );
  const SortBtnReport = () => {
    const isReportSort = chartState.r_key !== 'cancel' ? true : false; // 取消状态为默认推送数据，否则认为是排序数据
    return <Tooltip title='报告研报排序'>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className='upDownBlock' style={{ backgroundColor: isReportSort ? chartState.r_color : '#ededed' }}>
          <ExceptionOutlined style={{ color: isReportSort ? 'white' : 'black' }} />
        </div>
      </Dropdown>
    </Tooltip>
  }
  const refreshHeaderStyles = isMobile ? { height: 150 } : {};
  const datepicker_props = { size: 'small', disabled: searchBool }
  return (
    <div className='refreshHeader' style={refreshHeaderStyles}>
      <Row align='middle'>
        <Col {...autoCol([6, 12])}>
          <Space>
            <Select style={{ width: 155 }} value={signalType} size='small'
              options={typeValueOptions}
              onChange={(val) => {
                setSignalType(val);
                onPmsChange('type', val);
              }} />
            <Popover content={content} trigger="click">
              <Button {...btn_text_props} icon={<ControlOutlined style={{ color: hasFilter ? '#00a2a8' : 'black' }} />} />
            </Popover>
            <Popover content={content2} trigger="click">
              <Button {...btn_text_props} icon={<SearchOutlined style={{ color: searchBool ? '#ffcb12' : 'black' }} />} />
            </Popover>
            {isTrading ? <div className='upDownBlock2' style={{ backgroundColor: isAuto ? '#00a2a8' : '#ededed' }} onClick={() => {
              onAutoChange();
            }}>
              <Tooltip title='自动刷新'>
                <Text strong style={{ color: isAuto ? 'white' : 'black' }}>A</Text>
              </Tooltip>
            </div> : <SyncButton onClick={onSync} />}
            <Spin spinning={loading} size='small' />
          </Space>
        </Col>
        <Col {...autoCol([6, 12])} style={{ textAlign: 'right' }}>
          <Space size='small'>
            <Tooltip title='分时'>
              <Button {...btn_text_props} icon={<SlidersFilled />} onClick={goTimeIndexPage} />
            </Tooltip>
            <Tooltip title='推送分析'>
              <Button {...btn_text_props} icon={<TableOutlined />} onClick={goPushPage} />
            </Tooltip>
            <SortBtnReport />
            <Select size='small' value={chartState.idRate.value} style={{ width: 90 }} bordered={false} onChange={(v) => {
              chartState.idRate.value = v;
              onPmsChange('indexCode', v)
            }}>
              {idRateOptions}
            </Select>
            <NewRangePicker
              isSingle
              value={exDate}
              {...datepicker_props}
              onSelect={(dateStrings) => {
                setExDate(dateStrings);
                onPmsChange('date', dateStrings);
                chartState.barDate = dateStrings; // 上层再更新时间后重新获取板块数据，故更新barDate
              }}
            />
          </Space>
        </Col>
        <Col {...autoCol([6])} style={{ paddingLeft: 12 }}>
          <Space>
            <Segmented size='small' options={typeOptions} value={chartState.type}
              onChange={(v) => {
                chartState.type = v;
                onPmsChange('barType', { 'date': chartState.barDate, 'type': v });
              }}
            />
            <Tooltip title='板块分析'>
              <Button {...btn_text_props} icon={<LineChartOutlined />} onClick={goPlatePage} />
            </Tooltip>
          </Space>
        </Col>
        <Col {...autoCol([6])} style={{ textAlign: isMobile ? 'center' : 'right' }}>
          <NewRangePicker
            isSingle={_.includes([typeOptions[0], typeOptions[1]], chartState.type) ? false : true}
            dates={chartDate}
            value={chartState.barDate}
            size='small'
            onSelect={(dateStrings) => {
              if (_.includes([typeOptions[0], typeOptions[1]], chartState.type)) {
                setChartDate(dateStrings);
                onPmsChange('pieDate', dateStrings);
              } else {
                chartState.barDate = dateStrings;
                onPmsChange('barType', { 'date': dateStrings, 'type': chartState.type, 'update': true });
              }
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 6 }}>
        <Col {...autoCol([12, 24])}>
          <Row align='middle'>
            <Col span={10}>
              <Space size='small'>
                <SecText txt={'推送平均:'} strong />
                <RateText rate={_.get(rateObj, 'costAverageRate')} />
                <SecText txt={'过滤平均:'} strong />
                <RateText rate={_.get(rateObj, 'filterAverageRate')} />
              </Space>
            </Col>
            <Col span={chartState.r_key !== 'cancel' ? 0 : 14} style={{ textAlign: 'right' }}>
              <Space size='small'>
                <SortBtn name='涨' keyname='up' color='red' skey='rate' />
                <SortBtn name='跌' keyname='down' color='green' skey='rate' />
                <SecText txt={'收益率'} />
              </Space>
              <Divider type='vertical' />
              <SecText txt={'推送价'} />
              <Divider type='vertical' />
              <Space size='small'>
                <SecText txt={'建仓收益率'} />
                <SortBtn name={<ArrowUpOutlined />} keyname='c_up' color='#faad14' skey='costRate' />
                <SortBtn name={<ArrowDownOutlined />} keyname='c_down' color='#52c41a' skey='costRate' />
              </Space>
              <Divider type='vertical' />
              <SecText txt={'时间'} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ExtraBar;