import * as echarts from 'echarts';
import _ from 'lodash';

const DEFAULT_CARRY = 5;
const CAL_CARRY = DEFAULT_CARRY + 1;
const renderFloats = (val, mul, pos = DEFAULT_CARRY) => _.round(parseFloat(val) * mul, pos);
const createBaseLine = (color, rest = {}) => {
  let obj = { data: [], type: 'line', symbol: 'none', ...rest };
  if (color) {
    obj.lineStyle = { color };
    obj.itemStyle = { color };
  }
  if ('isDash' in rest) {
    obj.lineStyle = _.assign(obj.lineStyle, { type: 'dashed', width: 2 });
  }
  return obj;
}
const COMMON_CHART = {
  rateX: {
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      symbol: ['none', 'arrow'],
      symbolSize: [8, 8],
      symbolOffset: 7,
      onZero: false,
    },
    data: []
  },
  rateY: {
    splitArea: {
      show: true
    },
    splitLine: {
      show: false
    },
    axisLine: {
      show: true,
      symbol: ['none', 'arrow'],
      symbolSize: [8, 8],
      symbolOffset: 7,
    }
  },
  indexSeries: {
    name: '指数',
    data: [],
    type: 'line',
    showSymbol: false,
    connectNulls: true,
    smooth: true,
    lineStyle: {
      color: '#ED7B35'
    },
    itemStyle: {
      color: '#ED7B35'
    },
  },
  tooltipCustom: {
    show: true,
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    },
    padding: 5,
  },
  indexSeries: {
    name: '指数波幅',
    data: [],
    type: 'line',
    symbol: 'none',
    lineStyle: {
      color: '#B5495B',
      type: 'dashed',
      width: 1,
    },
    itemStyle: {
      color: '#B5495B'
    },
  },
  rateSeries: {
    name: '收益率',
    data: [],
    type: 'line',
    //symbol: 'none',
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgba(58,77,233,0.6)'
        },
        {
          offset: 1,
          color: 'rgba(58,77,233,0.2)'
        }
      ])
    },
  },
  extraRate: {
    name: '超额收益率',
    lineStyle: {
      color: '#ffa600',
    },
    itemStyle: {
      color: '#ffa600'
    },
    type: 'line',
  }
}

// 计算收益率
function calRate(listArray = [], start = 0, rv = 1) {
  return _.size(listArray) > 0 ? listArray.map(p => {
    let rateVal = start === 0 ? 0 : _.round((p - start) / start, CAL_CARRY) * rv;
    return p !== '-' ? parseFloat(rateVal.toFixed(DEFAULT_CARRY)) : p
  }) : [];
}
// 计算净值
// export function calNets(listArray = []) {
//   return _.size(listArray) > 0 ? listArray.map(n => {
//     let netVal = _.round(1 * (1 + (n / 100)), CAL_CARRY);
//     return n !== '-' ? parseFloat(netVal.toFixed(DEFAULT_CARRY)) : n
//   }) : [];
// }
// 计算数组内大小值
function calMaxMin(array = [], cur = 0, type = 'min') {
  // 计算array时需要判断无 "-" 占位 及NaN数据
  const newArray = _.size(array) > 0 ? array.map(n => n && n !== '-' ? n : 0) : [0];
  if (type === 'min') {
    let minVal = _.min(newArray);
    return minVal < cur ? minVal : cur;
  } else if (type === 'max') {
    let maxVal = _.max(newArray);
    return maxVal > cur ? maxVal : cur;
  }
}

function renderSlice(array = [], start = 0, end = 0) {
  // const newEnd = _.size(array) <= end + 1 ? end : end + 1
  return _.slice(array, start, end + 1)
};
// 计算收益公式；
const calRateFormula = (val, pre) => _.round((val - pre) / pre, CAL_CARRY) * 100;
// 通过收益率时间渲染指数的数据，并计算波幅；【波幅公式】 n-收盘价 / 收盘价
function handleIndexValue(dateType = '', timesArray = [], idxTimesArray = [], idxPoints = [], close = 0) {
  let indexValues = ['-']; let priceValues = []; let dayPriceVals = [];
  let pureValue = []; let purePrice = [];
  let stimeArr = [];
  // 通过收益率时间渲染指数的数据，并计算波幅；【波幅公式】 n-收盘价 / 收盘价
  timesArray.map((t, i) => {
    const idxTime = idxTimesArray.indexOf(t);
    const rateValue = close === 0 ? 0 : calRateFormula(idxPoints[idxTime], close);
    // 实时数据，没到的时间会用最后一个点位一直填充到结束  【ExtraChart】需要判断对重复2次以上数据未无效数据
    const prevTwoValue = i > 2 ? [idxPoints[i - 2], idxPoints[i - 1]] : [0, 0];
    const inValidPoint = idxPoints[i] === prevTwoValue[0] && idxPoints[i] === prevTwoValue[1] && dateType === 'TODAY' ? true : false
    if (idxTime !== -1 && !inValidPoint) {
      indexValues[i] = parseFloat(rateValue.toFixed(DEFAULT_CARRY));
      priceValues[i] = _.round(idxPoints[idxTime], DEFAULT_CARRY);
      pureValue.push(parseFloat(rateValue.toFixed(DEFAULT_CARRY)));
      purePrice.push(idxPoints[idxTime]);
    } else {
      indexValues[i] = '-';
      priceValues[i] = '-'
    }
    stimeArr.push(i);
  });
  // 不是实时数据，timesArray收益率数据第一位是’前一交易日‘，所以indexValues[0]='-',故填充为0；
  if (dateType !== 'TODAY') {
    indexValues[0] = 0;
    priceValues[0] = close;
    // 每日收益计算，(当日-前一日)/前一日
    dayPriceVals = priceValues.map((p, i) => parseFloat(calRateFormula(p, i === 0 ? close : priceValues[i - 1])).toFixed(DEFAULT_CARRY));
  }
  // indexValues,priceValues：根据时间赋值的价格及计算后波幅数据；dayPriceVals：每日指数收益
  // purePrice,pureValue:价格和波幅的纯数据(无占位字段)，用于判断数组大小
  // stimeArr:slider滑动所需的纯数字数组;
  return { indexValues, priceValues, purePrice, pureValue, dayPriceVals, stimeArr }
}
// 计算回撤数据 ； 查找当前点之前的最大值，当前点-前最大值 / 最大值； 都是负数
export function handleRetreat(array = [], pos = 1) {
  let retreat = [];
  if (_.size(array) > 0) {
    array.map((n, i) => {
      let cur = 0;
      if (i > 0) {
        const beforeMax = _.max(_.slice(array, 0, i + 1));
        cur = beforeMax ? _.round(((n - beforeMax) / beforeMax) * pos, 4) : 0;
      }
      retreat.push(cur);
    })
  }
  return retreat;
};

function multiplication(array = [], carry = 6) {
  let sum = 0;
  let newValArray = [];
  let orrrg = [];
  let nonZero = false; // 判断非零位，true=已经遍历出有效值，如中间数据为0，可继续执行计算，而不是直接输出0；
  // 【累加乘公式】 (1 + n)(1 + n1)(1 + n2) 连成计算结果  ;  [运算保留精度6位]
  if (Array.isArray(array)) {
    array.map(val => {
      if (nonZero && sum !== 0) {
        let calval = (1 + val) * sum; //累计收益率计算公式
        sum = calval;
        newValArray.push(renderFloats(calval - 1, 100, carry)); // 百分比结果：（val - 1） * 100
      } else if (val !== 0 && sum === 0) {
        newValArray.push(renderFloats(val, 100, carry));
        sum = 1 + val; // 进行1+处理
        nonZero = true;
      } else {
        newValArray.push(renderFloats(val, 100, carry));
      }
      orrrg.push(val);
    })
  }
  return newValArray;
};

export {
  COMMON_CHART,
  renderFloats,
  createBaseLine,
  calRate,
  calMaxMin,
  renderSlice,
  handleIndexValue,
  multiplication
}