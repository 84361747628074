import React from 'react';
import { Statistic } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import _ from 'lodash';

// 渲染 涨红跌绿 颜色
function renderValueColor(value, vType = 'pure', colors = { up: 'red', down: 'green', zero: 'black' }) {
  let pureValue = vType === 'replace' ? _.replace(value || '', ',', '') : value;
  const floatValue = pureValue ? _.round(parseFloat(pureValue), 3) : 0;
  if (floatValue > 0) {
    return colors.up;
  } else if (floatValue < 0) {
    return colors.down;
  } else {
    return colors.zero;
  }
}

function renderValuePrefix(value) {
  const floatValue = value ? _.round(parseFloat(value), 3) : 0;
  if (floatValue > 0) {
    return <ArrowUpOutlined style={{ color: 'red' }} />
  } else if (floatValue < 0) {
    return <ArrowDownOutlined style={{ color: 'green' }} />
  } else {
    return null
  }
}

const StatisticHeader = ({ title = '', value = 0, isColor = false, isPrefix = false, fSize = 0, stProps = {} }) => {
  let renderStyle = { fontSize: 24, fontWeight: '600' }
  let sProps = { ...stProps }
  if (fSize) {
    renderStyle.fontSize = fSize;
  }
  if (isColor) {
    renderStyle.color = renderValueColor(value, 'replace');
  }
  if (isPrefix) {
    sProps.prefix = renderValuePrefix(value);
  }
  return <Statistic
    title={title}
    value={value}
    valueStyle={renderStyle}
    {...sProps}
  />
}

export default StatisticHeader;