import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Space, notification, Typography, Badge, Select, Tooltip } from 'antd';
import { INDEX_OPTIONS } from '../Components/sutil';
import { DAY_COLOR, typeValueOptions } from '@/utils/utils2';
import { base_options } from '@/utils/indexCodes';
import { autoCol, SyncButton } from '@/utils/utils';
import { useReactive } from 'ahooks';
import { NewRangePicker } from '@/view/common/widgets';
import Marquee from 'react-fast-marquee';
import _ from 'lodash';
import * as echarts from 'echarts';

const { Option } = Select;
const { Text } = Typography;
const PLATE_TYPE = [{ label: '板块', value: 'plate' }, { label: '行业', value: 'industry' }];
const renderText = (txt) => txt ?? '';
const renderTextSecond = (txt) => <Text type='secondary'>{txt}</Text>;
let timer = null;

export default function ChartsIndex(props) {
  const [datazoomVal, setDatazoomVal] = useState({});
  const markRef = useRef([]);
  const pmsState = useReactive(_.cloneDeep(_.get(props, 'defaultParams', {})));
  const [option, setoption] = useState(INDEX_OPTIONS.indexLine);

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('indexCharts'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    myChart.on('click', 'series.line', (param) => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        const getx = _.get(param, 'data.xAxis', '');
        const filters = _.filter(markRef.current, o => o.xAxis === getx);
        if (_.size(filters) > 0) {
          notification.open({
            key: 'limit_notice',
            message: '涨停票',
            description: <>
              {filters.map((n, i) => <div key={'l' + i}>
                <Text type='secondary'>{`${n.xAxis} : `}</Text>
                <Text strong style={{ color: props.colorType[_.get(n, 'pushType', 0)] }}>{n.value}</Text>
                <Text>{`-${renderText(_.get(n, 'plate'))}-${renderText(_.get(n, 'industry'))}`}</Text>
                <br />
              </div>)}
            </>,
            placement: 'bottom',
          });
        }
      }, 500);
    })

    let newOption = option;

    markRef.current = _.get(props, 'indexData.fullMarks', []);
    newOption.xAxis.data = _.get(props, 'indexData.xval', []);
    newOption.series[0].data = _.get(props, 'indexData.datas', []);
    newOption.series[0].markPoint.data = _.get(props, 'indexData.markpoints', []);
    newOption.yAxis[0].min = _.ceil(_.min(_.get(props, 'indexData.datas', [])) - 5, 1);
    newOption.yAxis[0].max = _.ceil(_.max(_.get(props, 'indexData.datas', [])) + 5, 1);
    newOption.yAxis[1].min = _.get(props, 'indexData.min', 0);
    newOption.yAxis[1].max = _.get(props, 'indexData.max', 0);
    // console.log('props', props);
    newOption.dataZoom[0].start = _.get(datazoomVal, 'start', newOption.dataZoom[0].start);
    newOption.dataZoom[0].end = _.get(datazoomVal, 'end', newOption.dataZoom[0].end);

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
    myChart.on('datazoom', (params) => {
      // console.log('zoom', params)
      setDatazoomVal(params);
    })
  }, [props]);
  // 先赋值为对象，再通过props传递
  function getIndex() {
    props.getIndexs({
      symbol: pmsState.symbol,
      dates: pmsState.dates,
      type: pmsState.type,
      pushType: pmsState.pushType
    });
  }

  function onParamsChange(key, v) {
    pmsState[key] = v;
    getIndex();
  }

  return (
    <>
      <Row justify='space-between' style={{ marginBottom: 8 }}>
        <Col {...autoCol([12])}>
          <NewRangePicker
            dates={pmsState.dates}
            size='small'
            onSelect={(dateStrings) => {
              onParamsChange('dates', dateStrings);
            }}
          />
          <Space style={{ marginLeft: 12 }}>
            <Badge color={DAY_COLOR['today']} text={renderTextSecond('今')} />
            <Badge color={DAY_COLOR['yesterday']} text={renderTextSecond('昨')} />
            <Badge color={DAY_COLOR['preday']} text={renderTextSecond('前')} />
          </Space>
        </Col>
        <Col {...autoCol([12])} style={{ textAlign: 'right' }}>
          <Space>
            <Select size='small' style={{ width: 130 }} bordered={false} value={pmsState.symbol}
              options={base_options}
              onChange={(v) => onParamsChange('symbol', v)} />
            <Select size='small' style={{ width: 140 }} value={pmsState.pushType}
              options={typeValueOptions}
              onChange={(v) => onParamsChange('pushType', v)} />
            <Select size='small' style={{ width: 100 }} value={pmsState.type}
              onChange={(v) => onParamsChange('type', v)}
            >
              {PLATE_TYPE.map((n, i) => <Option key={'pla' + i} value={n.value}>{n.label}</Option>)}
            </Select>
            <Tooltip title='刷新'>
              <SyncButton size='normal' onClick={() => getIndex()} />
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <div style={{ display: 'flex' }}>
        <div
          id="indexCharts"
          style={{ width: '100%', height: 410 }}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <Marquee speed={8} pauseOnHover>
          {_.get(props, 'ltext', '')}
        </Marquee>
      </div>
    </>
  )
}