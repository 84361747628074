import React from 'react';
import { Typography, Button, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

function validateResponse(res = {}, unmont = false) {
  let valid = false;
  if (_.get(res, 'code', '') === '200') {
    valid = true;
  } else {
    message.info(_.get(res, 'message', '获取失败!'));
  }
  if (unmont) { // 已经卸载则验证不通过
    valid = false;
  }
  return valid;
}

function isEmptyStringV2(value) {
  if (value == "undefined" || !value || !/[^\s]/.test(value)) {
    return true;
  } else {
    return false;
  }
}

// 自适应：默认中屏幕12，小屏幕24沾满一行
const autoCol = (span = [0, null, null]) => {
  return { xxl: span[0], xl: span[0], lg: span[1] || 12, md: span[2] || 24, sm: span[2] || 24, xs: span[2] || 24, flex: 'auto' }
}

// 收益率颜色 + 百分号处理
const updownColorKey = {
  zero: 'black',
  positive: 'red',
  negative: 'green'
}
// 根据rate返回带颜色或【%】的收益率string
const RateText = ({ rate = 0, carry = 2, prefix = true, restStyle = {} }) => {
  let renderRate = rate ? _.round(parseFloat(rate), carry).toFixed(carry) : 0;
  let ud = '';
  if (renderRate === 0) {
    ud = 'zero';
  } else if (renderRate > 0) {
    ud = 'positive';
  } else if (renderRate < 0) {
    ud = 'negative'
  }
  if (prefix) {
    renderRate = renderRate + '%';
  }
  return <Text style={{ color: updownColorKey[ud] || 'black', ...restStyle }}>
    {renderRate}
  </Text>
}

// 生成标准表格columns；typeArray=[需要增加单列条件的Index,type的值]
const createBasicColums = (colList = [], typeArray = []) => {
  return _.size(colList) > 0 ? colList.map((itemArray, i) => {
    if (_.size(typeArray) > 0 && typeArray.indexOf(i) !== -1) {
      return createSingleColumns(itemArray, _.last(typeArray))
    }
    return createSingleColumns(itemArray)
  }) : []
}
// 创建单一列的column信息
const ALIGN_KEYS = ['left', 'center', 'right']; // 单列数据中最后一位默认是align，如需要配置该行其他数据，可以通过非对齐字段进行判断。配合type使用
const SORT_KEYS = ['descend', 'ascend'];
const createSingleColumns = (pmArray = ['', ''], type = '') => {
  // pmArray 创建单列数据的参数数组; 根据使用频率进行排列及拓展；
  //0：名称 1:key字段 2宽度 3对齐（可用0占位默认值）4:默认排序
  let obj = {
    title: pmArray[0],
    dataIndex: pmArray[1],
    key: pmArray[1],
    align: pmArray[3] && ALIGN_KEYS.indexOf(pmArray[3]) !== -1 ? pmArray[3] : ALIGN_KEYS[0]
  }
  if (pmArray[2]) {
    obj.width = pmArray[2];
  }
  if (pmArray[4] && _.isArray(pmArray[4])) {
    obj.sorter = (a, b) => a[pmArray[1]] - b[pmArray[1]];
    obj.sortDirections = SORT_KEYS;
    if (SORT_KEYS.indexOf(pmArray[4][0]) !== -1) {
      obj.defaultSortOrder = _.head(pmArray[4]);
    }
  }
  // type 生成些标准需求且复用率高的列表渲染内容，更复杂无需在此继续增加type
  if (type === 'dateTime') {
    obj.render = function (text, record) { return text ? moment(text).format('YYYY-MM-DD HH:mm') : '' }
  } else if (type === 'updown') {
    const isRate = _.last(pmArray) === 'rate' ? false : true; // 收益字段，array最后一位是rate，则添加百分号
    obj.render = function (text, record) { return <RateText rate={text} prefix={isRate} /> }
  } else if (type === 'strong') {
    obj.render = function (text, record) { return <Text strong>{text}</Text> }
  } else if (type === 'strongPrice') {
    obj.render = function (text, record) {
      const priceVal = text ? _.round(parseFloat(text), 2) : 0;
      return <Text strong>{priceVal}</Text>
    }
  }
  return obj
}

// 大额资金增加逗号分隔
function addNumberComma(value = 0) {
  let splitNumber = _.reverse(_.split(String(_.isNumber(value) ? value : parseFloat(value)), '')); // 翻转数组，从小数点后进行遍历
  let c = 0;
  let isDim = _.includes(splitNumber, '.'); // 是否包含小数点，false=已经计算过或者没有小数点，true=当前数据包含小数点
  let stringArray = [];
  splitNumber.map(n => {
    let num = n !== '.' && n !== '-' ? parseInt(n) : null;
    if (n === '.') { //遇到小数点开始后循环开始c++
      isDim = false;
    }
    // 每3位进位，push【,】进行分隔，从小数点后整数开始计算，并且最后一位是负数不处理；
    if (c === 3 && !isDim && n !== '-') {
      c = 0;
      stringArray.push(',');
    }
    if (num !== null && !isDim) { //整数位c递增
      c++;
    }
    stringArray.push(n);
  })
  return _.join(_.reverse(stringArray), '');
}
// 刷新按钮
const SyncButton = ({ size = 'small', onClick }) => {
  return <Button icon={<SyncOutlined />} size={size} type='text' onClick={onClick} />
}

const colWidth = (cols = []) => {
  return _.size(cols) > 0 ? _.sum(cols.map(c => c.width ?? 0)) : 0
}


export {
  validateResponse,
  isEmptyStringV2,
  autoCol,
  RateText,
  createBasicColums, createSingleColumns,
  addNumberComma,
  SyncButton,
  colWidth,
}