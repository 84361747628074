import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, message } from 'antd';
import { showIndexKline, customIndexKline, customIndexKlineBasePlate, customIndexKlineBaseBellwetherStock } from '@/api/workbench';
import { handleIndexValue, renderSlice, calRate, handleRetreat, calMaxMin, multiplication } from '@/view/common/Components/chartsInfoUtils';
import { useReactive, useUnmount, useUpdateEffect, useMount } from 'ahooks';
import {
  isValidArray, isValidObj, isFullTimeRange, isTruthy, createDatasTimeKey, labelValues,
  VALUE_TYPE, PRE_DAY_CN
} from '@/utils/utils2';
import { autoCol, validateResponse, isEmptyStringV2 } from '@/utils/utils';
import { codes_value, category_3_name, category_5_name, rules_pre_key, code_value_2 } from '@/utils/indexCodes';
import { EXTRA_OPTIONS, SideTabs } from './Components/sutil';
import { MainSlider } from '@/view/common/widgets';
import IndexBar from './AlphaIndex/IndexBar';
import AlPlateTable from './AlphaIndex/AlPlateTable';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const TODAY = moment().format('YYYY-MM-DD');
const dateOptions = [
  labelValues(['今日', 'TODAY']), labelValues(['周', 'ONE_WEEK']),
  labelValues(['月', 'ONE_MONTH']), labelValues(['季', 'THREE_MONTH']),
  labelValues(['半年', 'SIX_MONTH']), labelValues(['年', 'ONE_YEAR']), labelValues(['三年', 'THREE_YEAR']),
];
const RETREAT_OPTIONS = [labelValues(['回撤', 'retreat'])];
const idx45_Options = ['基础', '指数'];
const CUSTOM_STR = 'CUS';
const VALID_PMS_KEY = { 'rise': 'customPms', 'plate': 'cusPlate', 'lhb': 'cusLhb' };
/**
 * 该图表实现为两条指数曲线波幅进行对比显示，故获取暂采用同时获取两次指数数据进行显示;
 * 230905:增加新页面，该页面pageKey字段适配不同页面
 * 240509 增加自定义指数接口，使用不同计算逻辑; 波幅增加前一交易日
 */
export default function AlphaIndexCharts(props) {
  const page_key = _.get(props, 'pageKey', '');
  const isAlpha = page_key === 'AlphaPage' ? true : false;
  const isNewPage = page_key === 'New' ? true : false;

  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [indexArr, setIndexArr] = useState([]); //指数数据数据，保存获取的原始数据，顺序与展示一致
  const [lensArr, setLenArr] = useState([]);
  const [lastIndex, setLastIndex] = useState([]);
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]); // slider直接读取的value，中间处理set赋值后不做任何处理，避免处理数据是的差错而改变
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]); // 完整时间数据
  const [fullSeries, setFullSeries] = useState([]); // 完整series数据
  const [fullSeriesRetreat, setFullSeriesRetreat] = useState([]); // 完整series数据
  const indexState = useReactive({
    idx: '000852', // 默认中证1000
    idx2: '999901', //默认 Alpha标准
    idx3: '', //分类
    idx2Arr: [], idx3Arr: [], idx4Arr: [], // 4和5 = 基础指标的选择数组
    idx5Arr: [], idx6Arr: [], // 新增指数指标选择数组
    idx45_name: {}, idx45_type: '基础', // 指数、基础切换
    type: isAlpha ? 'TODAY' : 'ONE_WEEK', date: TODAY, subType: 'retreat',
    customPms: { 'duration': 0, 'type': 202, 'pushNum': 0, 'rate': 0 },
    cusPlate: { 'endTime': '', 'type': 202, 'pushNum': 0, 'plateNum': 0 },
    cusLhb: { 'endTime': '', 'type': 202, 'pushNum': 0, 'rate': 0 },
    isCustom: null, cloading: false, cusIndex: 1, cusType: 'rise', cusNames: {},
  });
  const [option, setoption] = useState(EXTRA_OPTIONS.indexChart);
  const [option2, setoption2] = useState(EXTRA_OPTIONS.indexRetreat);
  const [plateData, setPlateData] = useState([]);
  const [plateAllData, setPlateAllData] = useState({});
  const [update3, setUpdate3] = useState(0);
  let isUnmont = false;

  useMount(() => {
    _showIndexKline(
      isAlpha ? 'range' : 'single',
      isAlpha ? _.join([indexState.idx, indexState.idx2], ',') : indexState.idx
    );
  });

  useUnmount(() => {
    isUnmont = true;
  });
  // 获取指数，批量与单一获取接口相同，symbol字段拼接不同
  async function _showIndexKline(type = '', symbolString = null) {
    const is_range = type === 'range' ? true : false;
    let temp = is_range ? [] : _.cloneDeep(indexArr);
    let newSymbol = symbolString;
    if (is_range) {
      if (!symbolString) {
        let newLastArr = [];
        lastIndex.map(n => {
          if (!_.includes(n.keys, CUSTOM_STR)) {
            newLastArr.push(n.keys);
          }
        })
        newSymbol = _.join(newLastArr, ',');
      }
    }
    let params = {
      'symbol': newSymbol,
      'dateType': indexState.type,
      'date': indexState.date
    };
    const res = await showIndexKline(params);
    const res2 = is_range && indexState.isCustom === 'custom' && params.dateType !== 'TODAY' ? await _customIndexKline('return') : null;
    if (validateResponse(res, isUnmont)) {
      const is_custom = isValidObj(res2) ? true : false;
      const getDatas = _.assign(
        _.get(res, 'data', {}),
        is_custom ? { [res2.keys]: res2 } : {}
      );
      if (!isValidObj(getDatas)) {
        message.info('暂无数据！');
        return
      };
      let lens = is_range ? [] : _.cloneDeep(lensArr);
      Object.keys(getDatas).map((symbolName, index) => {
        const is_cus_str = _.includes(symbolName, CUSTOM_STR);
        // 检查是否自定义的格式
        let newData = _.assign(
          is_cus_str ? _.get(res2, 'data') : _.get(res, `data.${symbolName}`, {}),
          {
            'keys': is_cus_str ? symbolName + index : symbolName,
            'len': is_cus_str ? _.get(res2, 'data.len') : (_.size(_.get(res, `data.${symbolName}.timeList`, [])) || 0)
          }
        );
        //基础指标名称要根据指标拼接；有该平常则显示
        if (symbolName in indexState.idx45_name) {
          newData.cname = indexState.idx45_name[symbolName];
        };
        // 空数据不增加
        if (newData.len !== 0) {
          temp.push(newData);
          lens.push(newData.len);
        }
        if (type !== 'range' && newData.len === 0) {
          message.info('暂无该记录！')
        }
      });
      // 空数据不进行赋值备份index
      if (_.size(lens) > 0) {
        setLastIndex(_.uniqBy(temp, 'keys'));
      };
      setLenArr(lens); //三条数据: [6,5,6] 代表遍历完成每个指数返回的数据个数
      setIndexArr(_.uniqBy(temp, 'keys'));
      setUpdate(_.round(update + 0.1, 1));
    }
  };
  // 自定义指数
  async function _customIndexKline(type = '') {
    indexState.cloading = true;
    let pms = { 'dateType': indexState.type, 'date': indexState.date };
    let final = { cname: '', len: 0, preClose: 0, isCus: true, pointList: [], timeList: [] };
    let finalOther = []; let plen = [];
    const isRiseGet = indexState.cusType === 'rise' ? true : false;
    //赋值，验证，比率除以100
    const validParams = _.get(indexState, VALID_PMS_KEY[indexState.cusType]);
    let isValid = true;
    _.keys(validParams).map(keyname => {
      const getVal = _.get(validParams, keyname);
      if (!isTruthy(getVal)) {
        isValid = false;
      };
      _.set(pms, keyname, keyname === 'rate' && isRiseGet ? getVal / 100 : getVal);
    });
    if (!isValid) {
      message.info('自定义参数有误！');
    };
    let res = {};
    if (isValid) {
      if (isRiseGet) {
        res = await customIndexKline(pms);
      } else if (indexState.cusType === 'plate') {
        res = await customIndexKlineBasePlate(pms);
      } else if (indexState.cusType === 'lhb') {
        res = await customIndexKlineBaseBellwetherStock(pms);
      };
    };
    if (_.get(res, 'code') === '200') {
      const getData = _.get(res, 'data', {});
      const dataArray = _.keys(getData).map(date => {
        return { 'date': date, 'rate': getData[date] };
      });
      const orderData = createDatasTimeKey(dataArray, 'date', 'd', 'asc');
      // 区分涨跌幅以及板块的数据处理
      if (isRiseGet) {
        // 按照指数格式赋值
        orderData.map((n, i) => {
          final['pointList'][i] = n.rate;
          final['timeList'][i] = n.date;
        });
        final['preClose'] = _.get(_.head(orderData), 'rate');
        final['len'] = _.size(orderData);
        final['cname'] = `${VALUE_TYPE[pms.type]}(${pms.duration}-${pms.pushNum}-${pms.rate * 100}%)`;
        final['keys'] = indexState.cusIndex; // 【bug-fix】全局加index不重复值，否则uniqBy的时候会认为重复清除掉
        indexState.cusIndex++;
        indexState.isCustom = 'custom';
      } else if (indexState.cusType === 'plate') {
        const combindName = `${VALUE_TYPE[pms.type]}(${pms.plateNum}-${pms.pushNum}-${pms.endTime})`;
        const itemArray = [{ key: 'rate', name: '' }, { key: 'costRate', name: '建仓' }, { key: 'closeRate', name: '收盘' }];
        const newOthers = handleMultiOthers(combindName, itemArray, orderData, final, finalOther);
        plen = _.uniq(_.concat(plen, newOthers.len));
        finalOther = newOthers.other;
        let all_key = 'plate' + _.size(plateAllData) + 1;
        let tempAll = _.cloneDeep(plateAllData);
        _.set(tempAll, all_key, orderData);
        setPlateData(orderData);
        setPlateAllData(tempAll);
        indexState.isCustom = 'plate';
        indexState.subType = all_key;
        _.set(indexState.cusNames, all_key, combindName);
        setUpdate3(_.round(update3 + 0.1, 1));
      } else if (indexState.cusType === 'lhb') {
        const combindName = `${VALUE_TYPE[pms.type]}(${pms.pushNum}-${pms.rate}-${pms.endTime})`;
        const itemArray = [{ key: 'fullRate', name: '全量' }, { key: 'limitRate', name: '限制' }];
        const newOthers = handleMultiOthers(combindName, itemArray, orderData, final, finalOther);
        plen = _.uniq(_.concat(plen, newOthers.len));
        finalOther = newOthers.other;
      };
      // console.log('finalOther', finalOther)
    };
    indexState.cloading = false;
    // range 返回数据，single直接赋值更新
    if (type === 'return') {
      return isValid ? { 'data': isRiseGet ? final : finalOther, 'keys': CUSTOM_STR + pms.type } : null;
    } else {
      if (isValid) {
        setLenArr(_.concat(lensArr, isRiseGet ? [final.len] : plen));
        setIndexArr(_.concat(indexArr, isRiseGet ? [final] : finalOther));
        setUpdate(_.round(update + 0.1, 1));
      }
    }
  };
  // 自定义指数多条曲线
  function handleMultiOthers(combindName = '', itemArray = [], orderData = [], final = {}, finalOther = []) {
    let len = []; let other = _.cloneDeep(finalOther);
    itemArray.map((itm, i) => {
      let singleFinal = _.cloneDeep(final);
      orderData.map((n, i) => {
        singleFinal['pointList'][i] = _.get(n, 'rate.' + itm.key);
        singleFinal['timeList'][i] = n.date;
      });
      singleFinal['preClose'] = _.get(_.head(orderData), 'rate.' + itm.key);
      singleFinal['len'] = _.size(orderData);
      singleFinal['cname'] = combindName + itm.name;
      singleFinal['keys'] = indexState.cusIndex;
      indexState.cusIndex++;
      other[i] = singleFinal;
      len.push(_.size(orderData));
    });
    return { len, other };
  };

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('alpha_index'));
    myChart2 = echarts.init(document.getElementById('alpha_index_retreat'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);

    const is_not_today = indexState.type !== 'TODAY' ? true : false;
    let newTime = []; let idxTimes = []; let legArr = []; let newSeires = []; let sArray = [];
    let cusLegArr = []; let retreatSeires = []; let min = 0; let max = 0;
    // console.log('indexArr', indexArr)
    if (isValidArray(indexArr)) {
      const minLens = _.min(lensArr); const maxLens = _.max(lensArr); // 返回指数数据中数据的最大最小值
      // 如果minKey有值，说明有数据不全，赋值第一位时间
      if (minLens !== maxLens) {
        let findex = _.findIndex(indexArr, o => o.len === maxLens);
        const longTimeList = _.get(indexArr, `[${findex}].timeList`, []);
        idxTimes = longTimeList;
      } else {
        idxTimes = _.get(indexArr, `[0].timeList`, [])
      };
      indexArr.map((n, i) => {
        const cname = 'cname' in n ? n.cname : (codes_value[n.keys] || ('指数' + i));
        // prices字段记录原始点位数据，不影响图表，裁剪直接使用
        let sobj = { 'name': cname, 'type': 'line', 'symbol': 'none', 'data': [], 'prices': [] };
        legArr.push(cname);
        // 【230906】取消裁切，少的数据会按照长时间轴显示
        const getTimes = _.get(n, 'timeList', []);
        const getPoints = _.get(n, 'pointList', []);
        const preClose = _.get(n, 'preClose', 0);
        let newPoints = [];
        if (_.size(getTimes) < _.size(idxTimes)) {
          let sfirst = idxTimes.indexOf(_.head(getTimes));
          let slast = idxTimes.indexOf(_.last(getTimes));
          let newCount = 0;
          idxTimes.map((t, i) => {
            if (i >= sfirst && i <= slast) {
              newPoints[i] = getPoints[newCount];
              newCount++;
            } else {
              newPoints[i] = '-'
            }
          })
        };
        let retreats = [];
        //【新】 自定义指数进行连乘计算；并无需回撤数据； 增加波幅的前一交易日
        if ('isCus' in n) {
          const newCusData = _.concat([0], multiplication(getPoints, 4));
          sobj.data = newCusData;
          sobj.prices = getPoints;
          _.set(sobj, 'isCus', true);
          cusLegArr.push(cname);
          // retreats = handleRetreat(newCusData, 100);
        } else {
          // 该function处理平台收益率指数，times未经过裁剪； 当前已进行裁剪，所以传入2次相同的时间数据即可;
          const addPrePoint = _.concat(is_not_today ? [preClose] : [], _.size(newPoints) > 0 ? newPoints : getPoints);
          const addPreTime = _.concat(is_not_today ? [PRE_DAY_CN] : [], idxTimes);
          const renderIndexs = handleIndexValue(
            indexState.type,
            addPreTime,
            addPreTime,
            addPrePoint,
            preClose
          );
          sobj.data = renderIndexs.indexValues;
          sobj.prices = renderIndexs.priceValues;
          sArray = renderIndexs.stimeArr;
          retreats = handleRetreat(renderIndexs.priceValues, 100);
          newTime = addPreTime;
        };
        newSeires.push(sobj);
        // 回撤数据计算
        let robj = { 'name': cname, 'type': 'line', 'symbol': 'none', 'data': retreats };
        retreatSeires.push(robj);
        min = calMaxMin(robj.data, min, 'min');
        max = calMaxMin(robj.data, max, 'max');
      });
    }
    setTimeArr(sArray);
    setTimeNameArr(newTime);
    // 赋值slider的滑动数据
    if (_.last(sArray)) {
      setSliderValue([0, _.last(sArray)]);
      setSliderValue2([0, _.last(sArray)]);
    }
    setFullSeries(newSeires); setFullSeriesRetreat(retreatSeires);
    cusLegArr = _.filter(legArr, o => cusLegArr.indexOf(o) === -1);
    //console.log('newSeires', newSeires)
    newOption.xAxis.data = newTime; newOption2.xAxis.data = newTime;
    newOption.legend.data = legArr; newOption2.legend.data = cusLegArr;
    newOption.series = newSeires; newOption2.series = retreatSeires;
    newOption2.yAxis.min = min; newOption2.yAxis.max = max;

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize(); myChart2.resize();
    echarts.connect([myChart, myChart2]);
  }, [update]);
  /**
   * slider滑动调用，更新数据；
   * 【逻辑根据平台 MainRateCharts 收益率页面修改，只对指数进行处理，以下为精简逻辑进行实现
   */
  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    myChart = echarts.init(document.getElementById('alpha_index'));
    myChart2 = echarts.init(document.getElementById('alpha_index_retreat'));
    const isFullRange = isFullTimeRange(sliderValue, timeArr); // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    let min = 0; let max = 0;
    if (isFullRange) { // 全部时间赋值完整数据
      const fullTimes = _.cloneDeep(timeNameArr);
      newOption.series = _.cloneDeep(fullSeries);
      newOption2.series = fullSeriesRetreat.map(r => { // 回撤需要重新计算最大最小值，重新遍历
        min = calMaxMin(r.data, min, 'min');
        max = calMaxMin(r.data, max, 'max');
        return r;
      });
      newOption.xAxis.data = fullTimes;
      newOption2.xAxis.data = fullTimes;
    } else {
      const sliceTimes = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
      newOption.series.map((sir, i) => { //对series进行遍历裁剪，直接使用价格数组
        const slicePrice = renderSlice(sir.prices, sliderValue[0], sliderValue[1]);
        const newStart = _.get(slicePrice, '[0]', 0);
        let sliceRate = _.get(sir, 'isCus') ? multiplication(slicePrice, 4) : calRate(slicePrice, newStart, 100); // 重新计算波幅
        sliceRate[0] = 0;
        sir.data = sliceRate;
        // 回测裁剪
        const sliceRetreat = _.get(sir, 'isCus') ? [] : handleRetreat(slicePrice, 100);
        newOption2.series[i].data = sliceRetreat;
        min = calMaxMin(sliceRetreat, min, 'min');
        max = calMaxMin(sliceRetreat, max, 'max');
      });
      newOption.xAxis.data = sliceTimes;
      newOption2.xAxis.data = sliceTimes;
    }

    newOption2.yAxis.min = min; newOption2.yAxis.max = max;

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.resize(); myChart2.resize();
  }, [update2]);

  useUpdateEffect(() => {
    if (indexState.subType === 'retreat') {
      let myChart = props.myChart;
      myChart = echarts.init(document.getElementById('alpha_index_retreat'));
      myChart.resize();
    }
  }, [indexState.subType]);

  // 渲染基础指标动态中文名称
  function renderSymbolName(idx6String) {
    const c3 = _.get(category_3_name, `2.${indexState.idx5Arr[0]}`, null); // 今昨前仓
    const c4 = _.get(category_3_name, `3.${indexState.idx5Arr[1]}`, null);    // 涨停非涨停
    const c5 = _.get(category_3_name, `4.${indexState.idx5Arr[2]}`, null);    // 【新】增加new page里面的新分类
    const conbind345 = `${c3 ? '-' + c3 : ''}${c4 ? '-' + c4 : ''}${c5 ? '-' + c5 : ''}`; // c3、c4选填
    // 指数指标选择需要区分处理
    if (indexState.idx45_type === idx45_Options[1]) {
      // 增加生成好的指数名称 - 后面c3 c4今仓逻辑相同显示方法
      return `${_.get(category_5_name, idx6String, '')}` + conbind345;
    } else {
      // code_value保存基础、去限等分类名称和value；【新】增加其他页面的名称 code_value_2,因有相同key值
      const c1 = _.get(isAlpha ? codes_value : code_value_2, `${indexState.idx4Arr[0]}`, '');
      const c2 = _.get(category_3_name, `1.${indexState.idx4Arr[1]}`, '全部'); // 分类名，空则为全部
      return `${c1}-${c2}` + conbind345; // c1、c2 必填
    }
  }
  // 指标确定
  function handleConfirm() {
    // 每次添加创建选项拼接的中文名字
    let symbolString = '';
    if (indexState.idx45_type === idx45_Options[1]) {
      if (_.size(indexState.idx6Arr) > 1) { // 指数指标只有选择到第二级或第三级才算有效选择
        symbolString = _.join(
          _.concat([rules_pre_key], _.drop(indexState.idx6Arr), indexState.idx5Arr), //删除第一级的默认值，改成8888开头, 然后拼接数组
          ''
        );
        // 单独截取idx6Arr拼接的字符串，用于直接getcategory_5_name已经生成的指数指标名称
        const idx6Str = _.join(_.concat([rules_pre_key], _.drop(indexState.idx6Arr)), '');
        indexState.idx45_name[symbolString] = renderSymbolName(idx6Str);
      } else {
        message.info('请选择有效指数指标!');
        return
      }
    } else {
      symbolString = _.join(_.concat(indexState.idx4Arr, indexState.idx5Arr), '');
      indexState.idx45_name[symbolString] = renderSymbolName(); // 更新保存name的object
    }

    if (!isEmptyStringV2(symbolString)) {
      _showIndexKline('single', symbolString);
    }
  };
  // slider完成后
  function onSliderFinish(v) {
    setSliderValue(v);
    setSliderValue2(v);
    setUpdate2(_.round(update2 + 0.1, 1));
  };
  // 清除\刷新\周期改变
  function syncDeleteRange(type) {
    indexState.isCustom = null; // 刷新关闭自定义获取
    indexState.cusIndex = 1;
    indexState.subType = 'retreat';
    indexState.cusNames = {};
    if (type === 'sync' || type === 'typeChange') {
      _showIndexKline('range')
    } else if (type === 'del') {
      setIndexArr([]);
      setUpdate(_.round(update + 0.1, 1));
    };
    setPlateAllData({});
    setPlateData([]);
  };

  const charts_height = 360;
  const newDateOptions = isAlpha ? dateOptions : _.drop(dateOptions);
  const newPlateOptions = isValidObj(plateAllData) ? _.keys(plateAllData).map((p, i) => labelValues(['板块' + (i + 1), p])) : [];
  const isNotRetreat = indexState.subType !== 'retreat' ? true : false;
  return (
    <>
      <IndexBar
        isAlpha={isAlpha}
        isNewPage={isNewPage}
        values={indexState}
        option45={idx45_Options}
        onValueChange={(key, val, isupdate) => {
          _.set(indexState, key, val);
          if (isupdate) {
            _showIndexKline('single', val);
          }
        }}
        onBaseChange={(v) => {
          indexState.idx = v;
          if (_.includes(['866001', '866006'], indexState.idx) && indexState.type === 'TODAY') {
            message.info('暂无当日指数！');
            return
          }
          _showIndexKline('single', v);
        }}
        onClear={() => syncDeleteRange('del')}
        onRepeat={() => props.goRepeat()}
        onRandom={() => _showIndexKline('single', '99992550random')}
        onConfirm={handleConfirm}
        onCustomIndex={(type) => {
          indexState.cusType = type;
          _customIndexKline(type);
        }}
        onSync={() => syncDeleteRange('sync')}
        onSingleDateChagne={(date) => {
          indexState.date = date;
          _showIndexKline('range');
        }}
      />

      <Row>
        <Col {...autoCol([2, 4, 8])}>
          <SideTabs
            options={newDateOptions}
            active={indexState.type}
            onSelect={(key) => {
              indexState.type = key;
              syncDeleteRange('typeChange')
            }}
          />
        </Col>
        <Col {...autoCol([22, 20, 16])}>
          <Spin spinning={indexState.cloading}>
            <div style={{ display: 'flex' }}>
              <div
                id="alpha_index"
                style={{ 'width': '100%', 'height': charts_height }}
              />
            </div>
          </Spin>
        </Col>
      </Row>

      <MainSlider
        isNotToday={indexState.type !== 'TODAY' ? true : false}
        isFull={isFullTimeRange(sliderValue, timeArr)}
        timeNameArray={timeNameArr}
        timeArray={timeArr}
        value={sliderValue}
        svalue={sliderValue2}
        onSliderChange={(v) => setSliderValue2(v)}
        onSliderAfterChange={(v) => onSliderFinish(v)}
        onReset={() => onSliderFinish([0, _.last(timeArr)])}
      />

      <Row justify='end' style={{ marginTop: 8 }}>
        <Col {...autoCol([2, 4, 8])} style={{ textAlign: 'center' }}>
          <SideTabs
            options={isValidArray(newPlateOptions) ? _.concat(RETREAT_OPTIONS, newPlateOptions) : RETREAT_OPTIONS}
            active={indexState.subType}
            onSelect={(v) => {
              indexState.subType = v;
              if (v !== 'retreat') {
                setPlateData(_.get(plateAllData, v));
                setUpdate3(_.round(update3 + 0.1, 1));
              }
            }}
          />
        </Col>
        <Col {...autoCol([22, 20, 16])}>
          <div style={{ display: isNotRetreat ? 'none' : 'flex' }}>
            <div
              id="alpha_index_retreat"
              style={{ 'width': '100%', 'height': charts_height }}
            />
          </div>

          {isValidArray(plateData) && <AlPlateTable
            update={update3}
            dataOrder={plateData}
            show={isNotRetreat}
            cusNames={_.get(indexState.cusNames, indexState.subType, '')}
          />}
        </Col>
      </Row>
    </>
  )
}