import React, { } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { KeepaliveRouterSwitch ,KeepaliveRoute  } from 'react-keepalive-router'
import { routeConfig } from '@/router/route.config';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';

export default function Guards(props) {
  const mobxStore = useLocalObservable(() => store);

  return (
    <Observer>
      {() => (
        <Router>
          <KeepaliveRouterSwitch>
            {
              routeConfig.map(item => {
                if (item.path === "/strategy/alpha") {
                  return <KeepaliveRoute key={item.path} path={item.path} component={item.component} ></KeepaliveRoute>
                } else {
                  return <Route key={item.path} exact={item.exact} path={item.path} component={item.component} />
                }
              })
            }
            {/* <Redirect to='/strategy' /> */}
            {
              mobxStore.userLogin ?
                <Redirect to='/strategy' />
                :
                <Redirect to='/login' />
            }
          </KeepaliveRouterSwitch>
        </Router>
      )}
    </Observer>
  )
}