import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { isValidArray } from '@/utils/utils2';
import { NORM_LINE_CHARTS } from '../../Components/sutil';
import _ from 'lodash';
import * as echarts from 'echarts';
// 推送重复图表
export default function RepeatCharts(props) {
  const [option, setoption] = useState(NORM_LINE_CHARTS);

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('repeat_num_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option);
    const repeatDatas = _.get(props, 'datas', []);
    let sdata = []; let times = [];

    if (isValidArray(repeatDatas)) {
      repeatDatas.map(n => {
        sdata.push(_.get(n, 'value', '-'));
        times.push(_.get(n, 'date', ''));
      })
    }
    //console.log(sdata);
    newOption.xAxis.data = times;
    newOption.series = [createBaseLine(null, {
      name: '重复次数',
      data: sdata
    })];
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [props.updateCount]);

  return (
    <>
      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="repeat_num_charts"
            style={{ width: '100%', height: 405 }}
          />
        </div>
      </Spin>
    </>
  )
}