import React, { useState } from 'react';
import {
  PageHeader, Card, Row, Col,  Space, Table, Cascader, Button, Typography, Select,
  Modal, message
} from 'antd';
import { showBackStrategy } from '@/api/stock';
import { colWidth } from '@/utils/utils';
import { VALUE_TYPE, labelValues, isValidArray, isValidString, simpleColumns, typeValueOptions } from '@/utils/utils2';
import { NewRangePicker, NewDivider } from '@/view/common/widgets';
import { useMount, useReactive } from 'ahooks';
import VirtualTable from '@/view/common/Components/VirtualTable';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import _ from 'lodash';
import './pages.scss';

const { Text } = Typography;
const FTYPE_ARRAY = [
  ['tempPctChgRank', '一分钟涨跌幅档位', ['0.500%', '1.00%', '1.500%']],
  ['totalPctChgRank', '当前涨跌幅档位', ['2.00%', '4.00%', '6.00%']],
  ['preAvgVolumeRank', '昨日平均成交量档位', [7.5, 15, 30]],
  ['preMaxVolumeRank', '昨日最大成交量档位', [0.75, 1.5, 3]],
  ['pre5AvgAvgVolumeRank', '前五日平均成交量的平均值档位', [6, 12, 24]],
  ['pre5MaxAvgVolumeRank', '前五日平均成交量的最大值档位', [5, 10, 20]],
  ['pre5AvgMaxVolumeRank', '前五日最大成交量的平均值档位', [0.6, 1.2, 2.4]],
  ['pre5MaxMaxVolumeRank', '前五日最大成交量的平均值档位', [0.5, 1, 2]],
];
const FTYPE_OPTIONS = FTYPE_ARRAY.map(itm => labelValues([
  itm[1], itm[0],
  itm[2].map(sub => labelValues([sub, sub]))
]));
const NEW_TYPE_OPTIONS = _.filter(typeValueOptions, o => o.value > 200);
const TODAY = moment().format('YYYY-MM-DD');
/**
 *  推送回测页面
 */
const PushTestback = (props) => {
  const [tests, setTests] = useState([]); //  全量数据
  const [filter, setFilter] = useState([]); // 筛选数据
  const [exLoading, setExLoading] = useState(false);
  const pushState = useReactive({
    loading: false, date: [TODAY, TODAY], ftype: [],
    isFilter: 'emp', shows: { show: false, item: {} }, signalType: null
  });

  useMount(() => {
    _showBackStrategy();
  });

  // 获取回测数据
  async function _showBackStrategy() {
    const openMoment = moment(pushState.date[0] + ' 09:00:00');
    pushState.loading = true;
    let params = {
      'start': pushState.date[0] + ' 00:00:00',
      'end': pushState.date[1] + ' 00:00:00',
    }
    // console.log(params)
    const res = await showBackStrategy(params);
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      let newData = getData.map(item => {
        const pushMoment = moment(_.get(item, 'dateTime', ''));
        // 【tips】virtuallist虚拟列表可通过增加字段方式显示render信息；
        return {
          ...item,
          'isPush': _.size(_.get(item, 'pushType', [])) > 0 ? '是' : '否',
          'newPreLimit': _.get(item, 'isPre5TradeDaysLimitedStocks', '') === 't' ? '是' : '否',
          'dateTime': moment(_.get(item, 'dateTime', '')).format('HH:mm:ss'),
          'dateFormat': moment(_.get(item, 'dateTime', '')).format('YY-MM-DD'),
          'orderKey': openMoment.diff(pushMoment, 'millisecond'),
          'pushTypeArr': isValidArray(_.get(item, 'pushType')) ? _.get(item, 'pushType').map(n => parseInt(n.type)) : []
        }
      })
      setTests(newData);
      filterClear();
    }
    pushState.loading = false;
  }

  const columns = [
    simpleColumns(['代码', 'symbol', 70]),
    simpleColumns(['是否推送', 'isPush', 95]),
    simpleColumns(['日期', 'dateFormat', 105]),
    {
      ...simpleColumns(['时间', 'dateTime', 105]),
      sorter: (a, b) => a.orderKey - b.orderKey,
    },
    simpleColumns(['一分钟成交量', 'totalVolume', 105]),
    simpleColumns(['昨日平均成交量', 'preAvgVolume', 115]),
    simpleColumns(['一分钟成交量与昨日平均成交量的倍数', 'preAvgVolumeTimes', 185]),
    simpleColumns(['昨日最高成交量', 'preMaxVolume', 115]),
    simpleColumns(['一分钟成交量与昨日最大成交量的倍数', 'preMaxVolumeTimes', 185]),
    simpleColumns(['前五日平均成交量的平均值', 'pre5DaysAvgAvgVolume', 158]),
    simpleColumns(['一分钟成交量与前五日平均成交量的平均值的倍数', 'pre5DaysAvgAvgVolumeTimes', 200]),
    simpleColumns(['前五日平均成交量的最大值', 'pre5DaysMaxAvgVolume', 158]),
    simpleColumns(['一分钟成交量与前五日平均成交量的最大值的倍数', 'pre5DaysMaxAvgVolumeTimes', 200]),
    simpleColumns(['前五日最大成交量的平均值', 'pre5DaysAvgMaxVolume', 158]),
    simpleColumns(['一分钟成交量与前五日最大成交量的平均值的倍数', 'pre5DaysAvgMaxVolumeTimes', 200]),
    simpleColumns(['前五日最大成交量的最大值', 'pre5DaysMaxMaxVolume', 158]),
    simpleColumns(['一分钟成交量与前五日最大成交量的最大值的倍数', 'pre5DaysMaxMaxVolumeTimes', 200]),
    simpleColumns(['一分钟涨跌幅', 'tempPctChange', 105]),
    simpleColumns(['当前涨跌幅', 'totalPctChange', 105]),
    simpleColumns(['昨日涨跌幅', 'prePctChg', 105]),
    simpleColumns(['前五日最低到昨收涨跌幅', 'pre5DaysMaxPctChg', 145]),
    simpleColumns(['前十日最低到昨收涨跌幅', 'pre10DaysMaxPctChg', 145]),
    simpleColumns(['今日开盘涨跌幅', 'todayOpenPctChg', 125]),
    simpleColumns(['前五日累计涨跌幅', 'pre5DaysPctChg', 128]),
    simpleColumns(['一分钟交易笔数', 'totalTrade', 120]),
    simpleColumns(['买入笔数', 'buyTrade', 95]),
    simpleColumns(['大单买入笔数', 'bigBuyTrade', 105]),
    simpleColumns(['特大单买入笔数', 'extremeBuyTrade', 115]),
    simpleColumns(['卖出笔数', 'sellTrade', 95]),
    simpleColumns(['前五个交易日涨幅大于9.9%的次数小于等于1次', 'newPreLimit', 200]),
    simpleColumns(['今日市值', 'todayMarketCap', 95]),
    simpleColumns(['昨日市值', 'preMarketCap', 95]),
  ];

  // 导出
  function exportExcel() {
    let option = {}; let datas = [];
    const newExport = pushState.isFilter === 'isFilter' ? filter : tests;
    if (!isValidArray(newExport)) {
      message.info('暂无数据导出！');
      return;
    }
    newExport.map(n => {
      let exItem = {};
      columns.map(col => {
        exItem[_.get(col, 'title')] = _.get(n, col.key, '');
        // _.set(exItem, _.get(col, 'title'), _.get(n, col.key, ''))
      });
      datas.push(exItem);
    });
    // console.log('todayDescList', todayDescList)
    const colName = columns.map(n => n.title)
    option.fileName = `推送回测`;
    option.datas = [
      {
        sheetData: datas,
        sheetName: 'sheet',
        sheetFilter: colName,
        sheetHeader: colName,
      }
    ];
    //console.log(option);
    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
    setExLoading(false);
  };
  // 处理筛选
  function handleFilter(key, val) {
    pushState[key] = val;
    let fdata = []; let isCondition = false;
    let filterData = pushState.isFilter === 'emp' ? tests : [];
    //叠加选择，需要切换一个选择后，重新构建filterData，满足第二个选择需求
    if (key === 'signalType') {
      if (_.size(pushState.ftype) > 0) {
        filterData = _.filter(tests, o => o.type === _.join(pushState.ftype, ''));
        isCondition = true;
      }
      fdata = _.filter(isCondition ? filterData : tests, o => _.includes(o.pushTypeArr, parseInt(val)));
    } else if (key === 'ftype') {
      if (pushState.signalType) {
        filterData = _.filter(tests, o => _.includes(o.pushTypeArr, parseInt(pushState.signalType)));
        isCondition = true;
      }
      fdata = _.filter(isCondition ? filterData : tests, o => o.type === _.join(val, ''));
    }
    setFilter(fdata);
    pushState.isFilter = 'isFilter'; // 当前筛序
  }

  function filterClear() {
    pushState.isFilter = 'emp'; // 无筛选
    pushState.signalType = null;
    setFilter([]);
  }

  const expandedRowRender = (fatRecord = {}, type = '') => {
    const getTypeData = _.get(fatRecord, 'pushType', []);
    return (
      <div style={_.assign({ width: 300, float: 'left' }, type === 'modal' ? { height: 450 } : {})}>
        <Space direction='vertical'>
          {getTypeData.map(n => {
            const text_props = { mark: String(pushState.signalType) === String(_.get(n, 'type')) ? true : false }
            return <Space>
              <Text {...text_props}>{_.get(VALUE_TYPE, _.get(n, 'type'), '')}</Text>
              <Text {...text_props}>{_.get(n, 'price', '')}</Text>
              <Text {...text_props}>{_.get(n, 'time', '')}</Text>
              {isValidString(_.get(n, 'rate', '')) ? <Text {...text_props}>收益率：{_.get(n, 'rate', '')}</Text> : ''}
              {isValidString(_.get(n, 'costRate', '')) ? <Text {...text_props}>建仓：{_.get(n, 'costRate', '')}</Text> : ''}
            </Space>
          })}
        </Space>
      </div>
    )
  }

  const tableProps = {
    'rowKey': 'id', 'columns': columns, 'size': 'small', 'bordered': true,
    'expandable': { expandedRowRender, rowExpandable: (record) => _.size(record.pushType) > 0 },
    'loading': pushState.loading, 'scroll': { x: colWidth(columns), y: 618 }, 'pagination': false
  };
  const modalProps = { 'visible': _.get(pushState.shows, 'show'), 'footer': false, 'title': null, 'bodyStyle': { minHeight: 450 } };
  return <>
    <PageHeader
      title={'推送回测'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }} style={{ minHeight: 620 }}>
      <Row align='middle'>
        <Col span={18}>
          <Space>
            <NewRangePicker
              dates={pushState.date}
              restRange={{ style: { width: 235 } }}
              onSelect={(dateStrings) => {
                pushState.date = dateStrings;
                _showBackStrategy();
              }}
            />
            <Select style={{ width: 155 }}
              value={pushState.signalType}
              options={NEW_TYPE_OPTIONS}
              onChange={(v) => handleFilter('signalType', v)} />
            <Cascader
              style={{ width: 215 }}
              value={pushState.ftype}
              options={FTYPE_OPTIONS}
              onChange={(v) => handleFilter('ftype', v)}
            />
            <Button disabled={pushState.isFilter === 'emp' ? true : false} onClick={() => filterClear()}>
              取消筛选
            </Button>
          </Space>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button loading={exLoading} onClick={() => {
            setExLoading(true);
            exportExcel();
          }}>
            导出
          </Button>
        </Col>
      </Row>

      <NewDivider/>

      {pushState.isFilter === 'isFilter' ? <Table
        dataSource={filter}
        {...tableProps}
      /> :
        <VirtualTable
          dataSource={tests}
          rowClick={record => {
            if (isValidArray(_.get(record, 'pushType', []))) {
              pushState.shows = { show: true, item: record }
            }
          }}
          {...tableProps}
        />}
    </Card>

    <Modal {...modalProps}
      onCancel={() => pushState.shows = { show: false, item: {} }}
    >
      <div style={{ marginBottom: 18 }}>
        <Text strong>{_.get(pushState.shows, 'item.symbol', '')}</Text>
      </div>
      {expandedRowRender(_.get(pushState.shows, 'item', {}), 'modal')}
    </Modal>
  </>
}

export default PushTestback;