import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Space, Typography, Checkbox, Spin, Segmented } from 'antd';
import { INDEX_OPTIONS } from '../../Components/sutil';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { isValidArray, isValidObj, labelValues } from '@/utils/utils2';
import { CHECKBOX_DAY, PUSH_MINUTE_CKEYS } from '../putil';
import { useReactive } from 'ahooks';
import _ from 'lodash';
import * as echarts from 'echarts';

const { Text } = Typography;
const AVG_OPTIONS = [labelValues(['5日', 5])];
const CHECK_OPTIONS = [labelValues(['常规', 'day']), labelValues(['分钟', 'min'])];
const ITEM_TYPE = {
  'day': CHECKBOX_DAY,
  'min': PUSH_MINUTE_CKEYS
}
// 推送统计均线图表
export default function AvgCharts(props) {
  const pmsState = useReactive({
    val: _.get(props, 'defaultAvgNum', 5),
    segVal: CHECK_OPTIONS[0].value
  });
  // const [datazoomVal, setDatazoomVal] = useState({})
  const [option, setoption] = useState(INDEX_OPTIONS.avg);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('push_avg_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const newPushs = pmsState.segVal === 'day' ? _.get(props, 'datas', {}) : _.get(props, 'cdatas', {});
    const timeOrders = _.get(newPushs, 'timeOrder'); // 排序完成的时间数据
    const avgVals = _.get(newPushs, 'avg', {})
    const startKey = _.get(newPushs, 'startObj.skey', 0); // 开始日期的index值
    const filterCheckbox = ITEM_TYPE[pmsState.segVal];
    let newOption = _.cloneDeep(option);
    let times = []; let sdata = [];
    if (isValidArray(checkboxValues)) {
      // 根据选择的key，创建空series数据，区分移动均线
      checkboxValues.map(c => {
        let findex = _.findIndex(filterCheckbox, o => o.value === c);
        if (findex !== -1) {
          sdata.push(createBaseLine(null, { name: filterCheckbox[findex].label, keys: c }));
          sdata.push(createBaseLine(null, { name: filterCheckbox[findex].label + '(平均)', keys: c, isAvg: true, isDash: true }));
        }
      });
      // 根据时间进行遍历
      if (isValidArray(timeOrders)) {
        let count = 0; // 数据index
        timeOrders.map((n, i) => {
          if (i >= startKey) { // 从起始位开始处理
            const start_index = i - (pmsState.val - 1);// 往前推5日，包含当天，所以需要-1，不减去当日
            times[count] = _.get(n, 'date');
            // 直接处理series.data数据，每个时间点进行赋值或者计算
            sdata = sdata.map(d => {
              if ('isAvg' in d) { // 平均线对点位进行计算
                const day_avg_val = calAvgPoints(avgVals, timeOrders, start_index, d.keys);
                _.set(d, `data[${count}]`, day_avg_val === 0 ? '-' : day_avg_val);

              } else { // 非平均根据日期和字段获取数据
                const day_val = _.round(_.get(avgVals, `${n.date}.${d.keys}`, 0), 6);
                _.set(d, `data[${count}]`, day_val === 0 ? '-' : day_val);
              }
              return d;
            });
            count++;
          }
        })
      }
    }
    //console.log('sdata', sdata)
    newOption.xAxis.data = times;
    newOption.series = sdata;
    // newOption.dataZoom[0].start = _.get(datazoomVal, 'start', newOption.dataZoom[0].start);
    // newOption.dataZoom[0].end = _.get(datazoomVal, 'end', newOption.dataZoom[0].end);
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
    // myChart.on('datazoom', (params) => {
    //   setDatazoomVal(params);
    // });
  }, [props.updateCount, update]);

  // 计算均线：前五日数据综合求平均，返回一个点位数据
  function calAvgPoints(avgs, times, sindex, keyname) {
    // avg 完整数据obj; times:完整时间数据; sindex 从哪个index值开始计算; keyname：数据字段
    let avalues = []; let final = 0;
    if (sindex > -1) {
      // 从起始位开始遍历{均线日}次，拿到{均线日}个数据=avalues
      for (let index = _.cloneDeep(sindex); index < (sindex + pmsState.val); index++) {
        avalues.push(_.get(avgs, `${times[index]['date']}.${keyname}`, 0));
      }
    }
    // 累加计算平均
    if (isValidArray(avalues)) {
      let is_full = true;
      // 包含{均线日}完整数据可进行计算
      avalues.map(a => {
        if (!a) {
          is_full = false;
        }
      });
      if (is_full) {
        final = _.chain(avalues).sum().divide(pmsState.val).round(6).value();
      }
    }
    return final;
  }

  const onCheckBoxChange = (checkedValues) => {
    setCheckboxValues(checkedValues);
    setUpdate(_.round(update + 0.1, 1));
  };

  const select_props = {
    style: { width: 130 }, bordered: true, size: 'small', value: pmsState.val, options: AVG_OPTIONS, bordered: false
  };
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginTop: 12, marginBottom: 35, marginLeft: 8, marginRight: 8, paddingLeft: 8, paddingRight: 8 };
  const renderCheckbox = ITEM_TYPE[pmsState.segVal];
  return (
    <>
      <Row gutter={[8, 8]} style={controlBarStyle}>
        <Col span={24}>
          <Space>
            <Segmented size='small' options={CHECK_OPTIONS} value={pmsState.segVal}
              onChange={(v) => {
                pmsState.segVal = v;
                setCheckboxValues([]);
                // cdatas数据为空，则上层获取数据
                if (v === 'close' && !isValidObj(_.get(props, 'cdatas', {}))) {
                  props.onGetCpush();
                }
              }}
            />
            <Text type='secondary'>均线区间</Text>
            <Select {...select_props} onChange={(v => {
              pmsState.val = v;
              props.onAvgNumChange(v);
            })} />
          </Space>
        </Col>
        <Col span={24}>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={checkboxValues}
            onChange={onCheckBoxChange}
          >
            <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
              {renderCheckbox.map((itm, i) => {
                return <Col span={3} key={i}>
                  <Checkbox value={itm.value}>{itm.label}</Checkbox>
                </Col>
              })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="push_avg_charts"
            style={{ width: '99%', height: 410 }}
          />
        </div>
      </Spin>
    </>
  )
}