import React, { useEffect, useState, useRef } from 'react';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { Tabs, Form, Spin, Input, Button, message, Space } from 'antd';
import { getForgetPasswordCode, getForgetEmailPasswordCode, postEmailForgetPassword, postPhoneForgetPassword, getCaptcha } from '@/api/auto';
import logo from '@/images/_logoEN.svg';
import VerifyModal from './VerifyModal';
import { cryptoString } from '@/utils/cryptoUtils';
import UUID from 'uuidjs';
const { TabPane } = Tabs;

export default function ForgetPassword(props) {
    const [spinning, setspinning] = useState(false);
    const [tabskey, settabskey] = useState('1');
    const [loading, setloading] = useState(false);
    const [disableMsgBtn, setDisableMsgBtn] = useState(false);
    const [disableTime, setDisableTime] = useState(60);
    const mobxStore = useLocalObservable(() => store);
    const [formEmail] = Form.useForm();
    const [formPhone] = Form.useForm();
    const interVal = useRef(null);
    const [captchaVal, setCaptchaVal] = useState(null);
    const [verifyShow, setVerifyShow] = useState(false);

    useEffect(() => {
        interVal.current = {
            time: 60,
            index: null,
        }

        return () => {
            clearInterval(interVal.current.index);
        }
    }, [mobxStore, interVal])

    // function onclick() {
    //     mobxStore.landLogin();
    //     props.history.push('/strategy');
    // }
    function _goLogin() {
        props.history.push('/');
    }
    function callback(key) {
        settabskey(key);
        if (key === '1') {
            formEmail.resetFields(); // 清除表单数据
        } else {
            formPhone.resetFields(); // 清除表单数据
        }
    }
    // 获取图片验证码
    async function _getCaptcha() {
        const phone = formPhone.getFieldValue('phone');
        if (phone === '' || !(/^1[3456789]\d{9}$/.test(phone))) {
            return message.error('请输入手机号！');
        }
        const uuid = UUID.generate();
        // const res = await getCaptcha(uuid);
        setCaptchaVal(uuid); // 本地记录图片的token，登录传给后台
        setVerifyShow(true);
    }

    async function _getSmsCode(val) {
        if (disableMsgBtn) {
            return message.warning('您点击的太快了！');
        }
        if (tabskey === '1') {
            const phone = formPhone.getFieldValue('phone');
            if (phone === '' || !(/^1[3456789]\d{9}$/.test(phone))) {
                return message.error('请输入正确的手机号！');
            }
            if (val === '') {
                return message.error('请输入图片验证码！');
            }
            setloading(true);
            let params = {
                phone: phone,
                captchaToken: captchaVal,
                captcha: val,
            }
            const data = await getForgetPasswordCode(params);
            if (data.code === '200') {
                message.success('发送手机验证码成功');
                setDisableMsgBtn(true);
                runDisableTime();
                setVerifyShow(false);
            } else {
                message.error(data.message)
            }
            setloading(false);
        } else {
            const email = formEmail.getFieldValue('email');
            if (email === '' || !(/^([a-zA-Z]|[0-9])(\w|)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(email))) {
                return message.error('请输入正确的邮箱号！');
            }
            if (val === '') {
                return message.error('请输入图片验证码！');
            }
            setloading(true);
            let params = {
                email: email,
                captchaToken: captchaVal,
                captcha: val,
            }
            const data = await getForgetEmailPasswordCode(params);
            //console.log(data);
            if (data.code === '200') {
                message.success('发送邮箱验证码成功');
                setDisableMsgBtn(true);
                runDisableTime();
                setVerifyShow(false);
            } else {
                message.error(data.message)
            }
            setloading(false);
        }
    }
    function runDisableTime() {
        interVal.current.index = setInterval(() => {
            if (interVal.current.time <= 1) {
                clearInterval(interVal.current.index);
                setDisableMsgBtn(false);
                interVal.current.time = 60;
                setDisableTime(60);
            }
            interVal.current.time -= 1;
            setDisableTime(t => t - 1);
        }, 1000);
    }
    async function onFinish(values) {
        if (tabskey === '1') {
            //console.log('phone', values);
            if (values.password !== values.passwordConfirm) {
                return message.error('两次密码输入不一致！')
            }
            setspinning(true);
            let params = {
                account: values.phone,
                verificationCode: values.phoneCode,
                password: cryptoString(values.password),
            }
            params.confirmPassword = params.password;
            const data = await postPhoneForgetPassword(params);
            //console.log(data);
            if (data.code === '200') {
                message.success('成功找回密码');
                props.history.push('/');
            } else {
                message.error(data.message);
            }
            setspinning(false);
        } else {
            //console.log('email', values);
            if (values.emailpassword !== values.emailpasswordConfirm) {
                return message.error('两次密码输入不一致！')
            }
            setspinning(true);
            let params = {
                account: values.email,
                verificationCode: values.emailCode,
                password: cryptoString(values.emailpassword),
            }
            params.confirmPassword = params.password;
            const data = await postEmailForgetPassword(params);
            //console.log(data);
            if (data.code === '200') {
                message.success('成功找回密码');
                props.history.push('/');
            } else {
                message.error(data.message);
            }
            setspinning(false);
        }
    }

    return (
        <Observer>
            {() => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        alt=""
                        src={logo}
                        style={{ margin: '150px 0 20px', width: 300, height: 110 }}
                    />

                    <div style={{ width: 400, }}>
                        <Spin spinning={spinning}>
                            <Tabs
                                defaultActiveKey={tabskey}
                                centered
                                size="large"
                                tabBarGutter={50}
                                onChange={callback}
                            >
                                <TabPane tab="手机号码找回" key="1" style={{ width: 400 }}>
                                    <Form
                                        form={formPhone}
                                        onFinish={onFinish}
                                    >

                                        <Form.Item
                                            label=""
                                            name='phone'
                                            rules={[
                                                { required: true, message: '请输入手机号！', },
                                                {
                                                    validator: (_, value) => {
                                                        if (value) {
                                                            if ((/^1[3456789]\d{9}$/.test(value))) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('请输入正确的手机号！'));
                                                        }
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input placeholder="请输入您的注册手机号" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name='phoneCode'
                                            rules={[{ required: true, message: '请输入验证码！', }]}
                                        >
                                            <Space>
                                                <Input
                                                    placeholder="手机验证码"
                                                    size="large"
                                                    autoComplete={'off'}
                                                    style={{ width: 280 }}
                                                />
                                                <Button
                                                    type='primary'
                                                    style={{ width: 112 }}
                                                    onClick={() => _getCaptcha()}
                                                    loading={loading}
                                                    size="large"
                                                    disabled={disableMsgBtn}
                                                >
                                                    {disableMsgBtn ? disableTime + '秒' : '获取验证码'}
                                                </Button>
                                            </Space>
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name='password'
                                            rules={[{ required: true, message: '请输入新密码！', },]}
                                        >
                                            <Input.Password placeholder="输入新密码" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name='passwordConfirm'
                                            rules={[{ required: true, message: '请输入确认密码！', },]}
                                        >
                                            <Input.Password placeholder="确认新密码" size="large" />
                                        </Form.Item>

                                        <Form.Item >
                                            <Button type="primary" htmlType="submit" style={{ width: '100%', height: 45 }}>
                                                确定
                                            </Button>
                                        </Form.Item>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div />
                                            <Button type="link" onClick={_goLogin}>直接登录</Button>
                                        </div>
                                    </Form>
                                </TabPane>


                                <TabPane tab="邮箱找回" key="2" style={{ width: 400 }}>
                                    <Form
                                        form={formEmail}
                                        onFinish={onFinish}
                                    >
                                        <Form.Item
                                            label=""
                                            name='email'
                                            rules={[
                                                { required: true, message: '请输入您的注册邮箱地址！', },
                                                {
                                                    validator: (_, value) => {
                                                        if (value) {
                                                            if ((/^([a-zA-Z]|[0-9])(\w|)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value))) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('请输入正确的邮箱号！'));
                                                        }
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input placeholder="请输入您的注册邮箱地址" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name='emailCode'
                                            rules={[{ required: true, message: '请输入验证码！', }]}
                                        >
                                            <Input.Search
                                                placeholder="邮箱验证码"
                                                enterButton={disableMsgBtn ? disableTime + '秒' : '获取验证码'}
                                                size="large"
                                                loading={loading}
                                                onSearch={_getSmsCode}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name='emailpassword'
                                            rules={[{ required: true, message: '请输入新密码！', },]}
                                        >
                                            <Input.Password placeholder="输入新密码" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name='emailpasswordConfirm'
                                            rules={[{ required: true, message: '请输入确认密码！', },]}
                                        >
                                            <Input.Password placeholder="确认新密码" size="large" />
                                        </Form.Item>

                                        <Form.Item >
                                            <Button type="primary" htmlType="submit" style={{ width: '100%', height: 45 }}>
                                                确定
                                            </Button>
                                        </Form.Item>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div />
                                            <Button type="link" onClick={_goLogin}>直接登录</Button>
                                        </div>

                                    </Form>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>

                    <VerifyModal
                        visible={verifyShow}
                        tokenVal={captchaVal}
                        onCancel={() => setVerifyShow(false)}
                        onOk={(v) => _getSmsCode(v)}
                        onRefresh={() => _getCaptcha()}
                    />

                    <p style={{ textAlign: 'center', margin: '150px 0 20px' }}>COPYRIGHT © 2021 程创科技出品 </p>
                </div>
            )}
        </Observer>
    )
}
