import axios from '@/utils/axios';
import _ from 'lodash';


//展示股票涨跌幅分析
export function showStockAnalysis(data) {
    return axios.post(global.api + '/signal/showStockAnalysis', data);
}
// 超大单信号
export function showTradingSignal(params) {
    return axios.get(global.api + '/signal/showTradingSignal', { params });
}
export function showSpecificTradingSignal(params) {
    return axios.get(global.api + '/signal/showSpecificTradingSignal', { params });
}

// 涨停监控
export function showLimitTradingSignal(params) {
    return axios.get(global.api + '/signal/showLimitTradingSignal', { params });
}
export function showLimitTradingSignalDuration(params) {
    return axios.get(global.api + '/signal/showLimitTradingSignalDuration', { params });
}
//最新消息
export function showNews(data) {
    return axios.post(global.api + '/signal/showNews', data);
}
//主力分析结果
export function showAnalysis(data) {
    return axios.post(global.api + '/mainFundsAnalysis/showAnalysis', data);
}
// 搜索股票
export function getAllStocks(txt) {
    return axios.get(global.api + '/stock/getAllStocks?search=' + txt);
}
//成本展示
export function showStockCost(data) {
    return axios.post(global.api + '/mainFundsAnalysis/showStockCost', data);
}
//指数
export function indexKlineDetail(data) {
    return axios.post(global.api + '/stock/indexKlineDetail', data);
}
// 板块信息
export function showIndexPlate(params) {
    return axios.get(global.api + `/signal/showIndexPlate`, { params });
}
//是否节假日
export function isAllStockHoliday(params) {
    return axios.post(global.api + '/signal/isAllStockHoliday', params);
}
//主力成本详情
export function showStockCostDetail(params) {
    return axios.post(global.api + '/mainFundsAnalysis/showStockCostDetail', params);
}
// k线
export function getKLineDetail(data) {
    return axios.post(global.api + '/stock/kLineDetail', data);
}
// 获取季度日期
export function getAvailableQuarter() {
    return axios.get(global.api + '/mainFundsAnalysis/getAvailableQuarter');
}
// 获取季度日期
export function getAllFund(txt) {
    return axios.get(global.api + '/mainFundsAnalysis/getAllFund?search=' + txt);
}
// 获取持仓信息
export function showFundHd(params) {
    return axios.post(global.api + '/mainFundsAnalysis/showFundHd', params);
}
// 获取成分股
export function commonIndex() {
    return axios.get(global.api + '/stock/commonIndex');
}
// 获取指定日期涨停票
export function showLimitStockInfo(params) {
    return axios.get(global.api + '/signal/showLimitStockInfo', { params });
}
// 获取持仓股信息
export function showStockAssets(params) {
    return axios.get(global.api + '/signal/showStockAssets', { params });
}
// 获取交易名单信息
export function showTradeList(params) {
    return axios.get(global.api + '/signal/showTradeList', { params });
}
// 更新股票交易限制
export function updateTradeType(params) {
    return axios.post(global.api + '/signal/updateTradeType', params);
}
// 添加股票交易名单
export function addTradeList(params) {
    return axios.post(global.api + '/signal/addTradeList', params);
}
// 添加股票交易名单
export function nextTradeDate() {
    return axios.get(global.api + '/signal/nextTradeDate');
}
// 报告-研报
export function getAllReportTypes() {
    return axios.get(global.api + '/signal/getAllReportTypes');
}
export function showReports(params) {
    return axios.post(global.api + '/signal/showReports', params);
}
export function showResearchReports(params) {
    return axios.post(global.api + '/signal/showResearchReports', params);
}
// 获取推送规则信息
export function getRuleInfo(id) {
    return axios.get(global.api + '/stock/getRuleInfo?uid=' + id);
}
// 推送交易信号指数饼状图
export function showTradingSignalPieChart(params) {
    return axios.get(global.api + '/signal/showTradingSignalPieChart', { params });
}
// 获取所有板块信息
export function getAllPlates() {
    return axios.get(global.api + '/stock/getAllPlates');
}
// 移动均线
export function getMovingAverage(params) {
    return axios.get(global.api + '/stock/getMovingAverage', { params });
}
// 移动均线
export function getAllPositionStock() {
    return axios.get(global.api + '/signal/getAllPositionStock');
}
export function showMovingAverageData(params) {
    return axios.post(global.api + '/signal/showMovingAverageData', params);
}
// 涨跌幅分布统计
export function getStockChgStatisticsInterval(params) {
    return axios.get(global.api + '/stock/getStockChgStatisticsInterval', { params });
}
export function getStockChgStatistics(params) {
    return axios.get(global.api + '/stock/getStockChgStatistics', { params });
}
// 板块推送信号统计
export function showPlateTradingSignal(params) {
    return axios.get(global.api + '/signal/showPlateTradingSignal', { params });
}
// 板块推送数据收益率曲线
export function showPlateGraph(params) {
    return axios.get(global.api + '/signal/showPlateGraph', { params });
}
// 策略运行周期信息获取
export function showStrategyRunning(params) {
    return axios.get(global.api + '/signal/showStrategyRunning', { params });
}
// 策略运行周期信息获取
export function showTradingRepeatNum(params) {
    return axios.get(global.api + '/signal/showTradingRepeatNum', { params });
}
// 推送回测
export function showBackStrategy(params) {
    return axios.get(global.api + '/signal/showBackStrategy', { params });
}
// 根据指数收益率范围筛选私募基金
export function fundFilter(params) {
    return axios.post(global.api + '/stock/fundFilter', params);
}
export function fundFilterByName(params) {
    return axios.post(global.api + '/stock/fundFilterByName', params);
}
// 指数分类
export function indexKlineSort(params) {
    return axios.post(global.api + '/stock/indexKlineSort', params);
}
//______________________成交量______________________
// 涨跌幅分布统计
export function getStockHistoryMaxVolumeByDate(params) {
    return axios.get(global.api + '/stock/getStockHistoryMaxVolumeByDate', { params });
}
export function getStockHistoryMaxVolumeBetweenDate(params) {
    return axios.get(global.api + '/stock/getStockHistoryMaxVolumeBetweenDate', { params });
}
//_____________________ T0 _____________________
// T0板块推送信号统计
export function showSellPositionRate(params) {
    return axios.post(global.api + '/signal/showSellPositionRate', params);
}