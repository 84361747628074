
import { simpleColumns, labelValues } from '@/utils/utils2';
import _ from 'lodash';
// 推送增加排序
const columnsPro = (cols = [], unsortKey = [], commomObj = {}) => {
    return cols.map(c => {
        const keys = c[1]
        let colObj = { ...commomObj };
        if (unsortKey.indexOf(keys) === -1) {// 不需要进行排序的字段
            colObj.sorter = (a, b) => a[keys] - b[keys];
            colObj.sortDirections = ['descend', 'ascend'];
        }
        return simpleColumns(c, colObj)
    })
}
// 日统计字段
const PUSH_KEYS_DAY = [
    { name: '涨跌', key: 'upDown', width: 90 },
    { name: '前五日最低到当前', key: 'preFiveDayLowToNow', width: 125 },
    { name: '前五日最低到最高', key: 'preFiveDayLowToUp', width: 125 },
    { name: '一天最高', key: 'oneDayHigh', width: 110 },
    { name: '一天最低', key: 'oneDayLow', width: 110 },
    { name: '一天收', key: 'oneDayClose', width: 110 },
    { name: '一天开', key: 'oneDayOpen', width: 110 },
    { name: '第二日最高', key: 'nextDayHigh', width: 110 },
    { name: '两日内最低', key: 'twoDayLow', width: 110 },
    { name: '第二日最低', key: 'nextDayLow', width: 110 },
    { name: '第二日收盘', key: 'nextDayClose', width: 110 },
    { name: '第二日开盘', key: 'nextDayOpen', width: 110 },
    { name: '三天内最高', key: 'threeDayHigh', width: 110 },
    { name: '第三日最高', key: 'thirdDayHigh', width: 110 },
    { name: '三日内最低', key: 'threeDayLow', width: 110 },
    { name: '第三日最低', key: 'thirdDayLow', width: 110 },
    { name: '第三日收盘', key: 'thirdDayClose', width: 110 },
    { name: '一日止盈', key: 'oneDayStopSurplus', width: 130 },
    { name: '一日止盈止损', key: 'oneDayStopSurplusAndLose', width: 130 },
    { name: '二日止盈', key: 'twoDayStopSurplus', width: 130 },
    { name: '二日止盈止损', key: 'twoDayStopSurplusAndLose', width: 130 },
    { name: '三日止盈', key: 'threeDayStopSurplus', width: 130 },
    { name: '三日止盈止损', key: 'threeDayStopSurplusAndLose', width: 130 },
];
const CHECKBOX_DAY = PUSH_KEYS_DAY.map(n => labelValues([n.name, n.key + 'AvgData'])); // 日checkbox选框
const pushCol = PUSH_KEYS_DAY.map(n => [n.name, n.key, n.width]);
const pushColumns = columnsPro([
    ['index', 'index', 60],
    ['代码', 'stockCode', 70],
    ['名称', 'stockName', 90],
    ['指数分类', 'type', 100],
    ['市值(亿)', 'market', 90],
    ['时间', 'pushDate', 115],
    ['买入均价', 'avgPrice', 105],
    ...pushCol,
],
    ['index', 'stockCode', 'stockName', 'pushDate', 'upDown', 'type'],
    { align: 'center' });
/**
 * 分钟统计字段拼接
   拼接原数据示例
"oneDayCloseAvgData30": 0.8438434711554216, //一日30收
  "oneDayCloseAvgDataMax30": 1.1414898365879518, //一日30之后最高
    "oneDayCloseAvgDataLow30": 0.5029802707951808, //一日30之后最低
"oneDayCloseAvgData35": 0.8329605329915662, //一日35收
  "oneDayCloseAvgDataMax35": 1.1087122019831326, //一日35之后最高
    "oneDayCloseAvgDataLow35": 0.5324427434228917, //一日35之后最低
    "oneDayCloseAvgData40": 0.8183409182048194, //一日40收
   "oneDayCloseAvgDataMax40": 1.0615587075240964, //一日40之后最高
      "oneDayCloseAvgDataLow40": 0.5425998278349398, //一日40之后最
    "oneDayCloseAvgData45": 0.8143228106951808, //一日45收
   "oneDayCloseAvgDataMax45": 1.0017642420108432, //一日45之后最高
"oneDayCloseAvgDataLow45": 0.5664021388903615, //一日45之后最低
...
共3日，4个时间点，3个统计未读
 */
let PUSH_MINUTE_TKEYS = []; let PUSH_MINUTE_CKEYS = []; // tkey=表格用的key，ckey=图表用的key
const push_min_obj = {
    'one': '一日', 'two': '二日', 'three': '三日',
    'DayClose': '收', 'DayCloseMax': '之后最高', 'DayCloseLow': '之后最低',
    'DayCloseAvgData': '收', 'DayCloseAvgDataMax': '之后最高', 'DayCloseAvgDataLow': '之后最低',
}
const push_minute = ['30', '35', '40', '45'];
const push_day = ['one', 'two', 'three'];
const push_item = ['DayClose', 'DayCloseMax', 'DayCloseLow'];
const push_item_chart = ['DayCloseAvgData', 'DayCloseAvgDataMax', 'DayCloseAvgDataLow'];
// 根据条件循环，拼接为最后结果
push_day.map(day => {
    PUSH_MINUTE_TKEYS.push({ key: _.camelCase(`--time-${day}--`), name: _.get(push_min_obj, day), width: 110 })
    push_minute.map(min => {
        push_item.map(item => {
            let pobj = {
                key: day + item + min,
                name: _.get(push_min_obj, day) + min + _.get(push_min_obj, item),
                width: _.size(_.get(push_min_obj, item)) > 1 ? 135 : 110
            }
            PUSH_MINUTE_TKEYS.push(pobj);
        });
        push_item_chart.map(item => {
            let pobj = {
                key: day + item + min,
                name: _.get(push_min_obj, day) + min + _.get(push_min_obj, item),
            }
            PUSH_MINUTE_CKEYS.push(labelValues([pobj.name, pobj.key]));
        });
    })
});
const minCol = PUSH_MINUTE_TKEYS.map(n => [n.name, n.key, n.width]);
const closeColumns = columnsPro([
    ['index', 'index', 60],
    ['代码', 'stockCode', 70],
    ['名称', 'stockName', 90],
    ['市值(亿)', 'market', 90],
    ...minCol,
],
    ['index', 'stockCode', 'stockName', 'timeOne', 'timeTwo', 'timeThree'],
    { align: 'center' });
// 板块
const PLATE_OPTIONS = [
    labelValues(['全部', 1]),
    labelValues(['主板', 2]),
    labelValues(['科创创业', 3])
];
/**
   动态策略数据，@ 最后需要替换成 vkey 所拿到的值 ；X 需要替换 key 所拿到的值； vobj 用获取到的数值显示对应的 value
*/
const RULES_DYM = [
    [
        { label: '成交量比@倍昨日平均成交量多X', key: 'basicConditionVolumePreAvgVolume', vkey: 'basicConditionVolumePreAvgVolumeArg', width: 280 },
        { label: '成交量比@倍前五日平均成交量的最大值多X', key: 'basicConditionVolumePre5MaxAvgVolume', vkey: 'basicConditionVolumePre5MaxAvgVolumeArg', width: 310 },
        { label: '一分钟涨跌幅比基础规则一分钟涨跌幅@%少X', key: 'basicConditionTempPctChg', vkey: 'basicConditionTempPctChgArg', width: 322 },
        { label: '当前涨跌幅比基础规则当前涨跌幅@%多X', key: 'basicConditionTotalPctChg', vkey: 'basicConditionTotalPctChgArg', width: 315 },
        { label: '昨日涨跌幅比基础规则昨日涨跌幅@%少X', key: 'basicConditionPrePctChg', vkey: 'basicConditionPrePctChgArg', width: 315 },
        { label: '前五日最低到最高涨跌幅比基础规则前五日最低到最高涨跌幅@%少X', key: 'basicConditionPre5daysMaxPctChg', vkey: 'basicConditionPre5daysMaxPctChgArg', width: 468 },
        { label: '今日开盘涨跌幅比基础规则今日开盘涨跌幅@%少X', key: 'basicConditionTodayOpenPctChg', vkey: 'basicConditionTodayOpenPctChgArg', width: 375 },
        { label: '前五日是否涨停过', key: 'basicConditionLast5DaysLimited', vobj: { 0: '否', 1: '是' }, width: 180 },
        { label: '大单以上笔数@(下限X)', key: 'basicConditionBigUpTrade', vkey: 'basicConditionBigUpTradeArg', width: 190 },
        { label: '5日累积涨跌幅比基础规则@%少X', key: 'basicConditionLast5DaysUpDown', vkey: 'basicConditionLast5DaysUpDownArg', width: 245 },
    ],
    [
        { label: '交易量条件', key: 'condition1TradeCondition', vobj: { 1: '一分钟交易笔数大于15', 2: '一分钟交易笔数在10-15笔之间' }, width: 160 },
        { label: '成交量比昨日最大成交量的@倍多X', key: 'condition1TotalVolumePreCondition', vkey: 'condition1TotalVolumePreConditionArg', width: 325 },
        { label: '成交量比前五日最大成交量的最大值的@倍多X', key: 'condition1TotalVolumePre5Condition', vkey: 'condition1TotalVolumePre5ConditionArg', width: 340 },
        { label: '一分钟涨跌幅比条件1一分钟涨跌@%多X', key: 'condition1TempPctChg', vkey: 'condition1TempPctChgArg', width: 285 },
        { label: '买入笔数1档条件阈值(@倍)', key: 'condition1FirstBuy', vkey: 'condition1FirstBuyArg', width: 235 },
        { label: '买入笔数2档条件阈值(@倍)', key: 'condition1SecondBuy', vkey: 'condition1SecondBuyArg', width: 235 }
    ],
    [
        { label: '成交量比昨日平均成交量的@倍多X', key: 'condition2TotalVolumePreAvgVolumeCondition', vkey: 'condition2TotalVolumePreAvgVolumeConditionArg', width: 260 },
        { label: '成交量比昨日最大成交量的@倍多X', key: 'condition2TotalVolumePreMaxVolumeCondition', vkey: 'condition2TotalVolumePreMaxVolumeConditionArg', width: 260 },
        { label: '成交量比昨日平均成交量的最大值的@倍多X', key: 'condition2TotalVolumePre5MaxAvgVolumeCondition', vkey: 'condition2TotalVolumePre5MaxAvgVolumeConditionArg', width: 310 },
        { label: '成交量比昨日最大成交量的最大值的@倍多X', key: 'condition2TotalVolumePre5MaxMaxVolumeCondition', vkey: 'condition2TotalVolumePre5MaxMaxVolumeConditionArg', width: 310 },
        { label: '一分钟涨跌幅比条件2一分钟涨跌幅@%多X', key: 'condition2TempPctChg', vkey: 'condition2TempPctChgArg', width: 310 }
    ],
    [
        { label: '成交量比昨日平均成交量的@倍多X', key: 'condition3TotalVolumePreAvgVolumeCondition', vkey: 'condition3TotalVolumePreAvgVolumeConditionArg', width: 265 },
        { label: '成交量比昨日最大成交量的@倍多X', key: 'condition3TotalVolumePreMaxVolumeCondition', vkey: 'condition3TotalVolumePreMaxVolumeConditionArg', width: 265 },
        { label: '成交量比昨日平均成交量的最大值的@倍多X', key: 'condition3TotalVolumePre5MaxAvgVolumeCondition', vkey: 'condition3TotalVolumePre5MaxAvgVolumeConditionArg', width: 310 },
        { label: '成交量比昨日最大成交量的最大值的@倍多X', key: 'condition3TotalVolumePre5MaxMaxVolumeCondition', vkey: 'condition3TotalVolumePre5MaxMaxVolumeConditionArg', width: 322 },
        { label: '一分钟涨跌幅比条件3一分钟涨跌幅@%多X', key: 'condition3TempPctChg', vkey: 'condition3TempPctChgArg', width: 310 }
    ]
];
const LABEL_ARRAY = ['基础条件', '条件1', '条件2', '条件3']; // 与 RULES_DYM 的数组顺序相同即可, 用于统计的 title 显示;
const gets = (n = {}, key = '', t = 0) => _.get(n, key, t);
const RULES_BASE = [
    { label: '一分钟成交量', key: 'totalVolume', width: 130 },
    { label: '昨日平均成交量', key: 'preAvgVolume', width: 130 },
    { label: '昨日最高成交量', key: 'preMaxVolume', width: 130 },
    { label: '前五日平均成交量的平均值', key: 'pre5DaysAvgAvgVolume', width: 210 },
    { label: '前五日平均成交量的最大值', key: 'pre5DaysMaxAvgVolume', width: 210 },
    { label: '前五日最大成交量的平均值', key: 'pre5DaysAvgMaxVolume', width: 210 },
    { label: '前五日最大成交量的最大值', key: 'pre5DaysMaxMaxVolume', width: 210 },
    { label: '一分钟涨跌幅', key: 'tempPctChange', width: 120 },
    { label: '当前涨跌幅', key: 'totalPctChange', width: 120 },
    { label: '昨日涨跌幅', key: 'prePctChg', width: 120 },
    { label: '前五日最低到最高涨跌幅', key: 'pre5DaysMaxPctChg', width: 200 },
    { label: '今日开盘涨跌幅', key: 'todayOpenPctChg', width: 130 },
    { label: '一分钟交易笔数', key: 'totalTrade', width: 130 },
    { label: '条件(1/2/3)', key: 'condition', width: 110 },
];

const BASE_GRID = { left: '8%', right: '6%', bottom: '10%', top: '10%' };
const INDEX_SORT_OPTIONS = {
    legend: {},
    tooltip: {},
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value' },
    series: [{ type: 'bar', data: [], label: { show: true, position: 'inside' } }],
    grid: BASE_GRID,
};

export {
    columnsPro,
    CHECKBOX_DAY,
    PUSH_MINUTE_CKEYS,
    PLATE_OPTIONS,
    pushColumns, closeColumns,
    RULES_DYM, LABEL_ARRAY, gets, RULES_BASE,
    INDEX_SORT_OPTIONS
}