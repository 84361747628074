import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { Route, Link, Redirect } from "react-router-dom"; //Switch, 
import { KeepaliveRouterSwitch, KeepaliveRoute } from 'react-keepalive-router'
import { Layout, Menu, Space, Avatar, Dropdown, Image, Row, Col } from 'antd';
import { routeConfig } from '@/router/route.config';
import logoCN from "@/images/logoEN.svg";
import {
	UserOutlined, DownOutlined, RadiusBottomleftOutlined, BorderHorizontalOutlined, BorderOuterOutlined,
	ControlOutlined, MenuFoldOutlined, MenuUnfoldOutlined, InsertRowBelowOutlined, StockOutlined,
	FileSearchOutlined, GroupOutlined
} from '@ant-design/icons';
import { useMount } from 'ahooks';
import { isBrowser } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import '../../colors.less';

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;

const fundManagerItem = [
	{ key: '1', link: '/strategy/alpha', pms: { key: "1", from: 'home' }, name: '个股Alpha增强', icon: <BorderHorizontalOutlined /> },
	{ key: '2', link: '/strategy/tzero', pms: { key: "2" }, name: 'T+0指数增强', icon: <RadiusBottomleftOutlined /> },
	{ key: '3', link: '/strategy/volume', pms: { key: "3" }, name: '最大成交量', icon: <InsertRowBelowOutlined /> },
	{ key: '4', link: '/strategy/alphaIndex', pms: { key: "4" }, name: 'Alpha择时', icon: <StockOutlined /> },
	{ key: '5', link: '/strategy/pushtestback', pms: { key: "5" }, name: 'Alpha推送回测', icon: <BorderOuterOutlined /> },
	{ key: '6', link: '/strategy/searchFund', pms: { key: "6" }, name: '私募搜索', icon: <FileSearchOutlined /> },
	{ key: '7', link: '/strategy/indexsort', pms: { key: "7" }, name: 'Alpha指数分类', icon: <GroupOutlined /> },
];

export default function Home(props) {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	const mobxStore = useLocalObservable(() => store);
	const [collapsed, setcollapsed] = useState(isMobile ? true : false);
	const [menuKey, setmenuKey] = useState(localStorage.getItem('menuKey') || '1');
	const [axiosSource] = useState(axios.CancelToken.source());

	useEffect(() => {
		// console.log(22, mobxStore.userInfo.role);
		// console.log(111, JSON.parse(JSON.stringify(mobxStore.userInfo)));
		if (!mobxStore.userLogin) {
			_getOut();
			axiosSource.cancel();
		}
	}, [props, mobxStore]);

	useMount(() => {
		mobxStore._getAllTradeDay();
	});

	function handleClick(e) {
		localStorage.setItem('menuKey', e.key);
		setmenuKey(e.key);
	}

	function _getOut() {
		mobxStore.cancelLogin();
		mobxStore.changeStockAnalysis({});
		mobxStore.changeAssetsData({});
		mobxStore.changeAssetIndex({});
		mobxStore.changeIndexCache({});
		mobxStore.changePushInfo({});
		props.history.replace('/login');
	}

	function checkRole(role) {
		return mobxStore.userInfo.role === role ? true : false
	}

	const menu = (
		<Menu
			triggerSubMenuAction='click'
			onClick={(e) => {
				if (e.key === '1') {
					_getOut();
				}
			}}>
			<Menu.Item key={'1'} danger>退出登录</Menu.Item>
		</Menu>
	);

	const renderLinkItem = (name, link, state) => {
		return <Link to={{ 'pathname': link, 'state': state }}>{name}</Link>
	};

	const iconStyle = { fontSize: 16, color: 'white' };
	const accountName = _.get(mobxStore, 'userInfo.account', 'User');
	const renderAccountName = _.size(accountName) > 7 ? accountName.substring(0, 7) + '...' : accountName;

	return (
		<Observer>
			{() => (
				<Layout style={{ minHeight: '100vh' }}>
					{/* <Link to={"/strategy/fundManagerNews"}>消息列表</Link> */}
					<Layout>
						<Sider
							trigger={null}
							collapsible
							collapsed={collapsed}
							width={180}
							breakpoint="lg"
							collapsedWidth="0"
							onBreakpoint={broken => {
								//console.log('broken', broken);
								if (isBrowser) {
									setcollapsed(broken)
								}
							}}
						>
							<div>
								<Avatar src={
									<Image
										src={logoCN}
										style={{ width: 163, height: 49, paddingTop: 20 }}
										preview={false}
									/>}
								/>
							</div>
							<Menu
								theme='dark'
								onClick={handleClick}
								defaultOpenKeys={['1', '2']}
								selectedKeys={[menuKey]}
								mode="inline"
							>
								{checkRole('fund_manager') &&
									<SubMenu key="2" icon={<ControlOutlined />} title={"策略分析"}>
										{fundManagerItem.map(item => <Menu.Item key={item.key} icon={item.icon}>
											{renderLinkItem(item.name, item.link, item.pms)}
										</Menu.Item>)}
									</SubMenu>}
							</Menu>
						</Sider>

						<Content>
							<Header className="site-layout-background" style={{ padding: 0 }}>
								<Row justify='space-between'>
									<Col span={2} style={{ fontSize: 16, paddingLeft: 10 }}>
										{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
											className: 'trigger',
											style: { color: 'white' },
											onClick: () => setcollapsed(!collapsed),
										})}
									</Col>
									<Col xxl={12} xl={12} lg={18} md={22} sm={22} style={{ textAlign: 'right', paddingRight: 25 }}>
										<Space>
											<div style={{ lineHeight: '31px' }}>
												<Space>
													<div style={{ width: '33px' }}>
														<UserOutlined style={iconStyle} />
													</div>
													<Dropdown overlay={menu} trigger={['click']}>
														<a style={{ color: 'white' }}>
															{renderAccountName} <DownOutlined />
														</a>
													</Dropdown>
												</Space>
											</div>
										</Space>
									</Col>
								</Row>

							</Header>
							<KeepaliveRouterSwitch>
								{
									routeConfig[5].routes.map((item, index) => {
										if (item.path === "/strategy/alpha") {
											return <KeepaliveRoute key={menuKey + index} path={item.path} component={item.component} ></KeepaliveRoute>
										} else {
											return <Route key={menuKey + index} path={item.path} component={item.component} />
										}
									})
								}
								{
									checkRole('fund_manager') &&
									<Redirect to={{
										pathname: "/strategy/alpha",
										state: { key: "1", from: 'redirect_home' }
									}} />
								}
							</KeepaliveRouterSwitch>
						</Content>

					</Layout>
				</Layout>
			)}
		</Observer >
	)
}