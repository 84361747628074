import React, { useState } from 'react';
import { Col, Row, PageHeader } from 'antd';
import { withRouter } from "react-router-dom";
import { useLocalObservable } from 'mobx-react';
import { getStockHistoryMaxVolumeByDate, getStockHistoryMaxVolumeBetweenDate } from '@/api/stock';
import { store } from '@/store/mobx';
import { autoCol, validateResponse } from '@/utils/utils';
import { useMount } from 'ahooks';
import HistoryTable from './Components/HistoryTable';
import moment from 'moment';
import _ from 'lodash';
import './volume.scss'

const TODAY = moment().format('YYYY-MM-DD');

export default withRouter(function VolumePage(props) {
  const mobxStore = useLocalObservable(() => store);
  const [volParams, setVolParams] = useState({ date: TODAY + ' 00:00:00' });
  const [historyDatas, setHistoryDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [upcount, setUpcount] = useState(0);

  useMount(() => {
    _getStockHistoryMaxVolumeByDate();
  });

  async function _getStockHistoryMaxVolumeByDate(date) {
    setLoading(true);
    let params = {
      date: date ? date + ' 00:00:00' : volParams.date,
    };
    setVolParams(params);
    const res = await getStockHistoryMaxVolumeByDate(params);
    if (validateResponse(res)) {
      const getDatas = _.get(res, 'data', []);
      setHistoryDatas(getDatas.map(n => {
        return { ...n, historyMaxVolume: _.get(n, 'historyMaxVolume') ?? 0 }
      }));
      setUpcount(_.round(upcount + 0.1, 1))
    }
    setLoading(false);
  }

  return <>
    <PageHeader
      title={'最大成交量'}
      style={{ backgroundColor: 'white' }}
    ></PageHeader>

    <div className="contentView" id="volume">
      <HistoryTable
        todayFormat={TODAY}
        loading={loading}
        updateCount={upcount}
        datas={historyDatas}
        onTimeChange={_getStockHistoryMaxVolumeByDate}
      />
    </div>
  </>
})

