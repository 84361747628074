import React, { useState } from 'react';
import { PageHeader, Tabs } from 'antd';
import PushOne from './push/PushOne';
import PushTwo from './push/PushTwo';
import _ from 'lodash';
import './pages.scss';
/**
 *  推送详情分析页，包含均线及移动均线计算;
 */
const PushPage = (props) => {
  const [active, setActive] = useState('A');

  return <>
    <PageHeader
      title={'推送详情'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
      onBack={() => props.history?.goBack()}
    ></PageHeader>

    <div className='pageTabs'>
      <Tabs
        defaultActiveKey={'A'}
        size='small'
        type='card'
        activeKey={active}
        onChange={(activeKey) => {
          setActive(activeKey);
        }}
      >
        <Tabs.TabPane key={'A'} tab='分析'>
          <PushOne routeType={_.get(props, 'location.state.type', 0)} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'B'} tab='动态'>
          <PushTwo routeType={_.get(props, 'location.state.type', 0)} />
        </Tabs.TabPane>
      </Tabs >
    </div>

  </>


}

export default PushPage;