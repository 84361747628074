import React, { useState } from 'react';
import { Typography, Tabs, Row, Col, Select, Space, Segmented, Button } from 'antd';
import { FileExcelOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { showMovingAverageData } from '@/api/stock';
import { validateResponse, SyncButton } from '@/utils/utils';
import { isValidObj, isValidString, isValidNumber, typeValueOptions, simpleColumns } from '@/utils/utils2';
import { useMount, useReactive, useUnmount } from 'ahooks';
import { PLATE_OPTIONS, pushColumns, closeColumns, RULES_DYM, LABEL_ARRAY, gets, RULES_BASE } from '../putil';
import { NewRangePicker } from '@/view/common/widgets';
import { PushTable, CloseTables } from './Tables';
import AvgCharts from './AvgCharts';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import _ from 'lodash';

const TODAY_FORMAT = "YYYY-MM-DD";
const TODAY = moment().format(TODAY_FORMAT);
const RANGE_DATE = [moment().subtract(7, 'd').format(TODAY_FORMAT), TODAY];
const DEFAULT_AVG_NUM = 5;
const dymBaseCol = RULES_BASE.map(n => {
  return {
    ...simpleColumns([n.label, n.key, n.width]),
    sorter: (a, b) => a[n.key] - b[n.key],
    sortDirections: ['descend', 'ascend'],
  }
});

const PushOne = ({ routeType }) => {
  const [active, setActive] = useState('p1');
  const oneState = useReactive({
    date: TODAY, spin: false, exSpin: false, signalType: '', avgNum: DEFAULT_AVG_NUM,
    dataType: 1, boardType: 1,//区分板块
  });
  const [pushDatas, setPushDatas] = useState({}); // 常规推送分析数据
  const [cpushs, setCPushs] = useState({}); // 收盘数据
  const [dymCol, setDymCol] = useState([]); // 动态表头数据
  const [cdymCol, setCDymCol] = useState([]);
  const [dates, setDates] = useState(RANGE_DATE);
  const [upcount, setUpcount] = useState(0);
  let isUnmont = false;

  useMount(() => {
    if (isValidNumber(routeType)) {
      oneState.signalType = routeType;
      _showMovingAverageData();
    }
  });

  useUnmount(() => {
    isUnmont = true;
  });
  // 获取移动均线
  async function _showMovingAverageData(date) {
    oneState.spin = true;
    let params = {
      'step': oneState.avgNum,
      'start': date ? date[0] : dates[0],
      'end': date ? date[1] : dates[1],
      'type': oneState.signalType,
      'dataType': oneState.dataType, // 区分常规及收盘数据的type
      'boardType': oneState.boardType
    }
    const res = await showMovingAverageData(params);
    // console.log(res)
    if (validateResponse(res, isUnmont)) {
      const getAvgData = _.get(res, 'data.avgData', {});
      const getBasic = _.get(res, 'data.basicData', {});
      let timeOrder = []; let tables = [];
      if (isValidObj(getAvgData)) {
        // 创建时间order数据；获取日期包含获取日期前{step}个交易日数据；
        _.keys(getAvgData).map(dateKey => { // order>0 在获取日期之前，否则是在日期之后；=0 为当天
          timeOrder.push({ 'date': dateKey, 'order': moment(params.start).diff(moment(dateKey), 'd') });
        });
        const newOrders = _.orderBy(timeOrder, ['order'], ['desc']); // 排序，时间早至后来时间
        let counter = 0;
        //表格数据只需要获取日期数据即可
        _.filter(newOrders, o => o.order <= 0).map(n => {
          const items = _.get(getBasic, n.date, [])
          tables = _.concat(tables, items.map(n => {
            counter++;
            let newItem = _.assign(n, {
              'index': counter,
              'market': _.round(parseFloat(_.get(n, 'market', 0)), 2)
            }, _.get(n, 'rollingData', {}));
            // 精简时间格式
            ['timeOne', 'timeTwo', 'timeThree'].map(k => {
              if (k in n && isValidString(_.get(n, k))) {
                newItem[k] = moment(_.get(n, k)).format('MM-DD HH:mm');
              }
            })
            return newItem;
          }));
        });
        let finals = {
          'avg': getAvgData,
          'basic': getBasic,
          'tables': tables,
          'timeOrder': newOrders,
          'boardType': params.boardType,
          'startObj': {
            'sdate': params.start,
            'skey': _.findIndex(newOrders, o => o.date === params.start) // 开始日期在timeOrder内的index值
          }
        }
        if (params.dataType === 1) {
          setPushDatas(finals);
          setDymCol(createDymCol(_.get(res, 'data.strategyArgs', {})));
        } else {
          setCPushs(finals);
          setCDymCol(createDymCol(_.get(res, 'data.strategyArgs', {})));
        }
      }
    }
    setUpcount(_.round(upcount + 0.1, 1));
    oneState.spin = false;
  }
  function createDymCol(strategyArgs) {
    let columns_dym = []
    RULES_DYM.map((array, i) => {
      columns_dym.push({  // 每组增加一个标题头部，区分条件分隔
        ...simpleColumns([LABEL_ARRAY[i], LABEL_ARRAY[i], 90]),
        render: (text, record) => _.get(record, 'condition', null) === i ? <CheckCircleOutlined style={{ color: '#1890ff' }} /> : ''
      })
      array.map(n => {
        const newLabel = 'vkey' in n ? _.replace(n.label, '@', gets(strategyArgs, n.vkey)) : n.label; // 替换 @ 显示动态常量表头..
        let sortCol = {
          sorter: (a, b) => a[n.key] - b[n.key],
          sortDirections: ['descend', 'ascend'],
        };
        let baseCol = {
          ...simpleColumns([newLabel, n.key, n.width]),
          render: (text, record) => {
            if (!text) { // 无数据返回 空字符串，保证排序时不会因空数据而失效；
              return '';
            }
            let newText = 'vobj' in n ? n['vobj'][text] : text; // vobj 通过返回值显示固定显示内容，直接从object里面获取
            return _.get(record, 'condition', null) === i ? <Typography.Text strong style={{ color: '#1890ff' }}>{newText}</Typography.Text> : newText;
          }
        }
        // vkey字段返回都是Number类型，可排序，其他则可不必排序
        columns_dym.push('vkey' in n ? _.assign(baseCol, sortCol) : baseCol);
      });
    });
    return columns_dym;
  }
  // 导出推送分析数据
  function exportExcel() {
    oneState.exSpin = true;
    let option = {}; let datas = [];
    const exportData = active === 'p1' ? _.get(pushDatas, 'tables', []) : _.get(cpushs, 'tables', []);
    const newCols = active === 'p1' ? _.concat(pushColumns, dymBaseCol, dymCol) : _.concat(closeColumns, dymBaseCol, cdymCol);
    const sheet_header = newCols.map(n => n.title);
    exportData.map(n => {
      let exObj = {};
      newCols.map(c => {
        _.set(exObj, c.title, _.get(n, c.key, ''));
      })
      datas.push(exObj);
    });
    const fileNameObj = { 'p1': `推送统计`, 'p2': `分钟推送统计` };
    option.fileName = fileNameObj[active] || 'file';
    option.datas = [
      {
        sheetData: datas,
        sheetName: 'sheet',
        sheetFilter: sheet_header,
        sheetHeader: sheet_header,
        // columnWidths: newCols.map(n => String(_.round((n.width / 10) - 3)))
      }
    ]
    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
    oneState.exSpin = false;
  }

  const push_table = _.get(pushDatas, 'tables', []);
  const cpush_table = _.get(cpushs, 'tables', []);
  const export_dis = active === 'p1' ? _.size(push_table) === 0 : _.size(cpush_table) === 0;

  return (
    <>
      <Row style={{ marginBottom: 12 }} align='middle'>
        <Col span={24}>
          <Space>
            <Select style={{ width: 155 }} value={oneState.signalType}
              options={typeValueOptions}
              onChange={(val) => {
                oneState.signalType = val;
                _showMovingAverageData();
              }} />
            <Segmented options={PLATE_OPTIONS} value={oneState.boardType}
              onChange={(v) => {
                oneState.boardType = v;
                _showMovingAverageData();
              }}
            />
            <NewRangePicker
              dates={dates}
              onSelect={(dateStrings) => {
                setDates(dateStrings);
                _showMovingAverageData(dateStrings);
              }}
            />
            <SyncButton onClick={() => _showMovingAverageData()} />
          </Space>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey={'p1'}
        size='small'
        activeKey={active}
        tabBarExtraContent={{
          right: active !== 'p3' ?
            <Button icon={<FileExcelOutlined />}
              size='small'
              disabled={export_dis}
              onClick={exportExcel}
            >
              导出
            </Button> : <></>
        }}
        onChange={(activeKey) => {
          setActive(activeKey);
          let is_get = false;
          // 切换且空数据，需获取数据
          if (activeKey === 'p2') {
            oneState.dataType = 2;
            is_get = !isValidObj(cpushs) ? true : false;
          } else if (activeKey === 'p1') {
            oneState.dataType = 1;
            is_get = !isValidObj(pushDatas) ? true : false;
          }
          if (is_get) {
            _showMovingAverageData();
          }
        }}
      >
        <Tabs.TabPane key={'p1'} tab='推送'>
          <PushTable
            datas={push_table}
            newCol={_.concat(pushColumns, dymBaseCol, dymCol)}
            spining={oneState.spin}
            goCharts={() => setActive('p3')}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={'p2'} tab='分钟'>
          <CloseTables
            datas={cpush_table}
            newCol={_.concat(closeColumns, dymBaseCol, cdymCol)}
            spining={oneState.spin}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={'p3'} tab='均线' >
          <AvgCharts
            updateCount={upcount}
            loading={oneState.spin}
            defaultAvgNum={DEFAULT_AVG_NUM}
            datas={pushDatas}
            cdatas={cpushs}
            onAvgNumChange={(num) => {
              oneState.avgNum = num;
              _showMovingAverageData();
            }}
            onGetCpush={() => {
              oneState.dataType = 2;
              _showMovingAverageData();
            }}
          />
        </Tabs.TabPane>
      </Tabs >
    </>
  )
}

export default PushOne;