import React, { useEffect, useState } from 'react';
import { Table, Space, Typography, Spin } from 'antd';
import { EXTRA_OPTIONS } from '../Components/sutil';
import { simpleColumns, isValidArray, isValidString } from '@/utils/utils2';
import { RateText } from '@/utils/utils';
import * as echarts from 'echarts';
import _ from 'lodash';

const { Text } = Typography;
let timer = null;

export default function PlateCharts(props) {
  const [option, setoption] = useState(EXTRA_OPTIONS.plateBar);
  const [plateItems, setPlateItems] = useState({});

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('plate_bar'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = _.cloneDeep(option);

    const getFullPlates = _.get(props, 'plates.fullPlates', {});
    const getXdata = _.get(props, 'plates.xdatas', []);
    newOption.xAxis.data = getXdata;
    newOption.series[0].data = _.get(props, 'plates.sdata', []);
    let firstPlate = _.head(getXdata);
    handlePlateItem(firstPlate, _.get(getFullPlates, `${firstPlate}.plateAnalysis`));

    //console.log('newOption', newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();

    myChart.on('click', 'series.bar', (param) => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        //点击找到对应范围，并更新table数据
        const plate_name = _.get(param, 'name', '');
        const plate_data = _.get(props, `plates.fullPlates.${plate_name}.plateAnalysis`, []);
        handlePlateItem(plate_name, plate_data);
      }, 500);
    });

  }, [props.updateCount]);
  // 全部板块table点击切换，更新表格数据
  useEffect(() => {
    const getFullPlates = _.get(props, 'plates.fullPlates', {});
    const getXdata = _.get(props, 'plates.xdatas', []);
    const getPropsSelect = _.get(props, 'plateSelect');
    let pindex = isValidArray(getXdata) && isValidString(getPropsSelect) ? getXdata.indexOf(getPropsSelect) : -1;
    if (pindex !== -1) {
      handlePlateItem(getXdata[pindex], _.get(getFullPlates, `${getXdata[pindex]}.plateAnalysis`));
    }
  }, [props.updateCount2]);
  // 赋值表格
  function handlePlateItem(name, datas) {
    if (isValidArray(datas)) {
      let sum = 0;
      datas.map(n => {
        sum = sum + _.get(n, 'costRate', 0);
      });
      setPlateItems({
        'datas': datas,
        'avg': _.round(sum / _.size(datas), 4),
        'name': name,
        'size': _.size(datas)
      });
    }
  }

  const columns = [
    simpleColumns(['名称', 'name']),
    simpleColumns(['代码', 'symbol']),
    simpleColumns(['推送时间', 'time']),
    {
      ...simpleColumns(['推送价', 'price']),
      render: (text) => <Text strong>{text}</Text>
    },
    {
      ...simpleColumns(['建仓收益率', 'costRate']),
      sorter: (a, b) => a.costRate - b.costRate,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <RateText rate={text} carry={4} />
    },
  ];

  return (
    <>
      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex', marginTop: 35, marginBottom: 12 }}>
          <div
            id="plate_bar"
            style={{
              width: '98%',
              height: 255
            }}
          />
        </div>
      </Spin>

      <Table
        rowKey={'uid'}
        dataSource={_.get(plateItems, 'datas', [])}
        columns={columns}
        title={() => <Space size='small'>
          <Text strong>{_.get(plateItems, 'name', '')}</Text>
          <Text>{_.get(plateItems, 'size', '')}</Text>
          <Text type='secondary'>建仓平均收益率:</Text>
          <RateText rate={_.get(plateItems, 'avg', '')} carry={4} />
        </Space>}
        size='small'
        scroll={{ y: 260 }}
        pagination={false}
      />
    </>
  )
}