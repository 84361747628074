import React, { useState } from 'react';
import { PageHeader, Row, Col, Card, Select, Space, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { showTradingRepeatNum } from '@/api/stock';
import { validateResponse } from '@/utils/utils';
import { isValidObj, typeValueOptions, labelValues } from '@/utils/utils2';
import { useMount, useReactive } from 'ahooks';
import { NewRangePicker, NewDivider } from '@/view/common/widgets';
import RepeatCharts from './repeat/RepeatCharts';
import moment from 'moment';
import _ from 'lodash';

const TODAY_FORMAT = "YYYY-MM-DD";
const TODAY = moment().format(TODAY_FORMAT);
const RANGE_DATE = [moment().subtract(30, 'd').format(TODAY_FORMAT), TODAY];
const NUM_OPTIONS = ['25', '50', '65', '70', '100', '200', '400'].map(n => labelValues([n, n]));
// 推送重复次数
const RepeatNum = (props) => {
  const pageState = useReactive({
    signalType: [], loading: false, num: '25'
  });
  const [dates, setDates] = useState(RANGE_DATE);
  const [repeats, setRepeats] = useState({});
  const [update, setUpdate] = useState(0);
  // 路由带入推送type，匹配直接加载
  useMount(() => {
    const routeDatas = _.get(props, 'location.state', {});
    //console.log(routeDatas);
    const getSignalType = _.get(routeDatas, 'type', '');
    if (_.findIndex(typeValueOptions, o => o.value === getSignalType) !== -1) {
      pageState.signalType = [getSignalType];
      _showTradingRepeatNum();
    }
  });

  async function _showTradingRepeatNum() {
    pageState.loading = true;
    let params = {
      'start': dates[0] + ' 00:00:00',
      'end': dates[1] + ' 00:00:00',
      'types': _.join(pageState.signalType, ','),
      'num': pageState.num
    };
    const res = await showTradingRepeatNum(params);
    if (validateResponse(res)) {
      const getData = _.get(res, 'data', {});
      if (isValidObj(getData)) {
        let repeatsOrder = [];
        // 根据返回日期排序时间
        _.keys(getData).map(dateKey => {
          repeatsOrder.push({ 'date': dateKey, 'order': moment().diff(moment(dateKey), 'd'), 'value': getData[dateKey] });
        });
        const newRepeat = _.orderBy(repeatsOrder, ['order'], ['desc']);
        setRepeats(newRepeat);
      }
    }
    pageState.loading = false;
    setUpdate(update + 1);
  }

  return <>
    <PageHeader
      title={'推送重复次数'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
      onBack={() => props.history?.goBack()}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }} style={{ minHeight: 605 }}>
      <Row style={{ marginBottom: 8 }} align='middle'>
        <Col span={24}>
          <Space>
            <Select style={{ width: 305 }} mode='multiple'
              value={pageState.signalType}
              options={typeValueOptions}
              onChange={(val) => pageState.signalType = val}
            />
            <Select style={{ width: 125 }}
              value={pageState.num}
              options={NUM_OPTIONS}
              onChange={(val) => pageState.num = val}
            />
            <NewRangePicker
              dates={dates}
              onSelect={(dateStrings) => {
                setDates(dateStrings)
              }}
            />
            <Button
              icon={<CheckCircleOutlined />}
              type='primary'
              onClick={() => _showTradingRepeatNum()}>
              {'确定'}
            </Button>
          </Space>
        </Col>
      </Row>

      <NewDivider />

      <RepeatCharts
        datas={repeats}
        loading={pageState.loading}
        updateCount={update}
      />
    </Card>
  </>
}

export default RepeatNum;