
import React, { useState } from 'react';
import { Table, Typography, Space, Select, Row, Col, Button, Segmented, Divider } from 'antd';
import { LeftOutlined, RightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useUpdateEffect, useReactive } from 'ahooks';
import { createSingleColumns, RateText } from '@/utils/utils';
import { isValidObj, labelValues } from '@/utils/utils2';
import _ from 'lodash';
import moment from 'moment';

const { Text } = Typography;
const PUSH_TYPE = [labelValues(['推送', 'push']), labelValues(['建仓', 'costPush']), labelValues(['收盘', 'closePush'])];
// 板块筛选表格
const AlPlateTable = ({ update = 0, dataOrder = [], cusNames = '', show = false }) => {
  const [singleData, setSingleData] = useState([]);
  const [allPlate, setAllPlate] = useState([]);
  const [date, setDate] = useState('');
  const [sortVal, setSortVal] = useState('');
  const [dateOptions, setDateOptions] = useState([]);
  const plState = useReactive({ type: 'push', page: 1 });
  // 【tips】 上层如使用卸载该组件，再次切换该effect不更新，故使用show在组建内部进行不显示操作
  useUpdateEffect(() => {
    const firstData = _.head(dataOrder)
    const initDate = _.get(firstData, 'date');
    //  orderData = 时间排序后数据 -> rate:未当日返回数据 -> push\costPush: 包含板块数据 -> 板块名称: [推送记录] 
    setDate(initDate);
    extractData(_.get(firstData, 'rate', {}));
    setDateOptions(dataOrder.map(n => labelValues([n.date, n.date])));
    setSortVal('');
  }, [update]);

  const columns = [
    createSingleColumns(['代码', 'symbol']),
    createSingleColumns(['股票', 'name']),
    {
      ...createSingleColumns(['行业板块', 'other']),
      render: (text, record) => `${record.market ?? ''} - ${record.industry ?? ''} - ${record.plate ?? ''}`
    },
    {
      ...createSingleColumns(['收益率', 'rate']),
      sorter: (a, b) => a.rate - b.rate,
      render: (text) => <RateText rate={text} />
    },
    {
      ...createSingleColumns(['建仓收益率', 'costRate']),
      sorter: (a, b) => a.costRate - b.costRate,
      render: (text) => <RateText rate={text * 100} />
    },
    {
      ...createSingleColumns(['时间', 'time']),
      sorter: (a, b) => a.timeOrder - b.timeOrder,
    },
  ];
  // 解析数据
  function extractData(data) {
    const getPush = _.get(data, plState.type, {});
    if (isValidObj(getPush)) {
      let plates = []; let push = [];
      let plateMap = new Map(); let count = 1;
      let plateKey = 0;
      let keyCount = 0;
      _.keys(getPush).map(plate => {
        // 区分板块
        if (!plateMap.has(plate)) {
          plateMap.set(plate, count);
          plateKey = count;
          count++;
        } else if (plateMap.has(plate)) {
          plateKey = plateMap.get(plate);
        };
        const plateItem = _.get(getPush, plate, []);
        push = _.concat(push, plateItem.map(n => {
          keyCount++;
          return _.assign(n, {
            'plkey': plateKey,
            'newKey': n.uid + keyCount,
            'timeOrder': moment(`${n.date} ${n.time}`).valueOf()
          });
        }));
        plates.push({ 'plname': plate, 'key': plateKey });
      });
      setAllPlate(plates);
      setSingleData(push);
    }
  }
  // 切换日期
  function onDateChange(val) {
    let findex = -1;
    if (val === 'left') {
      findex = _.findIndex(dataOrder, o => o.date === date);
      if (findex > 0) {
        findex -= 1;
        setDate(dataOrder[findex].date);
      } else {
        return
      };
    } else if (val === 'right') {
      findex = _.findIndex(dataOrder, o => o.date === date);
      if (findex < _.size(dataOrder) - 1) {
        findex += 1;
        setDate(dataOrder[findex].date);
      } else {
        return
      };
    } else {
      findex = _.findIndex(dataOrder, o => o.date === val);
    };
    if (findex !== -1) {
      extractData(_.get(dataOrder[findex], 'rate'));
    }
  };

  const btn_props = { type: 'text', size: 'small' };
  return show ? (
    <div style={{ marginTop: 12 }}>
      <Row style={{ marginBottom: 8 }}>
        <Col span={10}>
          <Space>
            <Button {...btn_props} icon={<LeftOutlined />} onClick={() => onDateChange('left')} />
            <Select style={{ width: 155 }} value={date} size='small'
              options={dateOptions}
              onChange={(val) => {
                setDate(val);
                onDateChange(val);
                setSortVal('');
              }} />
            <Button {...btn_props} icon={<RightOutlined />} onClick={() => onDateChange('right')} />
            <Divider type='vertical' />
            <Segmented options={PUSH_TYPE} size='small' value={plState.type}
              onChange={v => {
                plState.type = v;
                onDateChange(date);
                setSortVal('');
              }}
            />
          </Space>
        </Col>
        <Col span={14}>
          <div className='alphaPlateTxtSort'>
            {allPlate.map(plate => {
              const getkey = _.get(plate, 'key');
              const isChoose = sortVal === getkey ? true : false;
              return <div style={{ cursor: 'pointer', marginRight: 4 }} onClick={() => {
                if (!isChoose) {
                  setSortVal(getkey);
                  let newOrder = [];
                  singleData.map(n => {
                    if (n.plkey === getkey) {
                      newOrder.unshift(n);
                    } else {
                      newOrder.push(n);
                    }
                  });
                  plState.page = 1;
                  setSingleData(newOrder);
                }
              }}>
                <Text type={isChoose ? 'default' : 'secondary'}>{plate.plname}</Text>
                {isChoose && <CaretDownOutlined />}
              </div>
            })}
          </div>
        </Col>
      </Row>

      <Table
        rowKey={'newKey'}
        dataSource={singleData}
        columns={columns}
        footer={() => <Text>{cusNames}</Text>}
        size='small'
        pagination={{
          pageSize: 10,
          simple: true,
          current: plState.page,
          onChange: (page) => plState.page = page
        }}
      />
    </div>
  ) : <></>
};

export default AlPlateTable;