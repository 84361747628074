import Login from '@/view/Login/Login';
import Register from '@/view/Login/Register';
import ForgetPassword from '@/view/Login/ForgetPassword';
import EmailActivation from '@/view/Login/EmailActivation';
import EmailActivationFail from '@/view/Login/EmailActivationFail';
import Home from '@/view/Home/Home';
import AlphaPage from '@/view/Home/Alpha/AlphaPage';
import PushPage from '@/view/Home/Alpha/Pages/PushPage';
import PlatePage from '@/view/Home/Alpha/Pages/PlatePage';
import Tzero from '@/view/Home/Strategy/Tzero';
import VolumePage from '@/view/Home/Volume/VolumePage';
import NewAlphaPage from '@/view/Home/Alpha/Pages/NewAlphaPage';
import RepeatNum from '@/view/Home/Alpha/Pages/RepeatNum';
import TimeIndex from '@/view/Home/Alpha/Pages/TimeIndex';
import PushTestback from '@/view/Home/Alpha/Pages/PushTestback';
import SearchFund from '@/view/Home/Alpha/Pages/SearchFund';
import IndexSorts from '@/view/Home/Alpha/Pages/IndexSorts';
// import UnknowPage from '@/view/Home/404';

const subRoutes = (path, name, component) => {
    return { path, name, component }
}

export const routeConfig = [
    {
        path: "/login",
        name: "登陆",
        exact: true,
        component: Login,
    },
    {
        path: "/register",
        name: "注册",
        exact: true,
        component: Register,
    },
    {
        path: "/forgetPassword",
        name: "忘记密码",
        exact: true,
        component: ForgetPassword,
    },
    {
        path: "/emailActivation",
        name: "邮箱激活成功",
        exact: true,
        component: EmailActivation,
    },
    {
        path: "/emailActivationFail",
        name: "邮箱激活失败",
        exact: true,
        component: EmailActivationFail,
    },
    {
        path: "/strategy",
        name: "策略系统",
        component: Home,
        routes: [
            subRoutes("/strategy/alpha", "alpha增强", AlphaPage),
            subRoutes("/strategy/pushpage", "推送分析", PushPage),
            subRoutes("/strategy/platepage", "板块分析", PlatePage),
            subRoutes("/strategy/tzero", "T0", Tzero),
            subRoutes("/strategy/volume", "成交量", VolumePage),
            subRoutes("/strategy/alphaIndex", "Alpha指数*", NewAlphaPage),
            subRoutes("/strategy/repeatNum", "推送重复次数", RepeatNum),
            subRoutes("/strategy/timeIndex", "推送分时", TimeIndex),
            subRoutes("/strategy/pushtestback", "推送回测", PushTestback),
            subRoutes("/strategy/searchFund", "私募搜索", SearchFund),
            subRoutes("/strategy/indexsort", "指数分类", IndexSorts),
            // {
            //     path: "*",
            //     name: "404",
            //     component: UnknowPage,
            // },
        ]
    }
]