import React, { useEffect, useState } from 'react';
import { Spin, Row, Col, Statistic, Tabs, Table, Typography, Space } from 'antd';
import { showPlateTradingSignal } from '@/api/stock';
import { useUpdateEffect } from 'ahooks';
import { autoCol, validateResponse, RateText, SyncButton } from '@/utils/utils';
import { simpleColumns, isValidObj, isValidArray, isValidString, isValidNumber } from '@/utils/utils2';
import { EXTRA_OPTIONS } from '../Components/sutil';
import PlatesAllTable from '../Components/PlatesAllTable';
import _ from 'lodash';
import * as echarts from 'echarts';

const { Text } = Typography;
const STATISTIC_ARRAY = [
  { name: '命中率', key: 'rate', key2: 'numRate' },
  { name: '命中率(盘中)', key: 'rateTraing', key2: 'numRateTrading' },
  { name: '召回率', key: 'recall', key2: 'numRecall' },
  { name: '召回率(盘中)', key: 'recallTrading', key2: 'numRecallTrading' },
];
let timer = null;

export default function LimitCharts(props) {
  const [active, setActive] = useState('1');
  const [option, setoption] = useState(EXTRA_OPTIONS.limitBar);
  const [option2, setoption2] = useState(EXTRA_OPTIONS.plateBar);
  const [upcount, setUpcount] = useState(0);
  const [spin, setSpin] = useState(false);
  const [plates, setPlates] = useState({}); // 板块全部数据
  const [newPlates, setNewPlates] = useState({}); // 板块处理后显示数据
  const [plateItems, setPlateItems] = useState({ avg: 0, datas: [] });
  const [plateAllTables, setPlateAllTable] = useState([]);
  // 获取板块统计
  async function _showPlateTradingSignal() {
    const get_type = _.get(props, 'types', null); // 与涨停获取type联动，且必填字段
    if (!isValidNumber(get_type)) {
      return
    }
    setSpin(true);
    const res = await showPlateTradingSignal({
      'tradeDate': _.get(props, 'singleDate') + ' 00:00:00',
      'limited': true,
      'type': get_type
    });
    if (validateResponse(res)) {
      const getDatas = _.get(res, 'data', {});
      setPlates(getDatas);
      let xdatas = []; let sdata = []; let tableDatas = [];
      if (isValidObj(getDatas)) {
        _.keys(getDatas).map((platename, i) => {
          const ana_datas = _.get(getDatas, `${platename}.plateAnalysis`, []);
          xdatas.push(platename);
          sdata.push(_.size(ana_datas));
          tableDatas.push({
            'id': i + platename,
            'pname': platename,
            'plateRate': _.get(getDatas, `${platename}.plateRate`, 0),
            'costAvgRate': _.get(getDatas, `${platename}.costAvgRate`, 0),
          });
        });
        setNewPlates({ xdatas, sdata });
      }
      setPlateAllTable(tableDatas);
      setUpcount(_.round(upcount + 0.1, 1));
    }
    setSpin(false);
  }

  useUpdateEffect(() => {
    //console.log('单日时间切换')
    if (active !== '1') {
      _showPlateTradingSignal();
    }
  }, [_.get(props, 'singleDate'), _.get(props, 'types')]);

  useEffect(() => {
    //console.log('charts', props)
    let myChart = props.myChart; const ele_charts = document.getElementById('limitCharts') ? true : false;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    if (!ele_charts) { // 图表不挂载dom时，直接返回，避免报错
      return
    }
    myChart = echarts.init(document.getElementById('limitCharts'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = _.cloneDeep(option);

    newOption.series[0].data[0].value = _.get(props, 'infos.numLimit', 0);
    newOption.series[0].data[1].value = _.get(props, 'infos.numLimitTotal', 0);
    newOption.series[0].data[2].value = _.get(props, 'infos.numBase', 0);
    // console.log('newOption', newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();

  }, [props.updateCount, active]);
  // 板块图表
  useUpdateEffect(() => {
    let myChart2 = props.myChart2; const ele_charts = document.getElementById('limit_plate_bar') ? true : false;
    if (myChart2 !== null && myChart2 !== "" && myChart2 !== undefined) {
      myChart2.dispose();
    }
    if (!ele_charts) {
      return
    }
    myChart2 = echarts.init(document.getElementById('limit_plate_bar'));

    let newOption2 = _.cloneDeep(option2);
    newOption2.xAxis.data = _.get(newPlates, 'xdatas', []);
    newOption2.series[0].data = _.get(newPlates, 'sdata', []);
    // console.log('newOption', newOption)
    setoption2(newOption2);
    myChart2.setOption(newOption2, true);
    myChart2.hideLoading();
    myChart2.resize();
    myChart2.on('click', 'series.bar', (param) => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        //点击找到对应范围，并更新table数据
        const plate_name = _.get(param, 'name', '');
        const plate_data = _.get(plates, `${plate_name}.plateAnalysis`, []);
        handlePlateItem(plate_name, plate_data);
      }, 500);
    });
  }, [upcount, active]);
  // 赋值表格
  function handlePlateItem(name, datas) {
    if (isValidArray(datas)) {
      let sum = 0;
      datas.map(n => {
        sum = sum + _.get(n, 'costRate', 0);
      });
      setPlateItems({
        'datas': datas,
        'avg': _.round(sum / _.size(datas), 4),
        'name': name,
        'size': _.size(datas)
      });
    }
  }

  const columns = [
    simpleColumns(['名称', 'name']),
    simpleColumns(['代码', 'symbol']),
    simpleColumns(['推送时间', 'time']),
    {
      ...simpleColumns(['推送价', 'price']),
      render: (text) => <Text strong>{text}</Text>
    },
    {
      ...simpleColumns(['建仓收益率', 'costRate']),
      sorter: (a, b) => a.costRate - b.costRate,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <RateText rate={text} carry={4} />
    },
  ];

  const chart_style = { width: '100%', height: 260 };
  return (
    <>
      <Tabs size='small' activeKey={active}
        tabBarExtraContent={{ right: <SyncButton onClick={_showPlateTradingSignal} /> }}
        onChange={(v) => {
          setActive(v);
          if (v !== '1' && _.size(plates) === 0) {
            _showPlateTradingSignal();
          }
        }}>
        <Tabs.TabPane tab={'统计'} key='1' />
        <Tabs.TabPane tab={'板块总'} key='2' />
        <Tabs.TabPane tab={'板块'} key='3' />
      </Tabs>

      {active === '1' ? <>
        <Row style={{ marginBottom: 12, marginTop: 5 }}>
          {STATISTIC_ARRAY.map((n, i) => {
            let getVal = _.get(props, `${'infos'}.${n.key2}`, 0);
            return <Col {...autoCol([6])} key={n.key + i}>
              <Statistic title={n.name} value={_.round(getVal, 2)} suffix="%" valueStyle={{ fontSize: 19 }} />
            </Col>
          })}
        </Row>

        <Spin spinning={_.get(props, 'loading', false)}>
          <div style={{ display: 'flex' }}>
            <div id="limitCharts" style={chart_style} />
          </div>
        </Spin>
      </> : null}

      {active === '2' && <PlatesAllTable
        datas={plateAllTables}
        yHeight={350}
        loading={spin}
        onPlateClick={(pl) => {
          setActive('3');
          const getXdata = _.get(newPlates, 'xdatas', []);
          let pindex = isValidArray(getXdata) && isValidString(pl) ? getXdata.indexOf(pl) : -1;
          if (pindex !== -1) {
            handlePlateItem(getXdata[pindex], _.get(plates, `${getXdata[pindex]}.plateAnalysis`));
          }
        }}
      />}

      {active === '3' ? <>
        <Spin spinning={spin}>
          <div style={{ display: 'flex' }}>
            <div id="limit_plate_bar" style={chart_style} />
          </div>
        </Spin>

        <div style={{ minHeight: 180 }}>
          <Table
            rowKey={'uid'}
            dataSource={_.get(plateItems, 'datas', [])}
            columns={columns}
            title={() => <Space size='small'>
              <Text strong>{_.get(plateItems, 'name', '')}</Text>
              <Text>{_.get(plateItems, 'size', '')}</Text>
              <Text>建仓平均收益率:</Text>
              <RateText rate={_.get(plateItems, 'avg', '')} carry={4} />
            </Space>}
            size='small'
            scroll={{ y: 220 }}
            pagination={false}
          />
        </div>
      </> : null}
    </>
  )
}