import React, { useState } from 'react';
import { Table, Typography, Tooltip, Segmented } from 'antd';
import { RiseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { createSingleColumns } from '@/utils/utils';
import _ from 'lodash';

const { Text, Paragraph } = Typography;
const OPTIONS = [{ value: 'plate', icon: <UnorderedListOutlined /> }, { value: 'limit', icon: <RiseOutlined /> }];
const renderText = (txt) => txt ?? '';
const PlateTable = ({ datas, markData, plDate = '' }) => {
  const [tableType, setTableType] = useState(OPTIONS[0].value);
  const columns = [
    { ...createSingleColumns(['时间', 'time', 120]) },
    {
      ...createSingleColumns(['板块', 'plate']),
      render: (text, record) => {
        const getPlate = _.get(record, 'plate', [])
        return <Paragraph
          style={{ marginBottom: 0 }}
          ellipsis={{
            rows: 2,
            expandable: true
          }}
        >
          {getPlate && _.size(getPlate) > 0 && getPlate.map((item, i) => {
            return <span key={_.get(item, 'name') + _.get(item, 'value') + i} style={{ marginRight: 5 }}>
              <Text>{_.get(item, 'name', '')}</Text>
              <Text strong style={{ color: '#007cba', marginLeft: 4 }}>{_.get(item, 'value', 0)}</Text>
              <Text type='secondary'>{' ('}</Text>
              <Text strong style={{ color: '#e4b100', marginLeft: 4 }}>{_.get(item, 'rate', 0) + '%'}</Text>
              <Text type='secondary'>{') '}</Text>
            </span>
          })}
        </Paragraph>
      }
    },
  ];
  const columns2 = [
    { ...createSingleColumns(['时间', 'xAxis', 120]) },
    {
      ...createSingleColumns(['股票', 'symbol', 200]),
      render: (text, record) => {
        return <Text>{`${renderText(_.get(record, 'value'))}-${renderText(_.get(record, 'symbol_x'))}`}</Text>
      }
    },
    {
      ...createSingleColumns(['板块', 'plate', 200]),
      render: (text, record) => {
        return <Text>{`${renderText(_.get(record, 'plate'))}-${renderText(_.get(record, 'industry'))}`}</Text>
      }
    },
  ]
  const isPlate = tableType === 'plate' ? true : false
  return (
    <div style={{ marginTop: 12 }}>
      <Table
        rowKey={'order'}
        dataSource={isPlate ? datas : markData}
        columns={isPlate ? columns : columns2}
        size='small'
        // title={() => <Text strong>{plDate}</Text>}
        footer={() => {
          if (_.size(markData) > 0) {
            return <Tooltip title='切换板块/涨停统计'>
              <Segmented
                options={OPTIONS}
                value={tableType}
                size='small'
                onChange={(v) => setTableType(v)}
              />
            </Tooltip>
          }
          return null
        }}
        pagination={{
          pageSize: isPlate ? 7 : 10,
          size: 'small',
          simple: true
        }}
      />
    </div>
  )
}

export default PlateTable;