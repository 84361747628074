import React, { useState, useEffect } from 'react';
import { Tabs, Spin } from 'antd';
import { indexKlineDetail, showIndexPlate, getStockChgStatisticsInterval, getStockChgStatistics } from '@/api/stock';// getAllPlates, getMovingAverage,
import { showIndexKlineByDate } from '@/api/workbench';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { validateResponse } from '@/utils/utils';
import { getStorage, isValidArray, createDatasTimeKey } from '@/utils/utils2';
import { useUnmount, useMount } from 'ahooks';
import ChartsIndex from './IndexCharts/ChartsIndex';
import PlateTable from './IndexCharts/PlateTable';
import UpDownCharts from './IndexCharts/UpDownCharts';
import moment from 'moment';
import _ from 'lodash';

const PUSH_TYPE_COLOR = { 0: 'black', 1: 'red', 2: '#ff8600', 3: '#00a1a8' };
const TOTAY = moment().format('YYYY-MM-DD');
const RANGE_DATE = [moment().subtract(7, 'd').format('YYYY-MM-DD'), TOTAY];
const defaultPms = {
  symbol: '000001',
  dates: [TOTAY, TOTAY],
  type: 'plate',
  pushType: 201
};
const ACTIVE_ARR = ['index_cal', 'move_avg', 'up_down'];
const DEFAULT_INDEX_CODE = '000852';
/**
 *  指数、涨停票、板块统计展示模块
 */
const IndexCharts = ({ tabKey = '', isTrading = false, openFormat }) => {
  const mobxStore = useLocalObservable(() => store);
  const [glbIndex] = useState(JSON.parse(JSON.stringify(mobxStore.assetIndexData)));
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [indexTable, setIndexTable] = useState([]); // 板块表格数据
  const [plateDate, setPlateDate] = useState('');
  const [idxLoading, setIdxLoading] = useState(false);
  const [idxUpdate, setIdxUpdate] = useState(0);
  const [idxUpdate2, setIdxUpdate2] = useState(0);
  const [idxUpdate3, setIdxUpdate3] = useState(0);
  const [active, setActive] = useState(ACTIVE_ARR[0]);
  const [marks, setMarks] = useState([]); // 涨停数据
  const [limitText, setLimitText] = useState('');
  const [newChartsData, setNewChartsData] = useState({});
  const [indexParams, setIndexParams] = useState(defaultPms);
  const [updowns, setUpdowns] = useState([]); // 个股涨跌数据
  const [updownPms, setUpdownPms] = useState({ s_date: TOTAY, s_range: RANGE_DATE });
  const [indexParams2, setIndexParams2] = useState({
    symbol: DEFAULT_INDEX_CODE,
    startTime: RANGE_DATE[0],
    endTime: RANGE_DATE[1],
  });
  const [indexCharts, setIndexCharts] = useState({});
  let isUnmont = false;
  // 加载如有缓存数据优先使用缓存数据
  useMount(() => {
    const isGlobalIndex = _.get(glbIndex, 'infos', null);
    const tradingStatus = getStorage('2');
    if (!!isGlobalIndex && tradingStatus === 'rest') {
      // setIndexInfo(_.get(glbIndex, 'infos', {}));
      setIndexTable(_.get(glbIndex, 'table', []));
      setPlateDate(_.get(glbIndex, 'pmsDate', ''));
      updateChart1();
      updateChart2();
    } else {
      _getIndexDatas();
    }
  });

  useUnmount(() => {
    isUnmont = true;
  });

  useEffect(() => {
    if (tabKey === '4' && isTrading) {
      _getIndexDatas();
    }
  }, [tabKey]);
  // 获取模块数据，并进行数据处理; 【1】 合并原图表处理，现获取完数据后，进行初步处理后直接进行图表处理
  async function _getIndexDatas(pms) {
    setIdxLoading(true);
    let params = {
      'symbol': pms ? _.get(pms, 'symbol') : indexParams.symbol,
      'beginDate': pms ? _.get(pms, 'dates[0]') : indexParams.dates[0],
      'endDate': pms ? _.get(pms, 'dates[1]') : indexParams.dates[1],
      'type': 'minute'
    }
    //____________________________获取数据.首次处理_________________________
    let indexs = [];
    const res = await indexKlineDetail(params);
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data.pointList', []);
      let newData = [];
      if (_.size(getData) > 0) { // 用31分数据复制作为第一个点，并修改时间
        getData.map(n => {
          if (_.includes(n[0], '09:31:00')) {
            let changeTime = _.cloneDeep(n);
            changeTime[0] = _.replace(changeTime[0], '09:31:00', '09:30:00');
            newData.push(changeTime);// 增加开盘第一个点位，返回数据从31分开始
          }
          newData.push(n);
        })
      }
      indexs = newData;
    };
    let params2 = {
      'start': params.beginDate + ' 00:00:00',
      'end': params.endDate + ' 00:00:00',
      'indexCode': params.symbol,
      'pushType': pms ? _.get(pms, 'pushType') : indexParams.pushType,
      'type': pms ? _.get(pms, 'type') : indexParams.type,
    };
    const resPlate = await showIndexPlate(params2);
    setIdxLoading(false);
    if (validateResponse(resPlate)) {
      const getBasicInfo = _.get(resPlate, 'data.basicData', {});
      const getLimitStocks = _.get(resPlate, 'data.limitStocks', []); // 涨停票
      let arrayData = [];
      for (const keyname in getBasicInfo) {
        const timeItem = getBasicInfo[keyname];
        let plateArray = [];
        Object.keys(timeItem).map(kname => {
          const itemNum = _.get(timeItem, `${kname}.num`, 0)
          plateArray.push({ name: kname, value: itemNum, rate: _.get(timeItem, `${kname}.rate`, 0) });
        })
        arrayData.push({
          key: keyname,
          order: moment(keyname).diff(openFormat, 'm'),
          time: keyname,
          plate: _.orderBy(plateArray, ['value'], ['desc']),
        })
      }
      const idxArray = _.orderBy(arrayData, ['order'], ['asc']);
      //____________________________处理图表数据_________________________
      // 更新时处理图表数据，ChartsIndex图表内部只进行赋值操作
      const getLimit = isValidArray(getLimitStocks) ? getLimitStocks.map(n => {
        return { ...n, xtime: n.tradeDate + ' ' + n.time }
      }) : []; // 涨停信息数据
      if (isValidArray(indexs)) {
        let timeList = []; let valueList = []; let pureValue = []; let markpoint = []; let loops = []; let fullMarks = [];
        // 指数线
        indexs.map(n => {
          timeList.push(n[0] ?? '');

          if (n[2]) {
            valueList.push(parseFloat(n[2]));
            pureValue.push(parseFloat(n[2])); // 计算最大最小值用
          } else {
            valueList.push('-');
          }
        });
        // 涨停票markpoint
        let stockCal = {};
        isValidArray(getLimit) && getLimit.map((s, i) => {
          const x_time = _.get(s, 'xtime', '');
          const tindex = timeList.indexOf(x_time);
          const push_type = _.get(s, 'pushType', 0);
          if (tindex !== -1) {
            let mindex = _.findIndex(markpoint, o => o.xAxis === x_time)
            let pushObj = { // 每条数据需要的所有信息，弹出提示、markpoint、表格展示都需要用，字段根据需求延伸.
              name: s.name + i, order: s.name + i, value: s.name, xAxis: x_time, yAxis: valueList[tindex] + 2,
              symbol: '', symbolSize: 0, label: { color: PUSH_TYPE_COLOR[push_type], show: true }, stock: s.name,
              industry: s.industry, plate: s.plate, symbol_x: s.symbol, timeKey: moment().diff(moment(x_time), 'm'),
              isPush: _.get(s, 'isPush', false), pushType: push_type
            }
            fullMarks.push(_.cloneDeep(pushObj));
            // 计算同一时间出现多只股票的累加
            if (stockCal[x_time] && mindex !== -1) { // 多只 symbol(数量n) 
              stockCal[x_time] = stockCal[x_time] + 1;
              markpoint[mindex].value = (pushObj.isPush ? pushObj.stock : markpoint[mindex].stock) + `(${stockCal[x_time]})`
              if (pushObj.isPush) { // 优先将isPush推送的票放到第一个，并标记为红色
                markpoint[mindex].label.color = 'red';
              }
            } else { // 单只最后markpoint上只显示symbol名称
              stockCal[x_time] = 1;
              markpoint.push(pushObj);
            }

            loops.push({
              tKey: pushObj.timeKey, children: <span key={'point' + i} style={{ marginRight: 15, color: 'rgba(0,0,0,0.45)' }}>
                {`${s.xtime} : `}<span style={{ color: 'red' }}>{s.name}</span>
              </span>
            });
          }
        });
        loops = _.orderBy(loops, ['tKey'], ['desc']);
        // 计算今日指数波幅
        let min = 0; let max = 0;
        let low = _.min(pureValue); let high = _.max(pureValue); let start = _.get(res, 'data.preClose', 0);
        min = _.round(((low - start) / low) * 100, 3);
        max = _.round(((high - start) / high) * 100, 3);

        setLimitText(_.size(loops) > 0 ? loops.map(n => n.children) : []); // 循环展示股票信息文字
        setMarks(_.orderBy(fullMarks, ['timeKey'], ['desc'])); // 涨停票完整数据，时间排序
        setNewChartsData({
          xval: timeList,
          datas: valueList,
          markpoints: markpoint,
          fullMarks: fullMarks,
          min, max // 图表直接赋值右侧 y轴
        })
      }
      // 赋值全局数据
      setIndexTable(idxArray);
      mobxStore.changeAssetIndex({
        pmsDate: params.endDate,
        table: idxArray,
        indexArray: indexs,
      })
    }
    setPlateDate(params.endDate);
    updateChart1();
  }
  // 涨跌幅分布统计
  async function _getStockChgStatisticsInterval(date) {
    let params = {
      'start': (date ? date[0] : _.get(updownPms, 's_range[0]', '')) + ' 00:00:00',
      'end': (date ? date[1] : _.get(updownPms, 's_range[1]', '')) + ' 00:00:00',
    }
    // 盘中增加单日获取，区间接口盘中不返回当日数据
    const res = await getStockChgStatisticsInterval(params);
    const res2 = isTrading ? await getStockChgStatistics({ 'tradeDate': TOTAY + ' 00:00:00' }) : {};
    if (validateResponse(res)) {
      const getData = _.get(res, 'data', []);
      const getDataToday = _.get(res2, 'data', {});
      setUpdowns(createDatasTimeKey(
        isTrading ? _.concat(getData, [getDataToday]) : getData,
        'tradeDate', 'd', 'asc'
      ));
    }
    if (date) { // 时间切换先更新指数，然后再更新upcount
      _showIndexKlineByDate({ 'symbol': indexParams2.symbol, 'startTime': date[0], 'endTime': date[1] });
    } else {
      updateChart3();
    }
  }
  // 获取时间区间指数
  async function _showIndexKlineByDate(params) {
    setIndexParams2(params)
    const res = await showIndexKlineByDate(params);
    if (validateResponse(res)) {
      setIndexCharts(_.get(res, 'data', {}));
    }
    updateChart3();
  }
  // tab图表内容更新
  const updateChart1 = () => setIdxUpdate(idxUpdate + 1);
  const updateChart2 = () => setIdxUpdate2(_.round(idxUpdate2 + 0.1, 1));
  const updateChart3 = () => setIdxUpdate3(_.round(idxUpdate3 + 0.1, 1));
  // tab切换
  function onTabChange(activeKey) {
    setActive(activeKey);
    if (activeKey === ACTIVE_ARR[0]) {
      updateChart1();
    } else if (activeKey === ACTIVE_ARR[2]) {
      _getStockChgStatisticsInterval(updownPms.s_range);
    }
  }
  /**
   * TabsPanel里面如包含charts，上线后切换tab会有图表缩小问题
   * 所以改用状态控制，而不是包含在panel里面；
   */
  function renderCharts() {
    if (active === ACTIVE_ARR[0]) {
      return <Spin spinning={idxLoading}>
        <ChartsIndex
          defaultParams={defaultPms}
          todayFor={TOTAY}
          indexData={newChartsData}
          ltext={limitText}
          colorType={PUSH_TYPE_COLOR}
          getIndexs={(params) => {
            setIndexParams(params);
            _getIndexDatas(params);
          }}
        />
        <PlateTable
          datas={indexTable}
          plDate={plateDate}
          markData={marks}
        />
      </Spin>
    } else if (active === ACTIVE_ARR[2]) {
      return <UpDownCharts
        updateCount={idxUpdate3}
        params={updownPms}
        idxParams={indexParams2}
        todayFor={TOTAY}
        tDates={tradeDates}
        datas={updowns}
        indexs={indexCharts}
        onRangeChange={(type, dates) => {
          let temp = _.cloneDeep(updownPms);
          temp[type] = dates;
          setUpdownPms(temp);
          if (type === 's_range') {
            _getStockChgStatisticsInterval(dates);
          }
        }}
        onIndexChange={(symbol) => {
          _showIndexKlineByDate({ symbol, 'startTime': indexParams2.startTime, 'endTime': indexParams2.endTime })
        }}
      />
    }
  };
  return (
    <>
      <Tabs
        defaultActiveKey={ACTIVE_ARR[0]} size='small' activeKey={active}
        onChange={(activeKey) => onTabChange(activeKey)}
      >
        <Tabs.TabPane key={ACTIVE_ARR[0]} tab='指数' />
        <Tabs.TabPane key={ACTIVE_ARR[2]} tab='概况' />
      </Tabs >

      {renderCharts()}
    </>
  )
}

export default IndexCharts;