import React, { useState } from 'react';
import { Col, Row, PageHeader, Tabs, Badge, Typography, Dropdown, Menu, Space } from 'antd';
import { StockOutlined, SendOutlined, FundViewOutlined, EllipsisOutlined, BarChartOutlined, DownOutlined } from '@ant-design/icons';
import { getAllPositionStock } from '@/api/stock';
import { withRouter } from "react-router-dom";
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { autoCol, validateResponse } from '@/utils/utils';
import { saveStorage, isValidObj, isValidArray } from '@/utils/utils2';
import { useReactive, useMount, useUnmount } from 'ahooks'; // useScroll, useDebounceEffect
import StockAnalyse from './Components/StockAnalyse';
import NewsList from './Components/NewsList';
import ExtraTrade from './ExtraTrade';
import IndexCharts from './IndexCharts';
import LimitTrade from './LimitTrade';
import AlphaIndexCharts from './AlphaIndexCharts';
import moment from 'moment';
import _ from 'lodash';
import './assets.scss';

const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;
const TOTAY = moment().format('YYYY-MM-DD');
const OPEN_TIME = moment(TOTAY + ' 9:30');
const TabIcon = ({ txt, icon }) => <div>{icon}<span>{txt}</span></div>;
let timer = null;

export default withRouter(function AlphaPage(props) {
  const mobxStore = useLocalObservable(() => store);
  const intradeObj = JSON.parse(JSON.stringify(mobxStore.inTrading));
  const [active, setActive] = useState('1');
  const [isTrading, setIsTrading] = useState(_.get(intradeObj, 'trading')); // 是否在盘中 
  const [isTradeDate, setIsTradeDate] = useState(_.get(intradeObj, 'tradeDay'));
  const [limitParams, setLimitParams] = useState({}); //limit涨停模块 single参数备份，重载使用
  const [suspention, setSuspention] = useState([]);
  const [upcount, setUpcount] = useState(0);
  const pageState = useReactive({ limit_init: false, unread: 0 });// 首次加载
  // const scroll = useScroll(document);
  // 监听滚动，实现首次延时加载； 最下方新闻和股票分析模块
  // useDebounceEffect(() => {
  //   const getTop = _.get(scroll, 'top', 0);
  //   if (getTop > 500 && active === '3' && pageState.limit_init === false) {
  //     setLimitUpdate(limitUpdate + 1);
  //     pageState.limit_init = true;
  //   }
  //   // console.log(scroll)
  // },
  //   [scroll],
  //   { wait: 300 },
  // );

  useMount(() => {
    _getAllPositionStock();
    saveStorage('2', isTrading);

    if (!isValidObj(intradeObj)) {
      mobxStore._isAllStockHoliday();
      timer = setTimeout(() => {
        let tradeObj = JSON.parse(JSON.stringify(mobxStore.inTrading));
        setIsTrading(_.get(tradeObj, 'trading'));
        setIsTradeDate(_.get(tradeObj, 'tradeDay'));
        setUpcount(upcount + 1);
      }, 1200);
    };
  });

  useUnmount(() => {
    timer && clearTimeout(timer);
  });

  //获取持仓停牌股票信息
  async function _getAllPositionStock() {
    const res = await getAllPositionStock();
    if (validateResponse(res)) {
      const getData = _.get(res, 'data', {});
      let final = [];
      if (isValidObj(getData)) {
        _.keys(getData).map(keyname => {
          if (isValidArray(getData[keyname])) {
            getData[keyname].map(n => {
              final.push(_.assign(n, { 'platform': keyname }));
            })
          }
        });
      }
      setSuspention(final);
    }
  }
  // 页面跳转
  function jumpToRoute(path = '', pms = {}) {
    props.history.push({ 'pathname': `/strategy/${path}`, 'state': pms });
  };

  const isTradingStatus = isTrading === 'trading' ? true : false;
  const tabOneComp = <Badge size="small" count={pageState.unread} offset={[5, 0]}>
    <TabIcon txt={'Aplha推送'} icon={<SendOutlined />} />
  </Badge>;
  // 快捷跳转操作
  const fastItems = [
    { name: '推送分析', path: 'pushpage' },
    { name: '板块分析', path: 'platepage' },
    { name: '推送重复次数', path: 'repeatNum' },
    { name: '推送分时', path: 'timeIndex' },
  ];
  const fastmenu = (
    <Menu items={fastItems.map(n => {
      return {
        'label': <a key={n.path} onClick={() => {
          const pushVals = JSON.parse(JSON.stringify(mobxStore.pushInfo))
          jumpToRoute(n.path, n.path === 'timeIndex' && isValidObj(pushVals) ? pushVals : {})
        }}>
          {n.name}
        </a>,
        'key': n.path
      }
    })}
    />
  );
  const FastComps = <Dropdown key='fastdro' overlay={fastmenu} trigger={['click']}>
    <div style={{ cursor: 'pointer' }}>
      <Space>
        <Text>快捷</Text>
        <DownOutlined />
      </Space>
    </div>
  </Dropdown>;

  return (
    <>
      <PageHeader title={'个股Alpha增强'} style={{ backgroundColor: 'white' }}
        extra={[FastComps]}
      >
        {isValidArray(suspention)
          ? <div className='supendDiv'>
            <Paragraph ellipsis={{ row: 4, expandable: true }}>
              <span>停牌：</span>
              {suspention.map((n, i) => <span key={'sus' + i} style={{ marginRight: 10 }}>
                <Text keyboard>{`${_.get(n, 'stockName', '')}-${_.get(n, 'stockCode', '')} `}</Text>
                <Text type='secondary' style={{ fontSize: 13 }}>{`${_.get(n, 'platform')}-${_.get(n, 'productName')}-${_.get(n, 'subAccountName')}`}</Text>
              </span>)}
            </Paragraph>
          </div>
          : <></>}
      </PageHeader>

      <div className='contentTab'>
        <Tabs type='card' activeKey={active} onChange={(tab) => setActive(tab)}>
          <TabPane tab={tabOneComp} key="1">
            <ExtraTrade
              tradeDay={isTradeDate}
              updates={upcount}
              onListNewChange={(num) => pageState.unread = num}
              goPush={(pms) => jumpToRoute('pushpage', pms)}
              goPlate={(pms) => jumpToRoute('platepage', pms)}
              goTimeIndex={(val) => jumpToRoute('timeIndex', val)}
            />
          </TabPane>
          <TabPane tab={<TabIcon txt={'Alpha指数'} icon={<StockOutlined />} />} key="2">
            <AlphaIndexCharts
              pageKey='AlphaPage'
              isTrading={isTradingStatus}
              goRepeat={() => jumpToRoute('repeatNum')}
            />
          </TabPane>
          <TabPane tab={<TabIcon txt={'Alpha统计'} icon={<BarChartOutlined />} />} key="3">
            <LimitTrade
              tabKey={active}
              singlePms={limitParams}
              isTrading={isTradingStatus}
              onPmsChange={(pms) => setLimitParams(pms)}
            />
          </TabPane>
          <TabPane tab={<TabIcon txt={'全市场'} icon={<FundViewOutlined />} />} key="4">
            <IndexCharts
              tabKey={active}
              todayFormat={TOTAY}
              openFormat={OPEN_TIME}
              isTrading={isTradingStatus}
            />
          </TabPane>
          <TabPane tab={<TabIcon txt={'其他'} icon={<EllipsisOutlined />} />} key="5">
            <Row gutter={[16, 16]} style={{ marginTop: 12}}>
              <Col {...autoCol([12, 12])}>
                <NewsList />
              </Col>
              <Col {...autoCol([12, 12])}>
                <StockAnalyse pageKey='assetsShow' />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </>
  )
})