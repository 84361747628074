import React from 'react';
import { Col, Row, PageHeader } from 'antd';
import { autoCol } from '@/utils/utils';
import StockAnalyse from '../Alpha/Components/StockAnalyse';
import SignalTable from './Components/SignalTable';
import _ from 'lodash';

const Tzero = () => {
  return <>
    <PageHeader
      title={'T+0指数增强'}
      style={{ backgroundColor: 'white' }}
    ></PageHeader>

    <div style={{ padding: 8, width: '100%' }} id="tzero">
      <Row gutter={[8, 8]}>
        <Col {...autoCol([12])}>
          <StockAnalyse pageKey='tzero' />
        </Col>
        <Col {...autoCol([12])}>
          <SignalTable />
        </Col>
      </Row>
    </div>
  </>
}

export default Tzero;