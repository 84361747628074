import React, { useState } from 'react';
import {
  PageHeader, Row, Col, Cascader, Select, Segmented, Space, Spin,
  Button, message, Typography, InputNumber, Empty, Radio,
} from 'antd';
import { PlusOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
// import { useLocalObservable } from 'mobx-react';
// import { store } from '@/store/mobx';
import { useMount, useReactive, useUpdateEffect } from 'ahooks';
import { indexKlineSort } from '@/api/stock';
// import { autoCol, validateResponse } from '@/utils/utils';
import { labelValues, isValidArray } from '@/utils/utils2';
import { base_options, cascaderOptions, codes_value } from '@/utils/indexCodes';
import { INDEX_SORT_OPTIONS } from './putil';
import { NewRangePicker, NewDivider } from '@/view/common/widgets';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import './pages.scss';

const { Text } = Typography;
const FORMAT = "YYYY-MM-DD";
const TODAY = moment().format(FORMAT);
const RANGE = [moment().subtract(30, 'd').format(FORMAT), TODAY];
const LEVEL_ONE_NAME = { 'levelOneMin': '最小', 'levelOneMinToMiddle': '中小', 'levelOneMiddleToMax': '中大', 'levelOneMax': '最大' };
const LEVEL_ONE_COLOR = { 'levelOneMin': '#3d7d53', 'levelOneMinToMiddle': '#a9c466', 'levelOneMiddleToMax': '#f08c00', 'levelOneMax': '#d8200c' };
const LEVEL_ONE = ['levelOneMin', 'levelOneMinToMiddle', 'levelOneMiddleToMax', 'levelOneMax'];
const LEVEL_TWO = ['Min', 'MinToMiddle', 'MiddleToMax', 'Max'];
const LEVEL_TWO_OC = ['levelTwoOpen', 'levelTwoClose'];
const LEVEL_TWO_NAME = { 'Min': '最小', 'MinToMiddle': '中小', 'MiddleToMax': '中大', 'Max': '最大' };
const LEVEL_TWO_OC_NAME = { 'levelTwoOpen': '开', 'levelTwoClose': '收' };
const LEVEL_TWO_COLOR = {
  'levelTwoOpen': ['#f2ce5d', '#eec801', '#d49301', '#af8827'],
  'levelTwoClose': ['#6fc6d2', '#53a8c0', '#2681b0', '#016396']
};
const CAT_SEGS = _.keys(LEVEL_ONE_NAME).map(keys => labelValues([LEVEL_ONE_NAME[keys], keys]));
const DEFAULT_SEG = 'levelOneMin';
const SEGV = 'v';
const allOptions = _.chain(cascaderOptions).filter(o => o.value === 'all').get('[0].children', []).value();
const SecText = ({ children }) => <Text type='secondary'>{children}</Text>
// Alpha指数分类
const IndexSorts = (props) => {
  // const mobxStore = useLocalObservable(() => store);
  //const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [sortData, setSortData] = useState([]);
  const sortState = useReactive({
    loading: false, idx: '000852', dates: RANGE, idx2: '999901', idx2Arr: [], excess: '0',
    inputPms: { "minParam1": -0.5, "middleParam1": 0, "maxParam1": 0.5, "minParam2": -0.5, "middleParam2": 0, "maxParam2": 0.5 },
    segVal: {}, segIndex: 0, // segment根据遍历的index进行创建值 {v0,v1,v2}
  });

  // useMount(() => {

  // });

  // 获取指数分类数据
  async function _indexKlineSort(type = '') {
    sortState.loading = true;
    let params = {
      "start": sortState.dates[0],
      "end": sortState.dates[1],
      "baseIndex": sortState.idx,
      "customIndex": sortState.idx2,
      "isExcess": sortState.excess === '1' ? true : false
    };
    _.keys(sortState.inputPms).map(keyname => {
      _.set(params, keyname, sortState.inputPms[keyname] / 100);
    });
    const res = await indexKlineSort(params);
    if (_.get(res, 'code') === '200') {
      const getData = _.get(res, 'data', {});
      // add进行累加
      if (type === 'add') {
        let temp = _.cloneDeep(sortData);
        temp.push(_.assign(getData, { 'order': _.size(temp), ...params }));
        setSortData(temp);
      } else {
        // 新建，order=遍历时的index值，
        setSortData([_.assign(getData, { 'order': 0, ...params })]);
      }
      setUpdate(update + 1);
    } else {
      message.info(_.get(res, 'message', '获取失败！'))
    }
    sortState.loading = false;
  }
  // 获取成功更新
  useUpdateEffect(() => {
    // console.log(sortData)
    if (isValidArray(sortData)) {
      // let fullOptions = {};
      // 在数组内部将chart按照变量每次进行处理
      sortData.map((item, index) => {
        let myChart = props.myChart; let myChart2 = props.myChart;
        myChart = echarts.init(document.getElementById('indexsort_main_' + index));
        myChart2 = echarts.init(document.getElementById('indexsort_sub_' + index));
        let newOption = _.cloneDeep(INDEX_SORT_OPTIONS);
        let newOption2 = _.cloneDeep(INDEX_SORT_OPTIONS);
        // 每次使用空options进行数据处理
        newOption.xAxis.data = LEVEL_ONE.map(k => LEVEL_ONE_NAME[k]);
        newOption.series[0].data = LEVEL_ONE.map(k => {
          return { 'value': _.size(_.get(item, k + '.levels', [])), 'itemStyle': { 'color': LEVEL_ONE_COLOR[k] } }
        });
        //levelTwo分类用两个静态key数组遍历拼接，并且默认一个levelOne的值进行取值
        let count = 0;
        LEVEL_TWO_OC.map(oc => {
          LEVEL_TWO.map((k2, ki) => {
            newOption2.xAxis.data[count] = LEVEL_TWO_OC_NAME[oc] + LEVEL_TWO_NAME[k2];
            newOption2.series[0].data[count] = {
              'value': _.size(_.get(item, `${DEFAULT_SEG}.` + oc + k2, [])),
              'itemStyle': { 'color': LEVEL_TWO_COLOR[oc][ki] }
            };
            count++;
          })
        });
        // 默认每个segment的value
        sortState.segVal[`v${index}`] = DEFAULT_SEG;
        // 赋值options
        // fullOptions[index] = { 'main': newOption, 'sub': newOption2 };
        myChart.setOption(newOption, true);
        myChart.resize();
        myChart2.setOption(newOption2, true);
        myChart2.resize();
      });
      // setoption(fullOptions);
    };
  }, [update]);
  // 切换每组的segment的时候进行更新
  useUpdateEffect(() => {
    // 默认只对更新的sub图表重新赋值即可
    let myChart2 = props.myChart;
    myChart2 = echarts.init(document.getElementById('indexsort_sub_' + sortState.segIndex));
    const getSortItem = _.get(sortData, `[${sortState.segIndex}]`);
    const subKeys = _.get(sortState.segVal, 'v' + sortState.segIndex);
    // 数据按照选择的segmentVal进行更新
    let newOption2 = _.cloneDeep(INDEX_SORT_OPTIONS);
    let count = 0;
    LEVEL_TWO_OC.map(oc => {
      LEVEL_TWO.map((k2, ki) => {
        newOption2.xAxis.data[count] = LEVEL_TWO_OC_NAME[oc] + LEVEL_TWO_NAME[k2];
        newOption2.series[0].data[count] = {
          'value': _.size(_.get(getSortItem, `${subKeys}.` + oc + k2, [])),
          'itemStyle': { 'color': LEVEL_TWO_COLOR[oc][ki] }
        };
        count++;
      })
    });
    myChart2.setOption(newOption2, true);
    myChart2.resize();
  }, [update2]);
  // 删除item
  function delSortItem(index) {
    setSortData(_.filter(sortData, o => o.order !== index));
    setUpdate(update + 1);
  };
  // 头部调整参数输入
  function renderParamsInput(key) {
    const input_props = { size: 'small', style: { width: 120 }, addonAfter: '%', step: 0.1 };
    return <Space size='small'>
      <Text>Level{key}:</Text>
      <SecText>{'Mini'}</SecText>
      <InputNumber {...input_props}
        value={_.get(sortState, `inputPms.minParam${key}`)}
        onChange={v => _.set(sortState, `inputPms.minParam${key}`, v)}
      />
      <SecText>{'Middle'}</SecText>
      <InputNumber {...input_props}
        value={_.get(sortState, `inputPms.middleParam${key}`)}
        onChange={v => _.set(sortState, `inputPms.middleParam${key}`, v)}
      />
      <SecText>{'Max'}</SecText>
      <InputNumber {...input_props}
        value={_.get(sortState, `inputPms.maxParam${key}`)}
        onChange={v => _.set(sortState, `inputPms.maxParam${key}`, v)}
      />
    </Space>
  }

  return <>
    <PageHeader
      title={'Alpha指数分类'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
    >
      <Row>
        <Col span={18}>
          <Space>
            <SecText>{'基础'}</SecText>
            <Select value={sortState.idx}
              options={base_options}
              style={{ width: 135 }}
              onChange={(v) => sortState.idx = v}
            />
            <SecText>{'全部'}</SecText>
            <Cascader
              style={{ width: 156 }}
              value={sortState.idx2Arr}
              options={allOptions}
              onChange={(v) => {
                sortState.idx2Arr = v;
                sortState.idx2 = _.last(v);
              }}
            />
            <SecText>{'周期'}</SecText>
            <NewRangePicker
              dates={sortState.dates}
              onSelect={(dateStrings) => sortState.dates = dateStrings}
            />
            <Spin spinning={sortState.loading} />
          </Space>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Space>
            <Button icon={<PlusOutlined />} onClick={() => _indexKlineSort('add')}>添加</Button>
            <Button icon={<CheckOutlined />} type='primary' onClick={() => _indexKlineSort('new')}>新增</Button>
          </Space>
        </Col>
      </Row>

      <NewDivider />

      <Row>
        <Col span={12}>
          {renderParamsInput(1)}
          <Radio.Group size='small' onChange={e => sortState.excess = e.target.value} defaultValue="0" style={{ marginLeft: 6 }}>
            <Radio.Button value="0">指数</Radio.Button>
            <Radio.Button value="1">超额</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={12}>
          {renderParamsInput(2)}
        </Col>
      </Row>
    </PageHeader>

    <Row gutter={[8, 8]} style={{ paddingLeft: 8, paddingRight: 8 }}>
      {isValidArray(sortData) ? sortData.map((item, index) => {
        return <Col key={'sort' + index} span={_.size(sortData) > 1 ? 12 : 24}>
          <div className='newCards'>
            <Row>
              <Col span={22}>
                <Space size='small'>
                  <SecText>{'分档'}</SecText>
                  <Segmented size='small' options={CAT_SEGS}
                    value={_.get(sortState.segVal, SEGV + index)}
                    onChange={v => {
                      // 赋值 v1 ; 并更新index值
                      _.set(sortState.segVal, SEGV + index, v);
                      sortState.segIndex = index;
                      setUpdate2(_.round(update2 + 0.1, 1));
                    }}
                  />
                  <SecText>{_.get(codes_value, item.baseIndex)}</SecText>
                  <SecText>{_.get(codes_value, item.customIndex)}</SecText>
                  <SecText>{item.isExcess ? '超额' : '指数'}</SecText>
                </Space>
              </Col>
              <Col span={2} style={{ textAlign: 'right' }}>
                {_.size(sortData) > 1 && <Button size='small' icon={<CloseCircleOutlined />} type='text' danger
                  onClick={() => delSortItem(index)}
                />}
              </Col>
            </Row>
            <SecText>{`${_.get(item, 'minParam1') * 100}~${_.get(item, 'maxParam1') * 100}`}</SecText>
            <div style={{ display: 'flex' }}>
              <div
                id={"indexsort_main_" + index}
                style={{ 'width': '100%', 'height': 230 }}
              />
            </div>
            <SecText>{`${_.get(item, 'minParam2') * 100}~${_.get(item, 'maxParam2') * 100}`}</SecText>
            <div style={{ display: 'flex' }}>
              <div
                id={"indexsort_sub_" + index}
                style={{ 'width': '100%', 'height': 230 }}
              />
            </div>
          </div>
        </Col>
      }) : <div className='newCards' style={{ height: 500, width: '100%' }}>
        <Empty />
      </div>}

    </Row>
  </>
}

export default IndexSorts;