import axios from '@/utils/axios';
import _ from 'lodash';


/**
 *  基金经理工作台 - 展示最新一条消息（风控）
 */
export function getFirstRiskControl(data) {
    return axios.get(global.api + '/message/getFirstRiskControl', { params: data });
}
/**
 *  基金经理工作台 - 风控临时预警线设定
 */
export function createTemporaryRulesEasy(data) {
    return axios.post(global.api + '/riskControl/createTemporaryRulesEasy', data);
}
/**
 *  交易员工作台-子账户列表
 */
export function showAllSubAccount(data) {
    return axios.get(global.api + '/trader/control/showAllSubAccount', data);
}
/**
 *  基金经理工作台 - 获取账号下所拥有产品及其证券账户和子账户列表
 */
export function getAccountSubs(data) {
    return axios.get(global.api + '/fundManager/balance/getAccount', data);
}

// 根据时间获取指数数据
export function showIndexKline(params) {
    return axios.post(global.api + '/stock/showIndexKline', params);
}
export function showIndexKlineByDate(params) {
    return axios.post(global.api + '/stock/showIndexKlineByDate', params);
}
// 个性化指数
export function customIndexKline(params) {
    return axios.post(global.api + '/stock/customIndexKline', params);
}
export function customIndexKlineBasePlate(params) {
    return axios.post(global.api + '/stock/customIndexKlineBasePlate', params);
}
export function customIndexKlineBaseBellwetherStock(params) {
    return axios.post(global.api + '/stock/customIndexKlineBaseBellwetherStock', params);
}
// 所有交易日期
export function getAllTradeDay(params) {
    return axios.get(global.api + '/stock/getAllTradeDay', { params });
}