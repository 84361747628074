import React, { useState } from 'react';
import {
  Row, Col, Select, Button, Space, Cascader, Popover, Segmented, InputNumber,
  Typography, TimePicker
} from 'antd';
import { ClearOutlined, DiffOutlined } from '@ant-design/icons';
import { typeValueOptions, labelValues } from '@/utils/utils2';
import { SyncButton } from '@/utils/utils';
import {
  base_plate_options, cascaderOptions_3, cascaderOptions_4, cascaderOptions_5,
  cas2_1, cas2_2, cascaderOptions
} from '@/utils/indexCodes';
import { NewRangePicker } from '@/view/common/widgets';
import _ from 'lodash';

const { Text } = Typography;
const allOptions = _.chain(cascaderOptions).filter(o => o.value === 'all').get('[0].children', []).value();
const calOptions = _.filter(cascaderOptions, o => o.value !== 'all' && o.value !== 'base');
const CUS_OPTIONS = [labelValues(['涨跌幅', 'rise']), labelValues(['板块', 'plate']), labelValues(['龙虎榜', 'lhb'])];
const CUS_INFO = {
  'rise': {
    path1: 'customPms.type', name1: '类型',
    path2: 'customPms.duration', name2: '天数区间',
    path3: 'customPms.pushNum', name3: '个数限制',
    path4: 'customPms.rate', name4: '涨跌幅',
  },
  'plate': {
    path1: 'cusPlate.type', name1: '类型',
    path2: 'cusPlate.plateNum', name2: '板块数量',
    path3: 'cusPlate.pushNum', name3: '个数限制',
    path4: 'cusPlate.endTime', name4: '结束时间',
  },
  'lhb': {
    path1: 'cusLhb.type', name1: '类型',
    path3: 'cusLhb.pushNum', name3: '个数限制',
    path2: 'cusLhb.rate', name2: '频率限制',
    path4: 'cusLhb.endTime', name4: '结束时间',
  },
};
// index 指数bar
const IndexBar = ({
  isAlpha = false,
  isNewPage = false,
  values = {},
  option45 = [],

  onBaseChange,
  onClear,
  onRepeat,
  onValueChange,
  onCustomIndex,
  onSync,
  onRandom,
  onSingleDateChagne,
  onConfirm
}) => {
  const [cusType, setCusType] = useState('rise')
  const [timeVal, setTimeVal] = useState(null)

  const selectProps = { style: { width: 116 }, size: 'small' };
  const cascaderProps = { style: { width: 136 }, size: 'small' };
  const input_props = { size: 'small', style: { width: 120 }, step: 1 };

  const ClearButton = <Button icon={<ClearOutlined />} danger type='text' size='small' onClick={onClear} />;
  const BaseIndexSelect = <Select
    {...selectProps}
    value={values.idx}
    options={base_plate_options}
    onChange={onBaseChange}
  />;
  const RepeatBtn = <Button type='text' size='small' onClick={onRepeat}>重复</Button>;
  // 类型配置info
  const cusTypeInfo = _.get(CUS_INFO, cusType, {});
  const content = (
    <div>
      <div style={{ marginBottom: 12 }}>
        {/* <Text strong>自定义指数</Text> */}
        <Segmented
          options={CUS_OPTIONS} size='small'
          value={cusType}
          onChange={(v) => setCusType(v)}
        />
      </div>
      <Space direction='vertical' size='small'>
        <Space>
          <Text type='secondary'>{cusTypeInfo.name1}</Text>
          <Select style={{ width: 155 }} size='small'
            value={_.get(values, cusTypeInfo.path1)}
            options={typeValueOptions}
            onChange={(v) => onValueChange(cusTypeInfo.path1, v)} />
        </Space>
        <Space>
          <Text type='secondary'>{cusTypeInfo.name2}</Text>
          <InputNumber {...input_props}
            value={_.get(values, cusTypeInfo.path2)}
            onChange={v => onValueChange(cusTypeInfo.path2, v)}
          />
        </Space>
        <Space>
          <Text type='secondary'>{cusTypeInfo.name3}</Text>
          <InputNumber {...input_props}
            value={_.get(values, cusTypeInfo.path3)}
            onChange={v => onValueChange(cusTypeInfo.path3, v)}
          />
        </Space>

        {cusType !== 'rise' ? <Space>
          <Text type='secondary'>{cusTypeInfo.name4}</Text>
          <TimePicker
            size='small'
            value={timeVal}
            onChange={(time, timeString) => {
              setTimeVal(time);
              onValueChange(cusTypeInfo.path4, timeString);
            }}
          />
        </Space> : <Space>
          <Text type='secondary'>{cusTypeInfo.name4}</Text>
          <InputNumber {...input_props}
            addonAfter={'%'}
            value={_.get(values, cusTypeInfo.path4)}
            onChange={v => onValueChange(cusTypeInfo.path4, v)}
          />
        </Space>}
      </Space>

      <Button block style={{ marginTop: 26 }} type='primary'
        disabled={values.cloading}
        onClick={() => onCustomIndex(cusType)}
      >
        确定
      </Button>
    </div>
  );

  return <Row style={{ marginBottom: 22, marginTop: 12 }} justify={isAlpha ? 'end' : 'start'}>
    <Col span={isAlpha ? 14 : 0} style={{ textAlign: 'right' }}>
      <Space size='small'>
        <Text type='secondary'>{'基础'}</Text>
        {BaseIndexSelect}
        <Text type='secondary'>{'全部'}</Text>
        <Cascader
          {...cascaderProps}
          value={values.idx2Arr}
          options={allOptions}
          onChange={(v) => {
            onValueChange('idx2Arr', v);
            onValueChange('idx2', _.last(v), true);
          }}
        />
        <Popover content={content} trigger="click" placement='bottom'>
          <Button type='text' size='small' icon={<DiffOutlined />}
            disabled={values.type === 'TODAY' ? true : false}
            loading={values.cloading}
          />
        </Popover>
        <Text type='secondary'>{'分类'}</Text>
        <Cascader
          {...cascaderProps}
          value={values.idx3Arr}
          options={calOptions}
          onChange={(v) => {
            onValueChange('idx3Arr', v);
            onValueChange('idx3', _.last(v), true);
          }}
        />
        <SyncButton onClick={onSync} />
        {ClearButton}
        <Button type='text' size='small' onClick={onRandom}>随</Button>
        {isAlpha && RepeatBtn}
      </Space>
    </Col>
    <Col span={isAlpha ? 10 : 18} style={{ textAlign: 'center' }}>
      {values.type === 'TODAY' ? <NewRangePicker
        isSingle
        value={values.date}
        size='small'
        onSelect={onSingleDateChagne}
      /> : <Space size='small'>
        {isNewPage && <Text type='secondary'>指数</Text>}
        {isNewPage && BaseIndexSelect}

        {isAlpha ? <Segmented
          options={option45} size='small'
          value={values.idx45_type}
          onChange={(v) => onValueChange('idx45_type', v)}
        /> : <Text type='secondary'>基础</Text>}

        {values.idx45_type === option45[0] ? <Cascader
          {...cascaderProps}
          value={values.idx4Arr}
          options={isAlpha ? cascaderOptions_3 : cas2_1}
          onChange={(v) => onValueChange('idx4Arr', v ?? [])}
        /> : <Cascader
          {...cascaderProps}
          changeOnSelect // 指数指标可以不完全选到最后一级
          value={values.idx6Arr}
          options={cascaderOptions_5}
          onChange={(v) => onValueChange('idx6Arr', v ?? [])}
        />}
        <Cascader
          {...cascaderProps}
          value={values.idx5Arr}
          options={isAlpha ? cascaderOptions_4 : cas2_2}
          onChange={(v) => onValueChange('idx5Arr', v ?? [])}// 点击取消选择，v=undefined，45Arr字段只能是数组类型
        />
        <Button type='link' size='small' onClick={onConfirm}>
          确定
        </Button>
        {isNewPage && ClearButton}
      </Space>}
    </Col>
  </Row>
};

export default IndexBar;