import { createLabelValue } from './indexCodes';
import moment from 'moment';
import _ from 'lodash';

const PRE_DAY_CN = '前一交易日';

const STORAGE_TYPE = {
  '1': 'url_string',
  '2': 'trading_status', // 全局存储交易状态，全局缓存数据用
  'cmp': 'compare_account', // 对比交易时存储上一次账户id
};
const saveStorage = (type, val) => {
  try {
    localStorage.setItem(STORAGE_TYPE[type], val);
  } catch (error) { }
}
const getStorage = (type) => {
  try {
    let val = localStorage.getItem(STORAGE_TYPE[type]);
    return val ?? ''
  } catch (error) { }
}
// 创建简单表格col item，返回每个列信息的 object
const simpleColumns = (pmArray = ['', ''], rest = {}) => {
  let obj = {
    title: pmArray[0],
    dataIndex: pmArray[1],
    key: pmArray[1],
    ...rest
  }
  if (pmArray[2]) {
    obj.width = pmArray[2];
  }
  return obj;
}
// 固定宽度图表计算总宽度
const calColumnsWidth = (cols) => {
  return _.chain(cols).map(c => _.get(c, 'width', 0)).sum().value();
}
// 验证有效的数据类型
const isValidArray = (array) => array && _.isArray(array) && _.size(array) > 0 ? true : false;
const isValidObj = (object) => object && _.isObject(object) && _.size(object) > 0 ? true : false;
const isValidNumber = (number) => number && _.isNumber(number) ? true : false;
const isValidString = (string) => string && _.isString(string) && string !== '' ? true : false;
const isTruthy = (val) => Boolean(val);

// 今昨前 仓位颜色
const DAY_COLOR = {
  'today': 'red',
  'yesterday': '#ff8600',
  'preday': '#00a1a8',
}
// 推送名称和代码
const TYPE_VALUE = {
  '6s滚动全量': 200, //流入(全量)-
  'Alpha标准': 201, //流入(限制)-
  'Alpha或量标准': 202,//测试-
  'Alpha或量宽标': 203,//测试2-
  'Alpha价限': 204,//测试3-
  'Alpha去限': 205,//测试4-S
  'Alpha升标': 206,//测试5-
  'Alpha标准规则增强': 207,
  'Alpha宽限': 208,
  'Alpha首推': 209,
  'Alpha卖出': 210,
  'Alpha标准大单成交额': 211,
  'Alpha去重': 212,
  'Alpha量限测试': 213,
  'Alpha或量标准宽限': 214,
  'Alpha或量标准双限': 215,
}
let typeValueOptions = [];
let VALUE_TYPE = {}; // 推送值 key：名称 value
Object.keys(TYPE_VALUE).map((k, i) => {
  typeValueOptions[i] = createLabelValue([k, TYPE_VALUE[k]]);
  VALUE_TYPE[TYPE_VALUE[k]] = k;
});

// 时间排序及创建timeKey字段; pure_date=数组内为时间字符串，不是对象格式
const format_obj = {
  'd': 'YYYY-MM-DD',
  's': 'HH:mm:ss', 'fs': 'YYYY-MM-DD HH:mm:ss',
  'm': 'HH:mm', 'fm': 'YYYY-MM-DD HH:mm',
  'fms': 'YY-MM-DD HH:mm:ss:SSS', 'ms': 'HH:mm:ss:SSS',
};
const key_obj = {
  d: 'd', s: 's', m: 'm', ms: 'millisecond',
  fs: 's', fm: 'm', fms: 'millisecond',
};
const createDatasTimeKey = (array = [], compareKey = '', timeString = 'd', orders = 'desc') => {
  let final = [];
  if (isValidArray(array) && isValidString(compareKey)) {
    final = array.map(n => {
      const cval = compareKey === 'pure_date' ? n : _.get(n, compareKey);
      return _.assign(compareKey === 'pure_date' ? { 'date': n } : { ...n }, {
        'timeKey': moment(cval).diff(moment(), key_obj[timeString]),
        'formatDate': moment(cval).format(format_obj[timeString] || 'YYYY-MM-DD'),
      })
    });
  }
  return _.orderBy(final, ['timeKey'], [orders]);
}
// 指定日期之后的日期禁止点击
const disabledDateFunc = (current, targetDate) => {
  const tooLate = targetDate ? current.diff(moment(targetDate), 'days') > 0 : null;
  return !!tooLate;
};
// utils2 copy function：  创建单一item的对象，返回 {label,value,children,...rest}
function labelValues(array, rest) {
  let baseObj = { label: array[0], value: array[1] };
  if (array[2]) {
    baseObj.children = array[2];
  }
  if (isValidObj(rest)) { // 可添加其他字段
    baseObj = _.assign(baseObj, rest);
  }
  return baseObj;
}
//新增10个静态颜色; 10个方便下一轮颜色方便计算
const STATIC_RATE_COLOR = [
  '#438497', '#f07e00', '#bbca21', '#006b7f', '#e95268',
  '#a00080', '#cf8c00', '#97a88e', '#e82200', '#f56000',
];
// range范围与最大最小值重合时，返回true，说明当前为全部时间轴
function isFullTimeRange(slider, tarr) {
  const sliderValueOne = _.get(slider, '[0]', 0);
  const sliderValueTwo = _.get(slider, '[1]', 0);
  const timeValueFirst = _.get(tarr, '[0]', 0);
  const timeValueLast = _.last(tarr);
  let finalBool = false;
  if (sliderValueOne === timeValueFirst && sliderValueTwo !== 0 && timeValueLast && timeValueLast === sliderValueTwo) {
    finalBool = true;
  }
  return finalBool
};

// 计算当前是否是开盘时间
const isMarketTrading = (isTradeDay = true) => {
  const TOTAY = moment().format('YYYY-MM-DD');
  const OPEN_TIME = moment(TOTAY + ' 9:30');
  const CLOSE_TIME = moment(TOTAY + ' 15:00');
  const currentTime = moment();
  let isGet = false;
  const diffOpenMin = currentTime.diff(OPEN_TIME, 'm');
  const diffCloseMin = currentTime.diff(CLOSE_TIME, 'm');
  if (diffOpenMin >= 0 && diffOpenMin <= 120) {
    isGet = true;
  };
  if (diffCloseMin >= -120 && diffCloseMin <= 0) {
    isGet = true;
  };
  // 非交易日直接返回false
  if (!isTradeDay) {
    isGet = false;
  }
  return isGet;
};

export {
  saveStorage, getStorage, // storage 存储
  isValidArray, isValidObj, isValidNumber, isValidString, isTruthy, // 属性检测
  simpleColumns, calColumnsWidth,  // 表格类
  // 静态配置
  typeValueOptions, VALUE_TYPE, TYPE_VALUE,
  DAY_COLOR, STATIC_RATE_COLOR,
  PRE_DAY_CN,
  // function
  createDatasTimeKey,
  disabledDateFunc,
  labelValues,
  isFullTimeRange,
  isMarketTrading
}