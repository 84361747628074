import React from 'react';
import { Row, Col, Slider, DatePicker, Typography, Divider } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { PRE_DAY_CN, isValidString, isValidArray, disabledDateFunc } from '@/utils/utils2';
import moment from 'moment';
import _ from 'lodash';

const { RangePicker } = DatePicker;
const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');
// 封装日期范围选择组件
const NewRangePicker = ({
  isSingle = false,
  dates = [],
  value = '',
  singleValidDate = [],
  size = 'normal',
  disabled = false,
  restRange = {},

  onSelect
}) => {
  const sevenDay = [moment().subtract(7, 'd'), moment()];
  const thirtyDay = [moment().subtract(30, 'd'), moment()];
  const thisMonth = [moment().startOf('month'), moment()];
  const threeMonth = [moment().subtract(90, 'd'), moment()];
  const yearFromNow = [moment(`${moment().year()}-01-01`), moment()];

  function onTimeChange(date, dateStrings, info) {
    const calType = _.get(info, 'range', '');
    if (calType === 'end') {
      onSelect(dateStrings, date);
    }
  }

  let datepicker_props = { format: "YYYY-MM-DD", size: size, disabled: disabled, disabledDate: (current) => disabledDateFunc(current, TODAY) };
  if (isValidArray(singleValidDate)) {
    datepicker_props.dateRender = (current) => {
      const style = {};
      if (singleValidDate.indexOf(current.format('YYYY-MM-DD')) !== -1) {
        style.border = '1px solid #1890ff';
        style.borderRadius = '50%';
      }
      return (
        <div className="ant-picker-cell-inner" style={style}>
          {current.date()}
        </div>
      );
    }
  }
  const newRangeValue = !dates[0] || !dates[1] ? null : [moment(dates[0]), moment(dates[1])];
  return isSingle ? <DatePicker
    value={isValidString(value) ? moment(value) : null}
    {...datepicker_props}
    onChange={(date, dateStrings) => {
      onSelect(dateStrings);
    }}
  /> : <RangePicker value={newRangeValue}
    onCalendarChange={onTimeChange}
    disabledDate={(current) => disabledDateFunc(current)}
    size={size}
    ranges={_.get(restRange, 'picker', '') === 'month' ? {} : {
      '7日': sevenDay, '30日': thirtyDay, '本月': thisMonth, '季度': threeMonth, '今年': yearFromNow
    }}
    {...restRange}
  />
};

// 滑动时间组件
const MainSlider = ({
  isNotToday = false,
  timeNameArray = [], // 名称数组
  timeArray = [], // index值数组
  sDisable = false,
  value = 0, // slider的值
  svalue = 0, // slider index的值
  empComp = <div style={{ height: 32 }}></div>,
  isFull = false,

  onSliderChange,
  onSliderAfterChange,
  onReset
}) => {
  const formatter = (value) => timeNameArray[value] || 'none';
  const firstdate = _.get(timeNameArray, `[1]`, '');
  const start = _.get(timeNameArray, `[${value[0]}]`, '');
  const end = _.get(timeNameArray, `[${value[1]}]`, '');
  const max = _.last(timeArray) || 0;
  const textProps = { strong: true, style: { fontSize: 13 } };
  // start会等于前一交易日，所以符合sliderValue=0及中文相同，则显示数组1位的有效日期
  if (isNotToday) {
    return (
      <Row align='middle'>
        <Col span={6}>
          <Text {...textProps}>开始日期: {value[0] === 0 && start === PRE_DAY_CN ? firstdate : start}</Text>
          <div style={{ height: 15, width: 15, cursor: 'pointer', marginRight: 4, float: 'right' }} onClick={onReset}>
            {isFull ? <></> : <RollbackOutlined style={{ color: '#9f9f9f' }} />}
          </div>
        </Col>
        <Col span={12}>
          <Slider
            min={0}
            max={max}
            disabled={sDisable}
            range={{ draggableTrack: true }}
            tipFormatter={formatter}
            value={svalue}
            onChange={onSliderChange}
            onAfterChange={onSliderAfterChange}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Text {...textProps}>结束日期: {end}</Text>
        </Col>
      </Row>
    )
  } else {
    return empComp
  }
};

const NewDivider = ({ size = 12 }) => <Divider style={{ margin: `${size}px 0` }} />;

export {
  NewRangePicker,
  MainSlider,
  NewDivider,
}