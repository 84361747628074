import React from 'react';
import {
  Row,
  Col,
  Table,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import { autoCol, createSingleColumns } from '@/utils/utils';
import { DAY_COLOR } from '@/utils/utils2';
import StatisticHeader from '@/utils/StatisticHeader';
import _ from 'lodash';

// 分类显示的key和name信息
const statisc_array = [
  { name: '推送总数', key: 'numBase' },
  { name: '涨停票', key: 'numLimit', key2: 'numLimitTrading', key2Color: DAY_COLOR['today'] },
  { name: '昨日涨停', key: 'ynumLimit', key2: 'ynumLimitTrading', key2Color: DAY_COLOR['yesterday'] },
  { name: '前日涨停', key: 'preYNumLimit', key2: 'preYNumLimitTrading', key2Color: DAY_COLOR['preday'] },
  { name: '全市场涨停票', key: 'numLimitTotal', key2: 'numLimitTotalTrading' },
  { name: '涨停命中率', key: 'rate' },
];
const renderStrongStock = (text, record) => {
  const isTrade = _.get(record, 'isTrading', false);
  return <Space size='small'>
    <Typography.Text type={isTrade ? 'danger' : ''} strong={isTrade}>{text}</Typography.Text>
    {/* <Button size='small' icon={<EyeOutlined />} disabled={_.get(record, 'uid') ? false : true} type='text' onClick={() => {
    _getRuleInfo(_.get(record, 'uid'), record);
  }} /> */}
  </Space>
}
/**
 * 单日统计数据及表格
 */
const DayTable = ({ infos, loading }) => {
  const columns = [
    {
      ...createSingleColumns(['股票代码', 'symbol', 120]),
      render: (text, record) => renderStrongStock(text, record)
    },
    {
      ...createSingleColumns(['名称', 'name', 120]),
      render: (text, record) => renderStrongStock(text, record)
    },
    { ...createSingleColumns(['板块', 'plate', 90]) },
    { ...createSingleColumns(['类型', 'market', 90]) },
    {
      ...createSingleColumns(['时间', 'times', 150]),
      render: (text, record) => _.get(record, 'date', '') + '  ' + _.get(record, 'time', '')
    },
  ]

  return <div style={{ minHeight: 680 }}>
    <Row style={{ marginTop: 8, marginBottom: 15 }}>
      {statisc_array.map((n, i) => { // 单日数据已计算，*100显示百分比即可
        let getVal = _.get(infos, n.key, '');
        const getKey2 = _.get(n, 'key2', null);
        const key2Value = getKey2 ? _.get(infos, n.key2, '') : null; //区分盘中及非盘中的不同key
        if (n.key === 'rate') {
          getVal = _.round(getVal * 100, 2) + '%'
        }

        return <Col {...autoCol([4])} key={n.key + i}>
          <StatisticHeader
            title={n.name}
            value={getVal || 0}
            fSize={21}
            stProps={key2Value ? {
              suffix: <Tooltip title={key2Value ? '盘中: ' + _.get(infos, n.key2, '') + '只（不包含收盘涨停）' : null}>
                <CaretUpOutlined style={{ color: _.get(n, 'key2Color', 'black'), fontSize: 12 }} />
                <span style={{ color: _.get(n, 'key2Color', 'black') }}>{key2Value}</span>
              </Tooltip>
            } : null}
          // isPrefix={_.get(n, 'isPrefix', false)}
          />
        </Col>
      })}
    </Row>

    <Table
      rowKey={'uid'}
      loading={loading}
      dataSource={_.get(infos, 'newDTO', [])}
      columns={columns}
      size='small'
      scroll={{ x: 620, y: 505 }}
      pagination={{
        pageSize: 20,
        simple: true
      }}
    />

  </div>
}

export default DayTable;