import { getAllTradeDay } from '@/api/workbench';
import { isAllStockHoliday } from '@/api/stock';
import { action, observable, makeObservable, autorun } from 'mobx';
import { getval, setVal } from './server';
import { isValidObj, createDatasTimeKey, isMarketTrading } from '@/utils/utils2';
import moment from 'moment';
import _ from 'lodash';

class Store {
  constructor() {
    makeObservable(this)
  }
  // 登陆令牌
  @observable userLogin = getval('token');
  // 用户信息
  @observable userInfo = getval('userInfo');
  // 账户下证券账户及子账户列表
  @observable mobxAccountSubs = getval('mobxAccountSubs');
  // 当前是否在盘中
  @observable inTrading = 'rest';
  // 首页产品等数据
  @observable assetsData = {};
  // tamp管理员首页模块数据
  @observable assetIndexData = {};
  @observable stockAnalysis = {};
  // 数据缓存
  @observable indexCacheVal = {}; // 指数缓存
  // 所有交易日期
  @observable tradeDates = [];
  // 当前推送list数据
  @observable pushInfo = [];

  // 登陆
  @action landLogin = (token) => {
    this.userLogin = token;
    setVal('token', token);
  }
  // 退出登陆
  @action cancelLogin = () => {
    this.userLogin = null;
    localStorage.removeItem('token');
    localStorage.removeItem('menuKey');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('mobxAccountSubs');
  }
  // 登陆接口保存用户信息
  @action landUserInfo = (userInfo) => {
    this.userInfo = userInfo;
    setVal('userInfo', userInfo);
  }
  // 基金经理 - 保存账户下证券账户及子账户列表
  @action _mobxAccountSubs = (data) => {
    this.mobxAccountSubs = data;
    setVal('mobxAccountSubs', data);
  }
  @action _isAllStockHoliday = async () => {
    const TOTAY = moment().format('YYYY-MM-DD');
    const holiRes = await isAllStockHoliday({ beginDate: TOTAY, endDate: TOTAY });
    if (_.get(holiRes, 'code') === '200') {
      const is_trade_day = !_.get(holiRes, 'data.isAllHoliday');
      let globalTrading = 'rest' // 默认休市
      if (is_trade_day) { // isAllHoliday = false 代表今日开盘 
        // console.log('距离开:' + diffOpenMin + '   距离闭：' + diffCloseMin)
        let isGet = isMarketTrading()
        if (isGet) { // 满足时间条件修改为trading 
          globalTrading = 'trading';
        }
      };
      this.inTrading = { 'tradeDay': is_trade_day, 'trading': globalTrading };
    };
  }
  // 首页全局缓存数据
  @action changeAssetsData = async (data) => {
    this.assetsData = data;
  }
  @action changeAssetIndex = async (data) => {
    this.assetIndexData = data;
  }
  @action changeStockAnalysis = async (data) => {
    this.stockAnalysis = data;
  }
  @action changePushInfo = async (data) => {
    this.pushInfo = data;
  }
  // 指数数据缓存
  @action changeIndexCache = async (data) => {
    this.indexCacheVal = data;
  }
  // 获取所有交易日期
  @action _getAllTradeDay = async () => {
    const year = moment().year();
    const res = await getAllTradeDay({ year: `${year - 2},${year - 1},${year}` });
    if (_.get(res, 'code', '') === '200') {
      let orders = createDatasTimeKey(
        _.get(res, 'data', []),
        'pure_date',
        'd',
        'asc'
      );
      let final = [];
      // 增加周的信息，间隔日>1则周增加；
      let week_num = 1; let wstart = ''; // 每周的开始日期
      let seaStart = '';
      let curYear = moment(_.get(orders, '[0].date')).year();
      orders.map((n, i) => {
        let cur = _.get(n, 'date');
        let next = _.get(orders, `[${i + 1}].date`);
        let diff = next ? moment(cur).diff(moment(next), 'd') : 0;
        const thisSeason = moment(cur).quarter(); const nextSeaon = moment(next).quarter();
        const splits = _.split(cur, '-');
        if (i === 0) {
          wstart = splits[1] + splits[2];
          seaStart = splits[1] + splits[2];
        }
        if (parseFloat(splits[0]) !== curYear) { // 跨年度，周重新计算
          week_num = 1;
          curYear = parseFloat(splits[0]);
        }
        final.push(_.assign(n, {
          'week': week_num,
          'start': wstart,
          'season': thisSeason,
          'seaStart': seaStart,
          'month': moment(cur).month()  // month()从0 开始
        }));
        // 交易日大于1则跨周末，周递增
        const nex_split = _.split(next, '-');
        if (Math.abs(diff) > 1) {
          week_num++;
          wstart = nex_split[1] + nex_split[2];
        };
        if (nextSeaon !== thisSeason) {
          seaStart = nex_split[1] + nex_split[2];
        }
      })
      this.tradeDates = final;
    }
  }
}

export const store = new Store();

autorun(() => {
  // console.log(`登陆${store.userInfo}`)
})
