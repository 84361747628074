import React, { useState } from 'react';
import { Row, Col, Menu, Space, Select, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { COMMON_INDEX_2 } from '@/utils/indexCodes';
import { TYPE_VALUE } from '@/utils/utils2';
import { SyncButton } from '@/utils/utils';
import { NewRangePicker } from '@/view/common/widgets';
import { useReactive } from 'ahooks';
import moment from 'moment';
import _ from 'lodash';

const { Option } = Select;
/**
 * 涨停统计参数修改组件
 * 区间和单日日期统一组件，所以区分单日和区间参数，统一用默认值，之后各自state进行维护
 * 上层_showLimitTradingSignal是唯一获取接口，所以要进行参数区分，减少特殊处理的复杂程度
 */
const LimitBars = ({
  type = '', // 区分是区间控制bar还是单日控制bar
  options = [],
  today = '',
  todayRange = [],
  defaultPms = {},
  searchOptions = [],
  frqOptions = [],

  onGet,
  //onClear,
}) => {
  const [pms, setPms] = useState(defaultPms);
  const barState = useReactive({ // 与外层相同的初始参数state
    type: 'Alpha标准',
    range: todayRange,
    date: today,
    searchType: 0,
    num: 1,
    ids: { name: _.last(COMMON_INDEX_2).name, value: _.last(COMMON_INDEX_2).value }
  });
  // 控制 bar 修改参数
  function onParamsChange(key, value) {
    let params = _.cloneDeep(pms);
    let rangeDateChange = false;
    if (key === 'date') {
      params['start'] = value + ' 00:00:00';
      params['end'] = value + ' 00:00:00';
    } else if (key === 'range') {
      params['start'] = value[0] + ' 00:00:00';
      params['end'] = value[1] + ' 00:00:00';
      rangeDateChange = true; // 区间数据切换要情况历史数据
    } else {
      params[key] = value;
    }

    setPms(params);
    onGet(type, params, rangeDateChange);
  }

  const menu = (
    <Menu
      items={COMMON_INDEX_2.map(n => {
        return {
          label: <a onClick={() => {
            barState.ids.name = n.name;
            barState.ids.value = n.value;
            onParamsChange('indexCode', n.value)
          }}>{n.name}</a>,
          key: n.value
        }
      })}
    />
  );
  // 左右切换单日数据组件
  // const renderDateChange = (type) => {
  //   const isLeft = type === 'left' ? true : false;
  //   const btnIcon = isLeft ? <LeftOutlined /> : <RightOutlined />;
  //   const curIndex = dateState.array.indexOf(dateState.val);
  //   const disBtn = isLeft ? curIndex === 0 : curIndex === (_.size(dateState.array) - 1);
  //   return <Button
  //     size='small'
  //     icon={btnIcon}
  //     type='text'
  //     disabled={disBtn}
  //     onClick={() => {
  //       if (isLeft) {
  //         dateState.val = dateState.array[curIndex - 1];
  //       } else {
  //         dateState.val = dateState.array[curIndex + 1];
  //       }
  //       let findex = _.findIndex(tradeRangeList, o => o.date === dateState.val);
  //       setTradeInfo(tradeRangeList[findex]);
  //       setUpdateCount(updateCount + 1)
  //     }}
  //   />
  // };

  const dateValue = barState.date !== '' ? moment(barState.date) : null;
  const newRangeValue = !barState.range[0] || !barState.range[1] ? null : [moment(barState.range[0]), moment(barState.range[1])];
  const isSingle = type === 'single' ? true : false;
  return (
    <Row justify='space-between' align='middle' style={{ marginBottom: 22 }}>
      <Col span={24}>
        <Space>
          <Select size='small' style={{ width: 115 }} value={barState.type}
            onChange={(val) => {
              barState.type = val;
              onParamsChange('type', TYPE_VALUE[val]);
            }}>
            {options.map(k => <Option key={k} value={k}>{k}</Option>)}
          </Select>
          <Select defaultValue={0} size='small' value={barState.searchType} style={{ width: 85, marginRight: 6 }}
            options={searchOptions}
            onChange={(v) => {
              barState.searchType = v;
              onParamsChange('searchType', v);
            }}
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <a style={{ color: 'black' }}>
              <Space size='small'>
                {barState.ids.name}
                <DownOutlined style={{ color: '#c7c7c7' }} />
              </Space>
            </a>
          </Dropdown>
          {!isSingle && <Select defaultValue={0} size='small' value={barState.num} style={{ width: 75 }}
            options={frqOptions}
            onChange={(v) => {
              barState.num = v;
              onParamsChange('num', v);
            }}
          />}
          <NewRangePicker
            isSingle={isSingle}
            dates={barState.range}
            value={barState.date}
            size='small'
            onSelect={(dateStrings) => {
              if (isSingle) {
                barState.date = dateStrings;
                onParamsChange('date', dateStrings);
              } else {
                barState.range = dateStrings
                onParamsChange('range', dateStrings);
              }
            }}
          />
          {isSingle && <SyncButton onClick={() => onGet(type, pms)} />}
        </Space>
      </Col>
    </Row>
  )
}

export default LimitBars