import React, { useEffect, useState } from 'react';
import {
  PageHeader, Card, Row, Col, Table, Input, Button, message,
  Select, Segmented, InputNumber, Space, Typography
} from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { fundFilter, fundFilterByName } from '@/api/stock';
import { RateText, validateResponse, colWidth } from '@/utils/utils';
import { isValidArray, isValidString, isTruthy, simpleColumns, labelValues, createDatasTimeKey } from '@/utils/utils2';
import { base_options } from '@/utils/indexCodes';
import { useReactive, useUnmount } from 'ahooks';
import { NewDivider } from '@/view/common/widgets';
import ExportJsonExcel from 'js-export-excel';
import _ from 'lodash';
import './pages.scss';

const { Text } = Typography;
const { Search } = Input;
const SOPTIONS = [labelValues(['名称', 'name']), labelValues(['范围', 'range'])];
const stringCode = (str) => isValidString(str) ? parseFloat(String(str).charCodeAt()) : 0;
// 私募搜索页面
const SearchFund = (props) => {
  const [funds, setFunds] = useState([]);
  const [exLoading, setExLoading] = useState(false);
  const [durFilter, setDurFilter] = useState([]);
  const [update, setUpdate] = useState(0);
  const fundState = useReactive({
    type: 'name', symbol: '', searchVal: '', rate: 0, loading: false,
    stype: 'name', redoShow: false,
  });
  let isUnmont = false;

  useUnmount(() => {
    isUnmont = true;
  });

  // 获取回测数据
  async function _search(name) {
    fundState.loading = true;
    let params = fundState.type === 'range' ? {
      'range': fundState.rate / 100,
      'index': fundState.symbol,
    } : { name };
    let isValid = true;
    _.keys(params).map(keyname => {
      if (!isTruthy(params[keyname])) {
        isValid = false;
      };
    });
    if (!isValid) {
      message.info('请填写正确的参数！');
      fundState.loading = false;
      return
    }
    const res = fundState.type === 'range' ? await fundFilter(params) : await fundFilterByName(params)
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data', []);
      let dursArr = [];
      let newData = getData.map(n => {
        const splitDate = _.split(n.duration, '--');
        if (dursArr.indexOf(n.duration) === -1) {
          dursArr.push(n.duration);
        };
        return {
          ...n,
          'end': splitDate[0],
          'start': splitDate[1],
          'nameOrder': stringCode(n.jjjc[0]) + stringCode(n.jjjc[1])
        };
      });
      setDurFilter(dursArr.map(n => { return { text: n, value: n } }));
      setFunds(createDatasTimeKey(newData, 'end'));
      fundState.stype = fundState.type;
      fundState.redoShow = false;
    }
    fundState.loading = false;
  }

  const columns = [
    simpleColumns(['代码', 'jjdm', 95]),
    {
      ...simpleColumns(['基金简称', 'jjjc', 110]),
      sorter: (a, b) => a.nameOrder - b.nameOrder,
    },
    {
      ...simpleColumns(['收益率(%)', 'rate', 105]),
      sorter: (a, b) => a.rate - b.rate,
      render: (text) => <RateText rate={text} />
    },
    {
      ...simpleColumns(['指数收益率(%)', 'indexRate', 125]),
      sorter: (a, b) => a.indexRate - b.indexRate,
      render: (text) => <RateText rate={text} />
    },
    {
      ...simpleColumns(['区间', 'duration', 140]),
      sorter: (a, b) => a.timeKey - b.timeKey,
      filters: durFilter,
      onFilter: (value, record) => record?.duration?.includes(value),
    },
  ];
  // 切换保存上次搜索记录
  useEffect(() => {
    if (fundState.type === 'name' && isValidString(fundState.searchVal)) {
      fundState.redoShow = true;
    }
  }, [update]);

  // 导出
  function exportExcel() {
    let option = {}; let datas = [];
    if (!isValidArray(funds)) {
      setExLoading(false);
      message.info('暂无数据导出！');
      return;
    }
    funds.map(n => {
      let exItem = {};
      columns.map(col => {
        exItem[_.get(col, 'title')] = _.get(n, col.key, '');
      });
      datas.push(exItem);
    });
    // console.log('todayDescList', todayDescList)
    const colName = columns.map(n => n.title);
    const colWidth = columns.map(n => _.round(n.width / 13));
    option.fileName = `私募基金`;
    option.datas = [
      {
        sheetData: datas,
        sheetName: 'sheet',
        sheetFilter: colName,
        sheetHeader: colName,
        columnWidths: colWidth
      }
    ];
    //console.log(option);
    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
    setExLoading(false);
  };

  const newCol = fundState.stype === 'name' ? [columns[0], columns[1], columns[2], columns[4]] : columns;
  return <>
    <PageHeader
      title={'私募搜索'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }} style={{ minHeight: 620 }}>
      <Row align='middle' justify='space-between'>
        <Col span={12}>
          <Space>
            <Segmented options={SOPTIONS} value={fundState.type}
              onChange={v => {
                fundState.type = v;
                setUpdate(_.round(update + 0.1, 1));
              }}
            />
            {fundState.type === 'range' && <InputNumber style={{ width: 125 }} addonAfter="%"
              value={fundState.rate}
              onChange={v => fundState.rate = v}
            />}
            {fundState.type === 'range' && <Select style={{ width: 158 }}
              value={fundState.symbol}
              options={base_options}
              onChange={(v) => fundState.symbol = v}
            />}
            {fundState.type === 'range' && <Button type='primary' loading={fundState.loading}
              onClick={() => _search()}
            >
              确定
            </Button>}
            {fundState.type === 'name' && <Search
              placeholder="搜索私募名称"
              onSearch={(value) => {
                fundState.searchVal = value;
                _search(value);
              }}
              loading={fundState.loading}
              allowClear
              enterButton
            />}
            {fundState.redoShow && isValidString(fundState.searchVal) && <Button type='text'
              icon={<RollbackOutlined />}
              onClick={() => _search(fundState.searchVal)}
            >
              {fundState.searchVal}
            </Button>}
          </Space>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button loading={exLoading} disabled={_.size(funds) === 0}
            onClick={() => {
              setExLoading(true);
              exportExcel();
            }}>
            导出
          </Button>
        </Col>
      </Row>

      <NewDivider />

      <Table
        dataSource={funds}
        rowKey={'jjdm'}
        columns={newCol}
        size='small'
        bordered
        scroll={{ x: colWidth(newCol), y: 690 }}
        pagination={false}
        title={() => <Text strong>{_.size(funds) > 0 ? '共计：' + _.size(funds) : ''}</Text>}
      />
    </Card>
  </>
}

export default SearchFund; 