import { COMMON_CHART } from '@/view/common/Components/chartsInfoUtils';
import _ from 'lodash';
import './comp2.scss';

const BASE_GRID = { left: '8%', right: '6%', bottom: '10%', top: '10%' };
const YAXIS_GRID = { left: '8%', right: '8%', bottom: '10%', top: '10%' };
const NORM_LINE_CHARTS = {
  xAxis: { name: '时间', type: 'category', data: [] },
  yAxis: { type: 'value' },
  series: [],
  tooltip: { trigger: 'axis' },
  grid: BASE_GRID
};

const LIMIT_RANGE_OPTIONS = {
  'rangeCategory': { // 涨停分类折线图
    xAxis: { type: 'category', data: [] },
    yAxis: [
      { type: 'value', splitNumber: 3 },
      { type: 'value', name: '波幅', position: 'right', ...COMMON_CHART.rateY }
    ],
    series: [],
    tooltip: { trigger: 'axis' },
    grid: YAXIS_GRID,
  },
  'rangeSum': { // 涨停累计柱状图
    legend: {},
    tooltip: {},
    dataset: { source: [] },
    xAxis: { type: 'category' },
    yAxis: {},
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
    grid: BASE_GRID,
  },
  'rangeSumDay': NORM_LINE_CHARTS // 涨停区间折线图
}

const EXTRA_OPTIONS = {
  'indexChart': {
    grid: BASE_GRID,
    legend: { data: [], left: 105 },
    xAxis: { type: 'category', ...COMMON_CHART.rateX },
    yAxis: { type: 'value', ...COMMON_CHART.rateY },
    series: [],
    tooltip: { trigger: 'axis', },
  },
  'indexRetreat': {
    grid: BASE_GRID,
    xAxis: { type: 'category', ...COMMON_CHART.rateX },
    legend: { data: [], left: 135 },
    yAxis: {
      name: '回撤',
      type: 'value',
      min: -10,
      max: 0,
      splitNumber: 5,
      ...COMMON_CHART.rateY
    },
    series: [],
    tooltip: { trigger: 'axis' },
  },
  'limitBar': {
    xAxis: {
      type: 'category',
      data: ['涨停票', '全市场涨停票', '推送总数'],
    },
    yAxis: { type: 'value', splitNumber: 4 },
    series: [
      {
        name: '统计',
        data: [
          { value: 0, itemStyle: { color: 'red' } },
          { value: 0, itemStyle: { color: '#CA7A2C' } },
          { value: 0, itemStyle: { color: '#336774' } }
        ],
        type: 'bar',
        lineStyle: { color: '#ED7B35' },
        itemStyle: { color: '#ED7B35' },
      }
    ],
    tooltip: { trigger: 'axis' },
    grid: BASE_GRID
  },
  'plateBar': {
    tooltip: { trigger: 'item' },
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value' },
    series: [
      {
        name: '板块',
        type: 'bar',
        label: {
          show: true, position: 'inside'
        },
        data: [],
        markPoint: { data: [] },
        itemStyle: { color: '#005889' }
      }
    ],
    dataZoom: [
      { type: 'inside', start: 0, end: 30 },
      { show: true, type: 'slider', top: '95%', start: 0, end: 30, height: 12, },
    ],
    grid: { left: '8%', right: '6%', bottom: '12%', top: '8%' },
  }
}

const INDEX_OPTIONS = {
  'avg': {
    grid: BASE_GRID,
    xAxis: { name: '时间', type: 'category', ...COMMON_CHART.rateX },
    yAxis: { name: '均线', type: 'value', ...COMMON_CHART.rateY },
    series: [],
    tooltip: { trigger: 'axis' },
  },
  'indexLine': {
    xAxis: { type: 'category', data: [] },
    legend: { data: ['指数'] },
    yAxis: [{ type: 'value', splitNumber: 5, scale: true, splitArea: { show: false }, splitLine: { show: false } },
    {
      type: 'value',
      name: '波幅(%)',
      position: 'right',
      ...COMMON_CHART.rateY,
      min: 0,
      max: 1,
    }],
    series: [{ ...COMMON_CHART.indexSeries, markPoint: { data: [] } }],
    dataZoom: [
      { type: 'slider', start: 0, end: 50, top: 365, height: 12, },
    ],
    tooltip: { trigger: 'axis' },
    grid: YAXIS_GRID,
  }
};

const UPDOWN_OPTION = {
  'range': {
    grid: { left: '6%', right: '6%', bottom: '16%', top: '8%' },
    // legend: { data: [], x: 'center', y: 'bottom', selected: {} },
    xAxis: { name: '时间', type: 'category', },
    yAxis: [
      { type: 'value' },
      { type: 'value', position: 'right', name: '指数', max: 1000, min: 0, axisLine: { show: false }, splitLine: { show: false }, },
    ],
    series: [],
    tooltip: { trigger: 'axis' },
  },
  'bar': {
    grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
    xAxis: { type: 'value', data: [] },
    yAxis: {
      type: 'category', data: [],
      axisLabel: { interval: 0, rotate: 50 },
      axisLine: { show: false },
    },
    series: [{ name: '统计', type: 'bar', label: { show: true }, data: [] }]
  },
  'rangeBar': {
    grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
    tooltip: { trigger: 'axis' },
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value' },
    series: [
      { name: '', type: 'bar', data: [], label: { show: false } }
    ]
  }
};

// 侧边tab切换
const SideTabs = ({ options = [], active = '', onSelect }) => <div className='aidxContent'>
  {options.map(n => <div key={n.value}
    className={active === n.value ? 'aidxTab-active' : 'aidxTab'}
    onClick={() => {
      if (active !== n.value) {
        onSelect(n.value);
      };
    }}
  >
    {n.label}
  </div>)}
</div>;

const UPDOWN_COLOR = { // 颜色数组从深到浅
  'redLevel': ['#ed002d', '#ff5777', '#ff7b94', '#ff97aa'],
  'greenLevel': ['#04b955', '#3cd781', '#7aefae', '#a4ffcc'],
  'up': 'red', 'flat': 'grey', 'down': 'green',
  'passive': '#ff922d', 'negtive': '#007db8'
};
const UPDOWN_TEXT = [
  { name: '涨', key: 'up', align: 'left' },
  { name: '平', key: 'flat', align: 'center' },
  { name: '跌', key: 'down', align: 'right' }
];
const INTERVAL_KEYS = [
  { key: 'limitNum', name: '涨停', colorPath: 'up', order: 1 },
  { key: 'intervalOne', name: '涨停-5%', colorPath: 'redLevel[1]', order: 3 },
  { key: 'intervalTwo', name: '5%-1%', colorPath: 'redLevel[2]', order: 4 },
  { key: 'intervalThree', name: '1%-0', colorPath: 'redLevel[3]', order: 5 },
  { key: 'flatPlate', name: '平盘', colorPath: 'flat', order: 6 },
  { key: 'intervalFour', name: '0--1%', colorPath: 'greenLevel[3]', order: 7 },
  { key: 'intervalFive', name: '-1%--5%', colorPath: 'greenLevel[2]', order: 8 },
  { key: 'intervalSix', name: '-5%-跌停', colorPath: 'greenLevel[1]', order: 9 },
  { key: 'downLimitNum', name: '跌停', colorPath: 'down', order: 11 },
];
const SPECIAL_ITEMS = [
  { key: 'up1', name: '>5%', sumKey: ['limitNum', 'intervalOne'], colorPath: 'redLevel[0]', order: 2 },
  { key: 'down1', name: '<-5%', sumKey: ['downLimitNum', 'intervalSix'], colorPath: 'greenLevel[0]', order: 10 },
  { key: 'passive', name: '正', sumKey: [0, 1, 2, 3].map(idx => INTERVAL_KEYS[idx].key), colorPath: 'passive', order: 12 },
  { key: 'negtive', name: '负', sumKey: [5, 6, 7, 8].map(idx => INTERVAL_KEYS[idx].key), colorPath: 'negtive', order: 13 },
];

// 两个数组相加返回结果
const arraySum = (array1 = [], array2 = []) => {
  let final = [];
  if (_.size(array1) > 0) {
    final = array1.map((n, i) => n + (array2[i] ?? 0));
  }
  if (_.size(array1) === 0 && _.size(array2) > 0) {
    final = array2.map((n, i) => n + (array1[i] ?? 0));
  }
  return final;
};

export {
  LIMIT_RANGE_OPTIONS,
  EXTRA_OPTIONS,
  INDEX_OPTIONS,
  NORM_LINE_CHARTS,
  UPDOWN_OPTION, UPDOWN_TEXT,
  UPDOWN_COLOR, INTERVAL_KEYS, SPECIAL_ITEMS,
  // 组件
  SideTabs,
  // 方法
  arraySum,
}