import React, { useState } from 'react';
import { PageHeader, Row, Col, Card, Segmented, Space, Divider, message, Spin, Typography } from 'antd';
// import { CheckCircleOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { getKLineDetail } from '@/api/stock';
import { autoCol, RateText } from '@/utils/utils';
import { labelValues, isValidArray, VALUE_TYPE } from '@/utils/utils2';
import { useMount, useReactive, useUpdateEffect } from 'ahooks';
import ExtraList from '../ExtraComp/ExtraList';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const FORMAT = "YYYY-MM-DD";
const TODAY = moment().format(FORMAT);
// const RANGE_DATE = [moment().subtract(30, 'd').format(FORMAT), TODAY];
const SEG_OPTIONS = [labelValues(['5日', '5']), labelValues(['2日', '2'])];
const SecText = ({ strong = false, txt = '' }) => {
  let text_props = {};
  if (strong) {
    _.set(text_props, 'strong', true);
  } else {
    _.set(text_props, 'type', 'secondary');
  }
  return <Text {...text_props}>{txt}</Text>
};
// 推送分时图页面
const TimeIndex = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [lists] = useState(_.get(props, 'location.state.list', []));
  const [rates] = useState(_.get(props, 'location.state.rates', {}));
  const [update, setUpdate] = useState(0);
  const [kvalue, setKvalue] = useState({});
  const timeState = useReactive({
    sdate: _.get(props, 'location.state.date', ''),
    loading: false, type: '5', typeName: _.get(VALUE_TYPE, _.get(props, 'location.state.tval'), ''),
    stockName: '', stockCode: 0, kval: [], preClose: 0
  });
  const [option, setoption] = useState({
    xAxis: {
      type: 'category',
      data: [],
      boundaryGap: false,
      axisLine: { onZero: false },
      splitLine: { show: false },
      min: 'dataMin',
      max: 'dataMax'
    },
    yAxis: [{
      name: '股价',
      scale: true,
      splitArea: { show: false },
      splitLine: { show: false },
    }, {
      type: 'value',
      name: '波幅(%)',
      position: 'right',
      axisLine: { show: true, symbol: ['none', 'arrow'], symbolSize: [8, 8], symbolOffset: 7 },
      splitArea: { show: true },
      splitLine: { show: false },
      min: 0,
      max: 1,
    }],
    series: [
      {
        name: '分钟', type: 'line', data: [], markPoint: {
          data: [{ type: 'max', name: 'Max' }]
        },
      },
    ],
    grid: { left: '8%', right: '8%', bottom: '14%', top: '10%' },
    dataZoom: [
      { type: 'inside', start: 0, end: 100 },
      { show: true, type: 'slider', bottom: '5%', start: 0, end: 100, height: 12 }
    ],
    tooltip: { trigger: 'axis' }
  });

  useMount(() => {
    // console.log(tradeDates);
    // console.log(handleBeginEnd('2024-02-'))
  });

  // 获取K线数据；分钟/日K
  async function _klineDetail() {
    if (timeState.stockCode) {
      timeState.loading = true;
      let params = {
        ...handleBeginEnd(timeState.sdate),
        symbol: timeState.stockCode,
        type: 'minute'
      }
      // console.log(params)
      const res = await getKLineDetail(params);
      if (_.get(res, 'code', '') === '200') {
        const is_same = moment(params.beginDate).isSame(params.endDate, 'D'); // 同一天不显示日期
        let timeList = []; let newValueList = [];
        let priceList = []; let fullList = [];
        if (isValidArray(_.get(res, 'data.pointList'))) {
          _.get(res, 'data.pointList', []).map(n => { // time open close high low revenue volume amount(成交额)
            const newTime = params.type === 'daily' ? moment(n[0]).format(FORMAT) : n[0];
            const values = _.drop(n);
            timeList.push(is_same ? moment(n[0]).format('HH:mm:ss') : newTime);
            priceList.push(parseFloat(n[2]));
            newValueList.push(values);
            fullList.push(_.concat([newTime], values));
          })
        }
        setKvalue({
          'time': timeList,
          'kval': priceList, // 只使用收盘价格
          'cal': {
            high: _.max(priceList),
            low: _.min(priceList),
            startPrice: _.get(res, 'data.preClose', 0)
          }
        });
        //tooltips计算振幅使用
        timeState.kval = fullList;
        timeState.preClose = _.get(res, 'data.preClose', 0);
        setUpdate(_.round(update + 0.1, 1));
      } else {
        message.info(_.get(res, 'message', '获取失败！'));
      }
      timeState.loading = false;
    }
  }
  // 计算开始结束日期
  function handleBeginEnd(date) {
    const countNum = { '5': 2, '2': 0 }; // 根据选择确定count其实位置
    const isValidDate = (ditem) => {
      return _.get(ditem, 'timeKey') < 0 || _.get(ditem, 'date') === TODAY ? true : false;
    }
    // 找到对应交易日，5日取前2+后2日的日期；2日则取当日及下一日；
    let findex = _.findIndex(tradeDates, o => o.date === date);
    let dateArray = [];
    let count = findex - countNum[timeState.type]; // 实际需要遍历的index值
    if (findex !== -1) {
      // Array创建5=[数组5] 创建2=[数组2]
      Array(parseInt(timeState.type)).fill('1').map((n, i) => {
        // 符合条件，timekey<0 = 今日之前，依次push数据
        if (isValidDate(tradeDates[count])) {
          dateArray.push(_.get(tradeDates, `[${count}].date`));
          count++
        } else { // 下一个交易日还没到，则向前找日期unshift到数组头部
          dateArray.unshift(_.get(tradeDates, `[${findex - i}].date`));
        }
      })
    }
    return { beginDate: _.head(dateArray) ?? '', endDate: _.last(dateArray) ?? '' }
  }

  useUpdateEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('timeindex_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });
    let newOption = _.cloneDeep(option);

    newOption.xAxis.data = _.get(kvalue, 'time', []);
    newOption.series[0].data = _.get(kvalue, 'kval', []);
    const getHigh = _.get(kvalue, 'cal.high', 0);
    const getLow = _.get(kvalue, 'cal.low', 0);
    const getStart = _.get(kvalue, 'cal.startPrice', 0);
    // 计算波幅
    const min = _.round(((getLow - getStart) / getLow) * 100, 2);
    const max = _.round(((getHigh - getStart) / getHigh) * 100, 2);
    newOption.yAxis[1].min = min > 0 ? 0 : min;
    newOption.yAxis[1].max = max < 0 ? 0 : max;

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update]);

  const chart_height = 486;
  return <>
    <PageHeader
      title={'推送分时分析'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
      onBack={() => props.history?.goBack()}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }} style={{ minHeight: 750 }}>
      <Row style={{ marginBottom: 8 }} align='middle'>
        <Col span={12}>
          <Space>
            <Segmented options={SEG_OPTIONS} value={timeState.type}
              onChange={v => {
                timeState.type = v;
                _klineDetail();
              }}
            />
            <Text strong>{timeState.sdate}</Text>
            <Text strong>{timeState.typeName}</Text>
          </Space>
        </Col>
      </Row>

      <Divider style={{ marginTop: 12 }} />

      <Row style={{ marginBottom: 6 }}>
        <Col {...autoCol([12])}>
          <Row align='middle'>
            <Col span={10}>
              <Space size='small'>
                <SecText txt={'推送平均:'} strong />
                <RateText rate={_.get(rates, 'costAverageRate')} />
                <SecText txt={'过滤平均:'} strong />
                <RateText rate={_.get(rates, 'filterAverageRate')} />
              </Space>
            </Col>
            <Col span={14} style={{ textAlign: 'right' }}>
              <SecText txt={'收益率'} />
              <Divider type='vertical' />
              <SecText txt={'推送价'} />
              <Divider type='vertical' />
              <SecText txt={'建仓收益率'} />
              <Divider type='vertical' />
              <SecText txt={'时间'} />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Text strong style={{ marginLeft: 12 }}>{timeState.stockName}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col {...autoCol([12])} style={{ height: chart_height + 150, overflowY: 'auto' }}>
          <ExtraList
            pageType='timeindex'
            current={timeState.stockCode}
            listData={lists}
            onStockClick={(stock, name) => {
              timeState.stockCode = stock;
              timeState.stockName = name;
              _klineDetail();
            }}
          />
        </Col>
        <Col {...autoCol([12])}>
          <Spin spinning={timeState.loading}>
            <div style={{ display: 'flex' }}>
              <div
                id={'timeindex_charts'}
                style={{ width: '100%', height: chart_height, marginTop: 45 }}
              />
            </div>
          </Spin>
        </Col>
      </Row>
    </Card>
  </>
}

export default TimeIndex;