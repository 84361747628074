import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import _ from 'lodash';
import * as echarts from 'echarts';

export default function ExtraPie(props) {
  const [option, setoption] = useState({
    legend: {},
    tooltip: {
      trigger: 'axis',
      showContent: false
    },
    dataset: {
      source: []
    },
    xAxis: { type: 'category' },
    yAxis: { gridIndex: 0 },
    grid: { top: '55%' },
    series: []
  });

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('extraPie'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = _.cloneDeep(option);

    const getPieList = _.get(props, 'pieData', []);
    // 图表 echarts 联动和共享数据集; 数据格式相同，以下将数据格式遍历成图表数据格式即可
    let newSource = [
      ['product']
    ];
    let seriesArray = [];
    if (_.size(getPieList) > 0) {
      // 上层进行过数据处理，保留原版数据结构  [{time:日期,data:[{name:板块,value:数值}]}]
      let plateObj = {}; // 中证1000:[0,1,2]
      getPieList.map((n, i) => {
        const getDataArr = _.get(n, 'data', []);
        newSource[0][i + 1] = _.get(n, 'time', '');
        getDataArr.map(item => {
          if (plateObj[item.name]) {
            plateObj[item.name].push(item.value);
          } else {
            plateObj[item.name] = [item.value];
          }

        });
      });
      // 对用对象汇总数据，创建图表数据
      _.keys(plateObj).map((keyname, index) => {
        newSource.push(_.concat(keyname, plateObj[keyname]));
        seriesArray[index] = {
          type: 'line',
          smooth: true,
          seriesLayoutBy: 'row',
          emphasis: { focus: 'series' },
          symbol: 'none'
        }
      })
    }
    newOption.series = _.concat(seriesArray, [{
      type: 'pie',
      id: 'pie',
      radius: '30%',
      center: ['50%', '25%'],
      emphasis: {
        focus: 'self'
      },
      label: {
        formatter: `{b}: {@${_.get(newSource, '[0][1]', '2012')}} ({d}%)`
      },
      encode: {
        itemName: 'product',
        value: _.get(newSource, '[0][1]', '2012'),
        tooltip: _.get(newSource, '[0][1]', '2012')
      }
    }]);
    newOption.dataset.source = newSource;
    //console.log('newOption', newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
    myChart.on('updateAxisPointer', function (event) {
      const xAxisInfo = event.axesInfo[0];
      if (xAxisInfo) {
        const dimension = xAxisInfo.value + 1;
        myChart.setOption({
          series: {
            id: 'pie',
            label: {
              formatter: '{b}: {@[' + dimension + ']} ({d}%)'
            },
            encode: {
              value: dimension,
              tooltip: dimension
            }
          }
        });
      }
    });
  }, [props.updateCount]);

  return (
    <Spin spinning={_.get(props, 'loading', false)}>
      <div style={{ display: 'flex', marginTop: 35 }}>
        <div
          id="extraPie"
          style={{ width: '100%', height: 535 }}
        />
      </div>
    </Spin>
  )
}