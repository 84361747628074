import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Typography } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { showTradingSignal, showSpecificTradingSignal, showTradingSignalPieChart, showPlateTradingSignal } from '@/api/stock';
import { validateResponse, autoCol } from '@/utils/utils';
import { useInterval, useMount, useUnmount, useReactive } from 'ahooks';
import { isValidObj, isMarketTrading } from '@/utils/utils2';
import { globalUrl } from '@/utils/global';
import ExtraBar from './ExtraComp/ExtraBar';
import ExtraPie from './ExtraComp/ExtraPie';
import ExtraList from './ExtraComp/ExtraList';
import PlateCharts from './ExtraComp/PlateCharts';
import PlatesAllTable from './Components/PlatesAllTable';
import moment from 'moment';
import _ from 'lodash';

const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const WEEK_RANGE = [moment().subtract(7, 'day').format(FORMAT), TODAY];
const INTERVAL_SECOND = 10000;
const audioAlert = new Audio(`${globalUrl}/y1184.mp3`);
const DEFAULT_PARAMS = {
  type: 201,
  indexCode: 'all',
  start: TODAY + ' 00:00:00',
  end: TODAY + ' 00:00:00',
  pageNum: 1
}
const report_color = '#ccbb61'; const research_color = '#7e989f';
const REPORT_SORT = [
  { label: '按报告类型', value: 'reportType', color: report_color },
  { label: '按报告时间', value: 'r_time', color: report_color },
  { label: '按研报类型', value: 'ratingName', color: research_color },
  { label: '按研报时间', value: 'rs_time', color: research_color },
  { label: '取消', value: 'cancel', color: 'black' },
];
const REPORT_SORT_KEYS = ['reportType', 'r_time', 'ratingName', 'rs_time'];
const FILTER_KEYS = ['open', 'close', 'chiNextOpen', 'chiNextClose', 'bTime', 'eTime', 'countNum'];
const CHARTS_OPTIONS = ['统计', '市值', '板块总', '板块'];
const INTERVAL_NUM = 10000;
let timer = null;
/**
 * 策略系统推送板块；
 * 通过定时任务及盘中自动更新，将获取到的数据进行List展示，并支持筛选等条件功能；
 * 
 * Notification 移动设备会报错 {  以下为三个不同的操作系统执行 navigator.userAgent 的 log
 * Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1
 * Mozilla/5.0 (Linux; Android 11.0; Surface Duo) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Mobile Safari/537.36
 * Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.82 Safari/537.36
 * }
 */
const ExtraTrade = ({
  tradeDay = false,
  updates = 0,

  onListNewChange,
  goPush,
  goPlate,
  goTimeIndex
}) => {
  const mobxStore = useLocalObservable(() => store);
  const init_trading = isMarketTrading(tradeDay);
  const [tradeList, setTradeList] = useState([]); // 获取的原始数据
  const [filterList, setFilterList] = useState([]); // 可筛选显示的数据
  const [upCount, setUpCount] = useState(0); //图表更新count
  const [upCount2, setUpCount2] = useState(0); //重置slider条件搜索count
  const [loading, setLoading] = useState(false);
  const [sortKey, setSortKey] = useState('');
  const [rates, setRates] = useState({}); //推送平均收益率 及 列表统计数据
  // open=打开，close=手动关闭并且timeinter无操作，init=初始timeinter随开盘收盘启动关闭
  const [autoUpdate, setAutoUpdate] = useState('init');
  const [permissionGrant, setPermissionGrant] = useState(false); // 检查浏览器通知功能
  const [inter, setInter] = useState(undefined);
  const unreadRef = useRef(0);
  const [isSearch, setIsSearch] = useState(false);
  const [pieCharts, setPieCharts] = useState([]);
  // 信号数据统一参数; 【tips】：用基础state赋值修改，因筛选范围条件是，字段不固定，会根据情况删除部分字段，所以没用useReactive
  const [getParams, setGetParams] = useState(DEFAULT_PARAMS);
  // 统计图表参数
  const chrState = useReactive({
    type: getParams.type,
    indexCode: getParams.indexCode,
    times: WEEK_RANGE,
    chartType: CHARTS_OPTIONS[0],
    barLoading: false, pieLoading: false,
    select_plate: '', last_get: null,
    sdate: TODAY, // 单日时期，不加00:00
  });
  const [plateCharts, setPlateCharts] = useState({}); // 板块相关数据
  const [upCount3, setUpCount3] = useState(0); //图表更新count
  const [upCount3_1, setUpCount3_1] = useState(0); // 板块表格点击跳转更新
  const [timeInter, setTimeInter] = useState(INTERVAL_NUM);
  const [isTrading, setIsTrading] = useState(init_trading);
  let isUnmont = false;

  useMount(() => {
    _showTradingSignal();
    audioAlert.load();
    console.log(navigator.userAgent)
    // 只有windows平台执行Notification方法，移动平台跳过，否则会白屏报错
    if (_.includes(navigator.userAgent, 'Windows')) {
      Notification.requestPermission(); //浏览器通知请求
      setPermissionGrant(Notification.permission === "granted" ? true : false);
    }
    _showTradingSignalPieChart();
    if (!tradeDay) {
      setTimeInter(undefined);
    }
  });

  useUnmount(() => {
    setInter(undefined);
    isUnmont = true;
    timer && clearTimeout(timer);
  });
  // 手动开启定时任务
  useInterval(() => {
    _showTradingSignal();
  }, inter);

  useInterval(() => {
    const is_trading = isMarketTrading(tradeDay);
    // console.log('is_trading', is_trading, autoUpdate)
    if (autoUpdate === 'close') {
      return;  // close=手动关闭，则不在进行定时判断任务
    };
    if (is_trading === false && inter) {
      setInter(undefined);
      setIsTrading(false);
      setAutoUpdate('init');
    } else if (is_trading === true && !inter) {
      setInter(INTERVAL_SECOND);
      setAutoUpdate('open');
      setIsTrading(true);
    };
  }, timeInter);

  useEffect(() => {
    // ref每次更新重新赋值，【解决fix】setInterval里面拿不到最新state数据
    unreadRef.current = _.size(filterList);
  });

  useEffect(() => {
    const update_trading = isMarketTrading(tradeDay);
    // console.log('upCount更新', update_trading, timeInter)
    setIsTrading(update_trading);
    if (update_trading && !timeInter) {
      setTimeInter(INTERVAL_NUM);
    };
  }, [updates]);

  // 【获取信号数据】
  const _showTradingSignal = async (pms) => {
    setLoading(true);
    let newParams = pms || getParams;
    if (newParams.indexCode === 'all') {
      Reflect.deleteProperty(newParams, 'indexCode')
    }
    const res = await showTradingSignal(newParams);
    setIsSearch(false);
    handleSignalResponse(res, newParams);
  }
  // 获取指定股票信号数据; 搜索时使用
  const _showSpecificTradingSignal = async ({ txt, date }) => {
    setLoading(true);
    let params = {
      search: txt,
      start: date[0] + ' 00:00:00',
      end: date[1] + ' 00:00:00',
    };
    const res = await showSpecificTradingSignal(params);
    setIsSearch(true);
    handleSignalResponse(res, params);
  }
  // 处理信号数据并提醒
  function handleSignalResponse(res, pm) {
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data.pageInfo.list', []);
      // console.log('更新前表数量', unreadRef.current);
      const pmDate = _.split(_.get(pm, 'end', ''), ' ')[0];
      const nextDate = moment(pmDate + ' 15:01:00'); const todayClose = moment(TODAY + ' 15:01:00');
      let count = 0;
      let newList = getData.map(n => {
        const date_time = _.get(n, 'date', '') + ' ' + _.get(n, 'time', '');
        // 对比nextDate是避免查看历史数据时timeKey值因为【毫秒】diff数值过大，统一用当天收盘后1分钟对时间进行对比
        const time_key_ms = _.get(n, 'date', '') === TODAY ? moment(todayClose).diff(moment(date_time), 'millisecond') : moment(nextDate).diff(moment(date_time), 'millisecond');
        const time_key_m = moment().diff(moment(date_time), 'm'); //分钟diff
        const is_report = isValidObj(_.get(n, 'report', null));
        const is_research = isValidObj(_.get(n, 'researchReport', null));
        let final = {
          ...n,
          'rate': n.rate || 0,
          'timeKey': time_key_ms,
          'new': time_key_m < 6 ? true : false,
          'format_time': moment(date_time).format('HH:mm:ss:SSS'),
          'isReport': is_report, // 以下为报告排序相关的字段，用于快速排序，将report和researchReport报告研报对象内的数据拿出来，并判断是否包含有研报报告
          'isResearch': is_research,
          'reportType': _.get(n, 'report.reportType', null),
          'r_time': _.get(n, 'report.reportDate', null),
          'r_time_timeKey': is_report ? moment().diff(moment(_.get(n, 'report.reportDate')), 'd') : 100, // 没有时间的默认100 排序会放在最后
          'ratingName': _.get(n, 'researchReport.ratingName', null),
          'rs_time': _.get(n, 'researchReport.reportDate', null),
          'rs_time_timeKey': is_research ? moment().diff(moment(_.get(n, 'researchReport.reportDate')), 'd') : 100,
        };
        if (final.new) {
          count++;
        }
        return final;
      });
      let orderNum = 1; let stockMap = new Map();
      let orderList = _.orderBy(newList, ['timeKey'], ['asc']); // 时间从后往前
      orderList = _.reverse(orderList).map(n => { // 增加推送数据的时间序号; 重复序号不变
        let idxVal = '';
        const getSymbol = _.get(n, 'symbol');
        if (stockMap.has(getSymbol)) {
          idxVal = stockMap.get(getSymbol);
        } else {
          stockMap.set(getSymbol, orderNum);
          idxVal = orderNum;
          orderNum++;
        }
        return _.assign(n, { 'idx': idxVal });
      });
      orderList = _.reverse(orderList); // 排序需要从前往后，需再次翻转
      const isTodayRes = moment(pm.start).format(FORMAT) === TODAY ? true : false;
      if (_.size(orderList) > unreadRef.current && isTodayRes) { // 当日自动更新数据，并且新数据数量 > 上次获取数量，通知
        const getHead = _.head(orderList);
        if (permissionGrant) {
          let notice = new Notification('推送通知！', {
            body: `新推送：${_.get(getHead, 'name', '') + '-' + _.get(getHead, 'symbol', '')}`,
            silent: true,
          })
          notice.onclick = () => {
            window.focus();
            notice.close();
          }
        }
        audioAlert.play();
      };
      setTradeList(() => orderList);
      setFilterList(() => orderList);
      const cuRates = {
        costAverageRate: _.get(res, 'data.costAverageRate', 0),
        filterAverageRate: _.get(res, 'data.filterAverageRate', 0),
        pushTotal: _.size(orderList),
        maxOrder: orderNum - 1 // 最后以为也会递增，所以需要减去1是股票数
      };
      setRates(cuRates);
      setLoading(false);
      setSortKey('');
      onListNewChange(count);
      // 更新全局
      mobxStore.changePushInfo({
        'list': orderList,
        'date': chrState.sdate,
        'rates': cuRates,
        'tval': _.get(pm, 'type')
      });
    }
  }
  // 推送交易信号指数饼状图
  const _showTradingSignalPieChart = async () => {
    const getTimes = _.get(chrState, 'times', []);
    if (_.size(getTimes) === 0) {
      return;
    };
    chrState.pieLoading = true;
    let params = {
      indexCode: _.get(chrState, 'indexCode') ?? 'all',
      type: _.get(chrState, 'type', ''),
      start: _.get(chrState, 'times[0]', '') + ' 00:00:00',
      end: _.get(chrState, 'times[1]', '') + ' 00:00:00',
    };
    if (params.indexCode === 'all') {
      Reflect.deleteProperty(params, 'indexCode')
    };
    if (chrState.chartType === CHARTS_OPTIONS[1]) {
      _.set(params, 'sortType', 'market');
    };
    const res = await showTradingSignalPieChart(params);
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data', {});
      if (isValidObj(getData)) {
        let newList = Object.keys(getData).map(date => {
          let pieValue = [];
          Object.keys(getData[date]).map(keyname => {
            pieValue.push({ name: keyname, value: getData[date][keyname] });
          })
          return { time: date, timeKey: moment().diff(moment(date), 'd'), data: pieValue }
        });
        newList = _.orderBy(newList, ['timeKey'], ['desc']);
        setPieCharts(newList);
      } else {
        setPieCharts([]);
      }
    } else {
      setPieCharts([]);
    }
    setUpCount(_.round(upCount + 0.1, 1));
    chrState.pieLoading = false;
  }
  // 获取板块统计
  async function _showPlateTradingSignal(date) {
    chrState.barLoading = true;
    const res = await showPlateTradingSignal({
      'tradeDate': date ? date + ' 00:00:00' : _.get(getParams, 'start'),
      'limited': false,
      'type': _.get(chrState, 'type', '')
    });
    if (validateResponse(res, isUnmont)) {
      const getPlates = _.get(res, 'data', {});
      let xdatas = []; let sdata = []; let tableDatas = [];
      if (isValidObj(getPlates)) {
        _.keys(getPlates).map((platename, i) => {
          const ana_datas = _.get(getPlates, `${platename}.plateAnalysis`, []);
          xdatas.push(platename);
          sdata.push(_.size(ana_datas));
          tableDatas.push({
            'id': i + platename,
            'pname': platename,
            'plateRate': _.get(getPlates, `${platename}.plateRate`, 0),
            'costAvgRate': _.get(getPlates, `${platename}.costAvgRate`, 0),
          });
        });
      }
      setPlateCharts({
        xdatas, sdata, tableDatas, 'fullPlates': getPlates
      });
      setUpCount3(_.round(upCount3 + 0.1, 1));
      chrState.last_get = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    chrState.barLoading = false;
  }
  // 修改获取参数信息；获取字段参数统一修改的function，
  function changeParamsGet(key, value) {
    const isPlateType = _.includes([CHARTS_OPTIONS[2], CHARTS_OPTIONS[3]], chrState.chartType) ? true : false;
    let temp = _.cloneDeep(getParams);
    if (key !== 'pageNum') {
      temp.pageNum = 1;
    }
    if (key === 'date') {
      temp.start = value + ' 00:00:00';
      temp.end = value + ' 00:00:00';
      chrState.sdate = value;
      if (isPlateType) {
        _showPlateTradingSignal(value);
      }
    } else if (key === 'range') {
      const getType = _.get(value, 'type', '');
      if (getType === 'confirm') { //有筛选字段的增加字段及value
        FILTER_KEYS.map(k => {
          if (_.get(value, k)) {
            _.set(temp, k, _.get(value, k));
          }
        })
      } else if (getType === 'reset') { // 重置删除筛选字段，可获取完整数据
        FILTER_KEYS.map(k => {
          if (k in temp) {
            Reflect.deleteProperty(temp, k);
          }
        });
        setUpCount2(upCount2 + 1);
      }
    } else if (key === 'pieDate') { // 饼图统计切换时间，不需要修改参数，直接调用接口
      chrState.times = value;
      _showTradingSignalPieChart();
      return;
    } else if (key === 'barType') {// 板块统计，获取板块数据
      const bar_type = _.get(value, 'type', '');
      const bar_date = _.get(value, 'date', '');
      chrState.chartType = bar_type;
      if (_.includes([CHARTS_OPTIONS[0], CHARTS_OPTIONS[1]], bar_type)) {
        _showTradingSignalPieChart(bar_date);
      } else {
        // 盘中或空数据时获取; 获取时间大于1分钟|没有板块数据|刷新 执行更新
        const isOverOneMintue = chrState.last_get ? moment().diff(moment(chrState.last_get), 's') > 60 ? true : false : null;
        if ((isTrading && isOverOneMintue) || _.size(plateCharts) === 0 || 'update' in value) {
          _showPlateTradingSignal(bar_date);
        }
      }
      return;
    } else {
      temp[key] = value;
    }
    // 图表参数单独维护一个state,type和indexCode共用值，切换基础参数（type,或者indexCode）根据图表type重新获取数据
    if (key === 'type' || key === 'indexCode') {
      chrState.type = _.get(temp, 'type');
      chrState.indexCode = _.get(temp, 'indexCode', 'all'); //temp有空的情况，空值=all
      if (isPlateType) {
        _showPlateTradingSignal();
      } else {
        _showTradingSignalPieChart();
      }
    }

    if (key === 'indexCode' && value === 'all') { // 增加选择全部
      Reflect.deleteProperty(temp, 'indexCode')
    }

    setGetParams(temp);
    _showTradingSignal(temp);
    handleAuto(key === 'date' && value === TODAY ? 'init' : 'close'); // 修改参数后暂停自动获取
  }
  // 切换排序；本地筛选符合条件数据，更新filterList
  function changeSortKey(key, skey = 'rate') {
    if (_.size(tradeList) === 0) {
      return;
    }

    handleAuto('close'); // 查看筛选结果关闭自动更新;
    let isFinish = false; let currentList = [];

    if (_.includes(REPORT_SORT_KEYS, key)) { // 报告排序时单独处理sort数据
      let newList = [];
      const isDate = _.includes(key, 'time'); // skey里面包含Date代表是时间排序，否则是类型排序
      if (isDate) {
        newList = _.orderBy(tradeList, [key + '_timeKey'], ['asc']); // key + _timeKey 找到对应的快捷的时间字段，并排序
      } else { // 根据报告或者研报类别进行分类，相同的临近显示
        const order_key = key + '_key'; // 例 reportType_key:
        let typeMap = new Map(); let orderCount = 1;
        newList = tradeList.map(n => {
          const getVal = _.get(n, key, null);
          if (getVal) {
            if (typeMap.has(getVal)) { // 同样reportType的数据都赋值相同的orderCount，这样排序时相同type的会显示在一起
              _.set(n, order_key, typeMap.get(getVal));
            } else {
              typeMap.set(getVal, orderCount);
              _.set(n, order_key, orderCount);
              orderCount++;
            }
          } else {
            _.set(n, order_key, 100); // 没有报告的统一赋值100，排最后
          }
          return n;
        });
        newList = _.orderBy(newList, [order_key], ['asc']);
      }
      setFilterList(newList);
      setSortKey(key);
      isFinish = true;
      currentList = newList;
    }

    if (key === 'cancel' && !isFinish) { // 点击取消恢复原始推送数据
      setSortKey(key);
      setFilterList(tradeList);
      isFinish = true;
      currentList = tradeList;
    }

    if (sortKey !== key && !isFinish) {
      setSortKey(key);
      // 按报告排序
      let newSortData = handleUpDownData(skey);
      let upDownArray = _.concat(newSortData.up, newSortData.down);
      //收益率排序
      if (key === 'up' || key === 'c_up') {
        let newUp = _.orderBy(upDownArray, [skey], ['desc']);
        setFilterList(_.concat(newUp, newSortData.zero));
        currentList = _.concat(newUp, newSortData.zero);
      } else if (key === 'down' || key === 'c_down') { // 建仓收益率排序
        let newDown = _.orderBy(upDownArray, [skey], ['asc']);
        setFilterList(_.concat(newDown, newSortData.zero));
        currentList = _.concat(newDown, newSortData.zero);
      }
    } else {
      if (!isFinish) {
        resetSortKey();
        currentList = _.concat(tradeList);
      }
    }

    updateMobx(currentList);
  }
  // 处理收益率数据，遍历数据，根据指定字段收益率大于小于0进行分组
  function handleUpDownData(skey) {
    let upArray = [];
    let downArray = [];
    let noRateArray = [];
    tradeList.map(n => {
      if (!n[skey]) {
        noRateArray.push(n)
      } else if (n[skey] < 0) {
        downArray.push(n)
      } else if (n[skey] > 0) {
        upArray.push(n)
      }
    });
    return { up: upArray, down: downArray, zero: noRateArray }
  }
  // 更改自动获取状态
  function handleAuto(status = '') {
    setInter(status === 'open' ? INTERVAL_SECOND : undefined);
    setAutoUpdate(status);
  };

  function resetSortKey() {
    setSortKey('')
    setFilterList(tradeList);
  }
  // 修改排序等，更新全局数据
  function updateMobx(list) {
    mobxStore.changePushInfo({
      'list': list,
      'date': chrState.sdate,
      'rates': rates,
      'tval': _.get(getParams, 'type')
    });
  }

  const bar_constant_props = { defaultPms: DEFAULT_PARAMS, todayFormat: TODAY, weekFormat: WEEK_RANGE, reportArray: REPORT_SORT, fKeys: FILTER_KEYS, typeOptions: CHARTS_OPTIONS };
  const bar_props = {
    'exParams': getParams, 'resetCount': upCount2, 'segCount': upCount3_1, 'sortKeyname': sortKey,
    'searchBool': isSearch, 'isAuto': autoUpdate === 'open' ? true : false, 'loading': loading, 'rateObj': rates,
    isTrading
  };
  return <>
    <ExtraBar
      {...bar_constant_props}
      {...bar_props}
      onPmsChange={(key, value) => changeParamsGet(key, value)}
      onSync={() => _showTradingSignal()}
      onConfirm={(pms) => _showSpecificTradingSignal(pms)}
      onSortChange={(keyname, skey) => changeSortKey(keyname, skey)}
      onAutoChange={() => {
        handleAuto(autoUpdate === 'open' ? 'close' : 'open');
        resetSortKey();
      }}
      goPushPage={() => goPush(getParams)}
      goPlatePage={() => goPlate(getParams)}
      goTimeIndexPage={() => goTimeIndex({ 'list': filterList, 'date': chrState.sdate, 'rates': rates, 'tval': _.get(getParams, 'type') })}
    />

    <Row gutter={[8, 8]}>
      <Col {...autoCol([12, 24])} style={{ paddingLeft: 8, overflowY: 'scroll', height: '74vh' }}>
        <ExtraList
          listData={filterList}
          sortkey={sortKey}
          colorObj={{ 'report': report_color, 'research': research_color }}
        />
        <div className='pushOrderText'>
          <Typography.Text type='secondary'>
            {'推送数：' + _.get(rates, 'pushTotal', '')}
            {'  股票数：' + _.get(rates, 'maxOrder', '')}
          </Typography.Text>
        </div>
      </Col>
      <Col {...autoCol([12, 24])} className='extraTradeRight'>
        {_.includes([CHARTS_OPTIONS[0], CHARTS_OPTIONS[1]], chrState.chartType) && <ExtraPie
          updateCount={upCount}
          pieData={pieCharts}
          loading={chrState.pieLoading}
        />}
        {chrState.chartType === CHARTS_OPTIONS[2] && <PlatesAllTable
          datas={_.get(plateCharts, 'tableDatas', [])}
          yHeight={565}
          loading={chrState.barLoading}
          onPlateClick={(plate) => {
            chrState.select_plate = plate;
            chrState.chartType = CHARTS_OPTIONS[3];
            setUpCount3_1(_.round(upCount3_1 + 0.1, 1));
          }}
        />}
        {chrState.chartType === CHARTS_OPTIONS[3] && <PlateCharts
          updateCount={upCount3}
          updateCount2={upCount3_1}
          plateSelect={chrState.select_plate}
          plates={plateCharts}
          loading={chrState.barLoading}
        />}
      </Col>
    </Row>
  </>
}

export default ExtraTrade;