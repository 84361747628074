import React, { useState } from 'react';
import { PageHeader, Row, Col, Card, Select, Space } from 'antd';
import { showPlateGraph } from '@/api/stock';
import { autoCol, validateResponse } from '@/utils/utils';
import { STATIC_RATE_COLOR, isValidObj, isValidArray, typeValueOptions, labelValues } from '@/utils/utils2';
import { useMount, useReactive } from 'ahooks';
import { NewRangePicker, NewDivider } from '@/view/common/widgets';
import PlateRangeCharts from './plate/PlateRangeCharts';
import moment from 'moment';
import _ from 'lodash';

const TODAY_FORMAT = "YYYY-MM-DD";
const TODAY = moment().format(TODAY_FORMAT);
const RANGE_DATE = [moment().subtract(7, 'd').format(TODAY_FORMAT), TODAY];
const FILTER_TYPE_OPTIONS = _.filter(typeValueOptions, o => [201, 202, 203, 205].indexOf(o.value) !== -1);
// 板块分析页面
const PlatePage = (props) => {
  const pageState = useReactive({
    signalType: '', loading: false,
  });
  const [dates, setDates] = useState(RANGE_DATE);
  const [plates, setPlates] = useState({});
  const [update, setUpdate] = useState(0);
  // 路由带入推送type，匹配直接加载
  useMount(() => {
    const routeDatas = _.get(props, 'location.state', {});
    //console.log(routeDatas);
    const getSignalType = _.get(routeDatas, 'type', '');
    if (_.findIndex(FILTER_TYPE_OPTIONS, o => o.value === getSignalType) !== -1) {
      pageState.signalType = getSignalType;
      _showPlateGraph();
    }
  });

  async function _showPlateGraph(range) {
    pageState.loading = true;
    let params = {
      'start': (range ? range[0] : dates[0]) + ' 00:00:00',
      'end': (range ? range[1] : dates[1]) + ' 00:00:00',
      'type': pageState.signalType,
    };
    const res = await showPlateGraph(params);
    if (validateResponse(res)) {
      const getData = _.get(res, 'data', {});
      if (isValidObj(getData)) {
        let timeOrder = []; let newPlate = {};
        // 根据返回日期排序时间
        _.keys(getData).map(dateKey => {
          timeOrder.push({ 'date': dateKey, 'order': moment().diff(moment(dateKey), 'd') });
        });
        const newOrders = _.orderBy(timeOrder, ['order'], ['desc']);
        newOrders.map((order, oi) => {
          const dayPlates = _.get(getData, order.date, []);
          dayPlates.map(d => {
            // 创建plates对象
            const newItem = _.assign(d, { 'idx': oi, 'ddate': order.date });// idx用于数据时间的对应位置
            const plate_name = _.get(d, 'plate', null);
            if (plate_name in newPlate) { // 创建过，直接用index进行赋值
              _.set(newPlate, `${plate_name}[${oi}]`, newItem);
            } else { // 空则创建
              _.set(newPlate, plate_name, [newItem]);
            }
          });
        });
        // newPlate 对象下面的数组值，有empty空位，需进行处理
        let finals = {};
        _.keys(newPlate).map(keyname => {
          let newArray = Array(_.size(newOrders)).fill({}); // 创建与时间大小相同的数组
          if (isValidArray(_.get(newPlate, keyname))) {
            // 根基idx进行赋值，未出现则是空对象
            _.get(newPlate, keyname).map(n => newArray[n.idx] = n);
          }
          _.set(finals, keyname, newArray);
        });
        let color_count = 0;
        let newPlateName = [];
        _.keys(finals).map((k, i) => {
          newPlateName.push(labelValues([k, k + i], { color: STATIC_RATE_COLOR[color_count] }));
          if (color_count >= 9) {
            color_count = 0;
          } else {
            color_count++;
          }
        })
        setPlates({
          'dates': newOrders,
          'plates': finals,
          // 直接处理checkItem的数据
          'plateNames': newPlateName
        });
      }
    }
    pageState.loading = false;
    setUpdate(update + 1);
  }

  return <>
    <PageHeader
      title={'板块详情'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
      onBack={() => props.history?.goBack()}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }} style={{ minHeight: 805 }}>
      <Row style={{ marginBottom: 8 }} align='middle'>
        <Col {...autoCol([12])}>
          <Space>
            <Select style={{ width: 155 }} value={pageState.signalType}
              options={FILTER_TYPE_OPTIONS}
              onChange={(val) => {
                pageState.signalType = val;
                _showPlateGraph();
              }} />
            <NewRangePicker
              dates={dates}
              onSelect={(dateStrings) => {
                setDates(dateStrings);
                _showPlateGraph(dateStrings);
              }}
            />
          </Space>
        </Col>
      </Row>

      <NewDivider />

      <PlateRangeCharts
        datas={plates}
        loading={pageState.loading}
        updateCount={update}
      />
    </Card>
  </>
}

export default PlatePage;