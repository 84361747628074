import React from 'react';
import { PageHeader, Card } from 'antd';
import AlphaIndexCharts from '../AlphaIndexCharts';

// 新Alpha指数图表页面
const NewAlphaPage = (props) => {
  return <>
    <PageHeader
      title={'Alpha择时'}
      style={{ backgroundColor: 'white', marginBottom: 12 }}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }} style={{ minHeight: 805 }}>
      <AlphaIndexCharts
        pageKey='New'
      />
    </Card>
  </>
}

export default NewAlphaPage;