import React, { useState } from 'react';
import {
  Card,
  Button,
  Row,
  Col,
  Space,
  Tabs,
  Typography,
  Pagination,
  List,
  Select,
  Tag,
  Popover
} from 'antd';
import { UnorderedListOutlined, SolutionOutlined, SearchOutlined, FileOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { showNews, getAllReportTypes, showReports, showResearchReports } from '@/api/stock';
import { useMount, useUnmount } from 'ahooks';
import { NewRangePicker } from '@/view/common/widgets';
import { validateResponse } from '@/utils/utils';
import StockSearch from '@/view/common/Components/StockSearch';
import QueueAnim from 'rc-queue-anim';
import moment from 'moment';
import _ from 'lodash';

const { Text, Paragraph } = Typography;
const { Option } = Select;
const TODAY = moment().format('YYYY-MM-DD');
const RESEARCH_OPTIONS = [{ label: '调高', value: '0' }, { label: '调低', value: '1' }, { label: '首次', value: '2' }, { label: '维持', value: '3' }]
const PAGE_SIZE = 20;
const NewDivder = () => <div style={{ height: 8 }} />

const NewsList = ({ }) => {
  const [newsData, setNewData] = useState({});
  const [reportOptions, setReportOptions] = useState([]);
  const [reportList, setReportList] = useState({});
  const [searchList, setSearchList] = useState({});
  const [rDate, setRDate] = useState([TODAY, TODAY]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('1');
  const [searchMode, setSearchMode] = useState(false);
  const [reportParams, setReportParams] = useState({
    start: TODAY, end: TODAY, stockSearch: '', reportType: '', ratingChange: ''
  });
  let isUnmont = false;
  // 首次加载监听上层update
  useMount(() => {
    _getAllReportTypes();
    _showResearchReports(1);
  });

  useUnmount(() => {
    isUnmont = true;
  });

  const _showNews = async (page = 1) => {
    setLoading(true)

    let params = {
      pageNum: page,
      pageSize: 20,
    }
    const res = await showNews(params);
    if (validateResponse(res, isUnmont)) {
      setNewData(_.get(res, 'data', {}));
      setLoading(false);
    }
  }

  const _getAllReportTypes = async () => {
    const res = await getAllReportTypes();
    if (validateResponse(res, isUnmont)) {
      setReportOptions(_.get(res, 'data', []));
    }
  }

  const _showReports = async (page = 1) => {
    setLoading(true)

    let params = {
      reportType: _.get(reportParams, 'reportType', ''),
      start: _.get(reportParams, 'start', ''),
      end: _.get(reportParams, 'end', ''),
      stockSearch: _.get(reportParams, 'stockSearch', ''),
      pageNum: page,
      pageSize: PAGE_SIZE,
    }
    const res = await showReports(params);
    if (validateResponse(res, isUnmont)) {
      setReportList(_.get(res, 'data', []));
      setLoading(false);
      setSearchMode(false)
    }
  }

  const _showResearchReports = async (page = 1) => {
    setLoading(true)

    let params = {
      ratingChange: _.get(reportParams, 'ratingChange', ''),
      start: _.get(reportParams, 'start', ''),
      end: _.get(reportParams, 'end', ''),
      stockSearch: _.get(reportParams, 'stockSearch', ''),
      pageNum: page,
      pageSize: PAGE_SIZE,
    }
    const res = await showResearchReports(params);
    if (validateResponse(res, isUnmont)) {
      setSearchList(_.get(res, 'data', []));
      setLoading(false);
      setSearchMode(false)
    }
  }

  function handleParamsChange(key, value) {
    let temp = _.cloneDeep(reportParams);
    if (key === 'date') {
      temp.start = value[0];
      temp.end = value[1];
    } else if (key === 'stockSearch') {
      temp['stockSearch'] = value.symbol;
      temp['stockName'] = value.stockName;
    } else {
      temp[key] = value;
    }

    setReportParams(temp);
  }

  function handleDataChange(activeKey, page) {
    const noReport = _.size(reportList) === 0 ? true : false;
    const noSearch = _.size(searchList) === 0 ? true : false;
    const noNews = _.size(newsData) === 0 ? true : false;
    const newActive = activeKey ?? active;
    if (activeKey) {
      setActive(activeKey);
    }
    if (newActive === '1') {
      const getPage3 = _.get(searchList, 'pageNum', 1);
      if (noSearch || page) {
        _showResearchReports(page ?? getPage3);
      }
    } else if (newActive === '2') {
      const getReport = _.get(reportList, 'pageNum', 1);
      if (noReport || page) {
        _showReports(page ?? getReport);
      }
    } else if (newActive === '3') {
      const getNews = _.get(newsData, 'pageNum', 1);
      if (noNews || page) {
        _showNews(page ?? getNews);
      }
    }
  }

  const type_options_2 = _.size(reportOptions) > 0 ? reportOptions.map((n, i) => <Option key={'o' + i} value={n}>{n}</Option>) : null
  const type_options_3 = RESEARCH_OPTIONS.map((n, i) => <Option key={'r' + i} value={n.value}>{n.label}</Option>)
  const isReport = active === '2' ? true : false;

  function checkParams(type = 'uncheck') {
    let checkArray = ['start', 'end'];
    let uncheck = [];
    let checks = [];
    checkArray.map((key, i) => {
      uncheck[i] = (!reportParams[key] || reportParams[key] === '') ? true : false;
      checks[i] = (reportParams[key] && reportParams[key] !== '') ? true : false;
    })
    return type === 'uncheck' ? _.includes(uncheck, true) : _.includes(checks, true);
  }
  const isUncheckPm = checkParams();
  // const isCheckPm = checkParams('check');
  function renderSearchBar() {
    return <Row gutter={[8, 8]} justify='center'>
      <Col span={20}><Text strong>搜索栏</Text></Col>
      <Col span={4} style={{ textAlign: 'right' }}>
        <div className='marksCloseIcon' onClick={() => setSearchMode(false)}>
          <CloseCircleOutlined />
        </div>
      </Col>
      <Col span={24}>
        <Space>
          <StockSearch
            size='small'
            width={185}
            titleTxt={'股票：'}
            onStockChange={(vals) => handleParamsChange('stockSearch', vals)}
          />
          <Popover
            content={<div>
              <Button danger size='small' type='link' onClick={() => handleParamsChange('stockSearch', { symbol: '', stockName: '' })}>
                清除
              </Button>
            </div>}
            trigger="hover"
          >
            <Text style={{ color: '#00a2a8' }}>{_.get(reportParams, 'stockName', '')}</Text>
          </Popover>
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          <Text>类型：</Text>
          <Select
            size='small'
            style={{ width: 220 }}
            value={isReport ? _.get(reportParams, 'reportType', null) : _.get(reportParams, 'ratingChange', null)}
            onChange={(val) => {
              handleParamsChange(isReport ? 'reportType' : 'ratingChange', val)
            }}
          >
            <Option value={''}>全部</Option>
            {isReport ? type_options_2 : type_options_3}
          </Select>
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          <Text>日期：</Text>
          <NewRangePicker
            dates={rDate}
            size='small'
            onSelect={(dateStrings) => {
              setRDate(dateStrings);
              handleParamsChange('date', dateStrings)
            }}
          />
        </Space>
      </Col>
      <Col span={12} style={{ marginTop: 28 }}>
        <Button block type='primary' size='small' disabled={isUncheckPm}
          onClick={() => {
            if (isReport) {
              _showReports();
            } else {
              _showResearchReports();
            }
          }}>
          确定
        </Button>
      </Col>
    </Row>
  }

  function renderActiveDatas() {
    if (active === '1') {
      return { page: _.get(searchList, 'pageNum', 0), total: _.get(searchList, 'total', 0), data: _.get(searchList, 'list', []) };
    } else if (active === '2') {
      return { page: _.get(reportList, 'pageNum', 0), total: _.get(reportList, 'total', 0), data: _.get(reportList, 'list', []) };
    } else if (active === '3') {
      return { page: _.get(newsData, 'pageNum', 0), total: _.get(newsData, 'total', 0), data: _.get(newsData, 'list', []) };
    }
  }

  function renderList(item) {
    if (active === '1') {
      return <List.Item>
        <List.Item.Meta
          title={<Text strong>{_.get(item, 'title', '')}</Text>}
          description={
            <Paragraph type='secondary' style={{ marginBottom: 0 }}>
              <Text strong type='secondary'>{_.get(item, 'stockName', '')}-{_.get(item, 'stockCode', '')}</Text>
              <NewDivder />
              <Tag>{_.get(item, 'orgsName', '')}</Tag>
              <Tag>{_.get(item, 'ratingName', '')}</Tag>
              <Tag>{_.get(RESEARCH_OPTIONS, `[${parseInt(_.get(item, 'ratingChange', 0))}].label`, '')}</Tag>
              <NewDivder />
              获取时间：{_.get(item, 'getDate', '')}     报告时间：{_.get(item, 'reportDate', '')}
            </Paragraph>
          }
        />
      </List.Item>
    } else if (active === '2') {
      return <List.Item>
        <List.Item.Meta
          title={<Text strong>{_.get(item, 'title', '')}</Text>}
          description={
            <Paragraph type='secondary' style={{ marginBottom: 0 }}>
              <Text strong type='secondary'>{_.get(item, 'stockName', '')}-{_.get(item, 'stockCode', '')}</Text>
              <NewDivder />
              <Tag>{_.get(item, 'reportType', '')}</Tag>
              <NewDivder />
              获取时间：{_.get(item, 'getDate', '')}     报告时间：{_.get(item, 'reportDate', '')}
            </Paragraph>
          }
        />
      </List.Item>
    } else if (active === '3') {
      const renderTime = moment(_.get(item, 'pubDate', '')).format('YYYY-MM-DD') === TODAY
        ? moment(_.get(item, 'pubDate', '')).format('HH:mm')
        : moment(_.get(item, 'pubDate', '')).format('MM-DD')
      return <List.Item>
        <List.Item.Meta
          title={<a href={item.url || '#'} target='_blank'>
            <Text strong>{_.get(item, 'articleTitle', '')}</Text>
          </a>}
          description={
            <Paragraph
              ellipsis={{ rows: 2, expandable: true }}
              type='secondary'
            >
              {_.get(item, 'description', '')}
            </Paragraph>
          }
        />
        <div style={{ padding: 10 }}>
          <Text type='secondary'>{renderTime}</Text>
        </div>
      </List.Item>
    }
  }
  const dataObj = renderActiveDatas();
  return <Card className='newsCard' size='small' bodyStyle={{ padding: 8 }}>
    <QueueAnim type="bottom" delay={300} component="div">
      {searchMode ? <div className='newsMarks' key={'a'}>
        {renderSearchBar()}
      </div> : null}
    </QueueAnim>

    <Tabs
      className='refreshHeader'
      style={{ zIndex: searchMode ? 3 : 5 }}
      activeKey={active}
      tabBarExtraContent={{
        right: active === '3' ? null : <div style={{ cursor: 'pointer' }} onClick={() => setSearchMode(true)}>
          <SearchOutlined />
        </div>
      }}
      onChange={(activeKey) => handleDataChange(activeKey, null)}
    >
      <Tabs.TabPane tab={<><FileOutlined /><span>个股研报</span></>} key="1" />
      <Tabs.TabPane tab={<><SolutionOutlined /><span>报告</span></>} key="2" />
      <Tabs.TabPane tab={<><UnorderedListOutlined /><span>资讯</span></>} key="3" />
    </Tabs>
    <List
      itemLayout="horizontal"
      dataSource={dataObj.data}
      loading={loading}
      renderItem={item => renderList(item)}
    />
    <div className='fixedFooter'>
      <Pagination
        simple
        current={dataObj.page}
        total={dataObj.total}
        pageSize={PAGE_SIZE}
        onChange={(page, pageSize) => handleDataChange(null, page)}
      />
    </div>
  </Card >
}

export default NewsList;