import React, { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import { showLimitTradingSignal, showLimitTradingSignalDuration } from '@/api/stock';
import { COMMON_INDEX_2, createLabelValue } from '@/utils/indexCodes';
import { autoCol, validateResponse } from '@/utils/utils';
import { TYPE_VALUE, isValidArray, createDatasTimeKey } from '@/utils/utils2';
import { useReactive, useMount } from 'ahooks';
import LimitCharts from './LimitComp/LimitChats';
import LimitChartsRange from './LimitComp/LimitChartsRange';
import LimitBars from './LimitComp/Bars';
import DayTable from './LimitComp/DayTable';
// import RulesModal from './LimitComp/Rules';
import moment from 'moment';
import _ from 'lodash';

const SegOption = Object.keys(TYPE_VALUE);
const TODAY = moment().format('YYYY-MM-DD');
const TODAY_RANGE = [moment().subtract(7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
const SEARCH_OPTIONS = [createLabelValue(['全部', 0]), createLabelValue(['分类1', 1]), createLabelValue(['分类2', 2]), createLabelValue(['分类3', 3])];
const FRQ_OPTIONS = [createLabelValue(['常规', 1]), createLabelValue(['50', 50]), createLabelValue(['100', 100]), createLabelValue(['200', 200])];
let searchValueType = {};
SEARCH_OPTIONS.map(c => {
  searchValueType[c.value] = c.label;
});
const divs = (a, b) => _.round(a / b, 4);
const newGet = (val, key) => _.get(val, key, 0);
const rounds = (v) => _.round(v, 4);
const deleteParamsKey = (pms) => {
  if (pms.indexCode === 'all') { // 全部选项时，不需要indexCode字段，否则返回数据为空
    Reflect.deleteProperty(pms, 'indexCode');
  }
  if (pms.searchType === 0) { // 搜索字段=0时删除searchType
    Reflect.deleteProperty(pms, 'searchType');
  }
  if (_.get(pms, 'num', 0) === 1) {
    Reflect.deleteProperty(pms, 'num');
  }
  return pms;
}
const defaultParamsRange = {
  type: TYPE_VALUE['Alpha标准'],
  indexCode: _.last(COMMON_INDEX_2).value,
  start: TODAY_RANGE[0] + ' 00:00:00',
  end: TODAY_RANGE[1] + ' 00:00:00',
  pageNum: 1,
  searchType: 0
};
const defaultParamsSingle = { // 左侧单日选择
  type: TYPE_VALUE['Alpha标准'],
  indexCode: _.last(COMMON_INDEX_2).value,
  start: TODAY + ' 00:00:00',
  end: TODAY + ' 00:00:00',
  pageNum: 1,
  searchType: 0
}
/**
 * 推送模块;
 * 【原】：原只是涨停票统计，根据获取涨停信息统计图表及区间内命中率等信息
 * 【改】：拆分为左右两组模块，同一接口，左侧为单日数据，右侧为区间数据并进行计算；
 *  修改前已完成统计部分内容，所以不进行修改，只将单日和多日逻辑进行拆分，并增加累加对比的图表和数据
 */
const LimitTrade = ({ singlePms = {}, tabKey = '', isTrading = false, onPmsChange }) => {
  const [tradeInfo, setTradeInfo] = useState({}); // 单日数据
  const [rangeAll, setRangeAll] = useState({}); // 汇总右侧累加数据的object
  const [updateCount, setUpdateCount] = useState(0);
  const [updateCount2, setUpdateCount2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const dateState = useReactive({ array: [], val: TODAY, type: null });
  // const modalState = useReactive({ show: false, value: {}, desc: {} });

  useMount(() => {
    _getSingleTypeData(defaultParamsSingle);
    _getRangeTypeData(defaultParamsRange);
  });
  // tab更新盘中更新
  useEffect(() => {
    if (tabKey === '3' && isTrading && _.get(singlePms, 'type')) {
      _getSingleTypeData(singlePms);
    }
  }, [tabKey]);
  // 统一调用的function，通过组件传对应参数，如不修改该值则null，params会调用state里面存储的数据获取
  //分页、时间、类型、刷新模块根据参数不同来处理。在调用事件中赋值给state.
  const _getSingleTypeData = async (pms) => {
    setLoading(true)
    let newPms = deleteParamsKey({
      type: pms.type,
      indexCode: pms.indexCode,
      start: pms.start,
      end: pms.end,
      pageNum: 1,
      searchType: pms.searchType
    });
    onPmsChange(newPms);
    dateState.type = _.get(newPms, 'type');
    const res = await showLimitTradingSignal(newPms);
    if (validateResponse(res)) {
      const getData = _.get(res, 'data', []);
      if (_.size(getData) > 0) {
        // 时间排序
        let orderData = createDatasTimeKey(getData, 'date', 'd', 'asc');
        // 原逻辑，每日数据进行计算，得到命中率召回率等计算结果
        const renderData = handleInTradeCal(orderData, 'single');
        // console.log('renderData', renderData)
        // findex为原单日多日是用来提取单日数据用，现保留增增加数据准确性
        let findex = _.findIndex(renderData.dataArray, o => o.date === dateState.val);
        let newSingleData = _.get(renderData, `dataArray[${findex}]`, []);
        newSingleData['newDTO'] = concatDTOs( // 合并处理数据
          _.get(newSingleData, 'stockAnalysisDTO', []),
          _.get(newSingleData, 'tradingStockAnalysisDTO', []),
          _.get(newSingleData, 'date')
        );
        // console.log('newSingleData', newSingleData)
        Reflect.deleteProperty(newSingleData, 'stockAnalysisDTO'); // 合并后可删除原字段
        Reflect.deleteProperty(newSingleData, 'tradingStockAnalysisDTO');
        setTradeInfo(newSingleData); // 单日数据
      } else { // 数据为空时
        setTradeInfo({});
      }
      setUpdateCount(_.round(updateCount + 0.1, 1));
    }
    setLoading(false);
  }
  // 获取区间数据
  const _getRangeTypeData = async (pms, timeChange) => {
    setLoading2(true);
    let newPms = deleteParamsKey({
      type: pms.type,
      indexCode: pms.indexCode,
      start: pms.start,
      end: pms.end,
      pageNum: 1,
      searchType: pms.searchType,
      num: pms.num,
    });
    // 拼接参数组成唯一值 ； 因newPms获取需要删除字段，所以拼接后两个字段从pms传入的参数中拿到
    const rangeKey = `${newPms.type}_${pms.searchType}_${pms.indexCode}_${(pms.num && pms.num > 1) ? pms.num : '常规'}`;
    const res = await showLimitTradingSignalDuration(newPms);
    if (validateResponse(res)) {
      const getData = _.get(res, 'data', []);
      if (_.size(getData) > 0) {
        let orderData = createDatasTimeKey(getData, 'date', 'd', 'asc');
        const renderData = handleInTradeCal(orderData, 'range');
        let temp = timeChange ? {} : _.cloneDeep(rangeAll);
        temp[rangeKey] = renderData;
        setRangeAll(temp);
      } else {
        message.info('暂无数据！');
      }
    }
    setUpdateCount2(_.round(updateCount2 + 0.1, 1));
    setLoading2(false);
  }
  // 处理盘中等返回数据计算
  function handleInTradeCal(datas, type) {
    // 以下key字段是字段拼接，根据后端返回数据字段进行拼接 ；
    const dayKeyArray = ['num', 'ynum', 'preYNum'];
    const baseKeys = ['numLimit', 'numLimitTrading', 'numLimitTotal', 'numLimitTotalTrading', 'numBase'];
    const subKeys = ['Recall', 'Rate', 'Acc']; //  subKeys字段是前端区分不同收益率自行创建，需要和图表中字段相同才可以显示
    // 昨 = 今+昨 ； 前=今+昨+前 ， 所以计算涨停票是，需要进行一次汇总处理
    const keysReturnSum = (item, keys, isTrading) => {
      let values = [];
      keys.map(k => {
        let limit_value = newGet(item, k + 'Limit');
        if (isTrading) {
          limit_value += newGet(item, k + 'LimitTrading');
        }
        values.push(limit_value);
      });
      return _.sum(values);
    };

    let newData = []; let dateArray = [];
    let cals = {};
    // 创建cal对象置空，运算需要
    baseKeys.map(k => {
      cals[k] = 0;
    });
    dayKeyArray.map(k => {
      subKeys.map(s => { // 创建比率计算，盘中，置空
        cals[k + s] = 0;
        cals[k + s + 'Trading'] = 0;
      });
    });
    // 遍历数据并计算
    newData = datas.map((itm, i) => {
      dateArray.push(itm.date);
      // 基础keys进行每日的累加计算
      baseKeys.map(key => {
        cals[key] += newGet(itm, key);
      });

      const base_total = newGet(itm, 'numBase'); // 推送总数
      const limit_total = newGet(itm, 'numLimitTotal'); //涨停数
      // 全市场涨停（含盘中
      const intrade_total = limit_total + newGet(itm, 'numLimitTotalTrading');
      //const base_rate = newGet(itm, 'rate'); //命中率
      let final = {};
      dayKeyArray.map((key, idx) => {
        const limit = keysReturnSum(itm, _.slice(dayKeyArray, 0, idx + 1)); // 因计算累加，所以根据数组字段slice，并处理递增累加数据
        const limit_trading = keysReturnSum(itm, _.slice(dayKeyArray, 0, idx + 1), true);
        //召回率：涨停.盘中 /  全市场涨停.盘中
        const recall = rounds(divs(limit, limit_total) * 100);
        const rate = rounds(divs(limit, base_total) * 100);
        // 盘中需要用盘中+数据进行数据
        const recall_tr = rounds(divs(limit_trading, intrade_total) * 100);
        const rate_tr = rounds(divs(limit_trading, base_total) * 100);
        // 召回率 . 盘中
        final[key + 'Recall'] = recall;
        final[key + 'RecallTrading'] = recall_tr;
        // 命中率 . 盘中
        final[key + 'Rate'] = rate;
        final[key + 'RateTrading'] = rate_tr;
        // 准确率 . 盘中  ;  准确率 = 命中率 + 召回率 /2
        final[key + 'Acc'] = rounds(divs(rate + recall, 2));
        final[key + 'AccTrading'] = rounds(divs(rate_tr + recall_tr, 2));
        // 统计
        subKeys.map(subk => {
          cals[key + subk] += final[key + subk];
          cals[key + subk + 'Trading'] = final[key + subk + 'Trading'];
        });
      });

      let newItm = { key: i + 1, ...itm, ...final };
      if (type === 'range') { // 区间数据不需要DTO数组
        Reflect.deleteProperty(newItm, 'stockAnalysisDTO');
        Reflect.deleteProperty(newItm, 'tradingStockAnalysisDTO');
      }
      return newItm
    });
    // 柱状图统计汇总计算数据
    cals.rate = rounds(divs(cals.numLimit, cals.numBase) * 100);
    cals.rateTraing = rounds(divs(cals.numLimit + cals.numLimitTrading, cals.numBase) * 100);
    cals.recallTrading = rounds(divs(cals.numLimit + cals.numLimitTrading, cals.numLimitTotal + cals.numLimitTotalTrading) * 100);
    cals.recall = rounds(divs(cals.numLimit, cals.numLimitTotal) * 100);

    return {
      'date': dateArray, //日期数据， 长度 = x天的数据
      'cals': cals, // object = x天内统计数据的汇总
      'dataArray': newData // 计算完成的完整数据，长度 = x天的数据
    }
  }

  // 合并涨停表格数据，并增加时间排序
  function concatDTOs(dto, dto2, date) {
    const current = moment(date + ' 9:29');
    let newDto = isValidArray(dto) ? dto.map(d => _.assign(d,
      { timeKey: current.diff(moment(d.date + ' ' + d.time), 's') } // 通过数据返回format时间进行拼接，精确到秒
    )) : [];
    let newDto2 = isValidArray(dto2) ? dto2.map(d => _.assign(d,
      { isTrading: true, timeKey: current.diff(moment(d.date + ' ' + d.time), 's') }
    )) : [];
    // 合并盘中涨停数据，并标识哪些是盘中涨停数据，并排序
    return _.orderBy(_.concat(newDto, newDto2), ['timeKey'], ['desc'])
  }

  function delRangeData(type, key) {
    if (type === 'clear') {
      setRangeAll({});
    } else {
      let temp = _.cloneDeep(rangeAll);
      Reflect.deleteProperty(temp, key);
      setRangeAll(temp);
    }
    setUpdateCount2(_.round(updateCount2 + 0.1, 1));
  }

  const defaultBarProps = { searchOptions: SEARCH_OPTIONS, options: SegOption, today: TODAY, todayRange: TODAY_RANGE, frqOptions: FRQ_OPTIONS };
  return <>
    <Row gutter={[8, 8]}>
      <Col {...autoCol([12])} className='limitDivder'>
        <LimitBars
          type='single'
          defaultPms={defaultParamsSingle}
          onGet={(type, pms) => {
            if (type === 'single') {
              dateState.val = moment(pms.start).format('YYYY-MM-DD');
              _getSingleTypeData(pms);
            }
          }}
          onClear={() => delRangeData('clear')}
          {...defaultBarProps}
        />
        <DayTable
          infos={tradeInfo}
          loading={loading}
        />
        <LimitCharts
          singleDate={dateState.val}
          types={dateState.type}
          updateCount={updateCount}
          infos={tradeInfo}
          loading={loading}
        />
      </Col>
      <Col {...autoCol([12])} style={{ paddingLeft: 12 }}>
        <LimitBars
          type='range'
          defaultPms={defaultParamsRange}
          onGet={(type, pms, timeChange) => {
            if (type === 'range') {
              _getRangeTypeData(pms, timeChange)
            }
          }}
          {...defaultBarProps}
        />
        <LimitChartsRange
          updateCount={updateCount2}
          allInfo={rangeAll}
          searchName={searchValueType}
          loading={loading2}
          onDelRow={(key) => delRangeData('del', key)}
        />
      </Col>
    </Row>

    {/* <RulesModal
      show={modalState.show}
      onCancel={() => modalState.show = false}
      values={modalState.value}
      descVal={modalState.desc}
    /> */}
  </>
}

export default LimitTrade;